import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  ArrowDropDown,
} from '@mui/icons-material';
import _ from 'lodash';
import {
  FormInputDate,
  FormInputSwitch,
  FormInputText,
} from "../form";
import {
  AlertDialog,
  DialogTitleClose,
} from "../dialog";
import { dateFormat } from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import * as constructionWorkTypeActions from "../../store/constructionWorkType";
import * as gprojectActions from "../../store/gproject";
import * as gsupplyConfirmActions from "../../store/gsupplyConfirm";
import GProjectG04Dialog from './GProjectG04Dialog';
import DocumentViewerDrawer from "../common/viewer/DocumentViewerDrawer";

const theme = createTheme();

const defaultValues = {
  gproject: {},
  requestComment: '',
  supplyComment: '',
};

const border = '1px solid rgba(224, 224, 224, 1)';
const requestTableHeader = {
  backgroundColor: '#f5f5f5',
  border,
};

// 단위 옵션 추가
const unitOptions = [
  { value: 'm²', label: 'm²' },     // SI 단위 - 소문자
  { value: 'EA', label: 'EA' },     // 일반 단위 - 대문자
  { value: 'kg', label: 'kg' },     // SI 단위 - 소문자
  { value: 'g', label: 'g' },       // SI 단위 - 소문자
  { value: 'L', label: 'L' },       // 리터는 혼동 방지를 위해 대문자 L 사용
  { value: 'mL', label: 'mL' }      // 밀리리터는 접두어 m은 소문자, L은 대문자
];

const GSupplyConfirmResultDialog = ({
  crudMode,
  setCrudMode,
  open,
  setOpen,
  selectedRow,
  setSelectedRow,
  setIsDataModified,
  refreshList,
}) => {
  const constructionWorkTypes = useSelector(state => state.constructionWorkType.constructionWorkTypes);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reset, control, setValue, getValues } = useForm({ defaultValues: defaultValues });

  const [requestTableData, setRequestTableData] = useState([]);
  const [localInputValues, setLocalInputValues] = useState({}); // 입력 필드 상태를 로컬 상태로 관리
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [documentViewerDrawerOpen, setDocumentViewerDrawerOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  const selectAllConstructionWorks = () => dispatch(constructionWorkTypeActions.selectAll());
  const modify = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(gsupplyConfirmActions.modify(data))
        .then(resolve)
        .catch(reject);  // 오류를 명시적으로 reject
    });
  };

  const updateStatus = ({ id, status }) => {
    return new Promise((resolve, reject) => {
      dispatch(gsupplyConfirmActions.updateStatus({ id, status }))
        .then(resolve)
        .catch(reject);  // 오류를 명시적으로 reject
    });
  };

  const handleDialogClose = () => {
    setOpen(false);

    initDialog();
  };

  // 프로젝트 다이얼로그가 닫힐 때만 테이블 데이터 재구성
  // useEffect(() => {
  //   const updateSelectedRow = async () => {
  //     if (!openProjectDialog && selectedRow && selectedRow.id) {
  //       const selectedRowG04Gen = await selectDirect(selectedRow.id);
  //       setSelectedRow(selectedRowG04Gen);
  //     }
  //   };

  //   // openProjectDialog가 true에서 false로 변경될 때만 실행
  //   if (!openProjectDialog && openProjectDialog !== undefined) {
  //     updateSelectedRow();
  //   }
  // }, [openProjectDialog]);
  
  const handleClickProjectModify = () => {
    setCrudMode("U");
    setOpenProjectDialog(true);
  }

  const initDialog = () => {
    reset(defaultValues);
    setRequestTableData([]);
    setLocalInputValues({}); // localInputValues도 함께 초기화
  }

  useEffect(() => {
    if (open) {
      selectAllConstructionWorks();
    }
  }, [open]);

  useEffect(() => {
    console.log(selectedRow);
    if (selectedRow) {
      for (const [item, value] of Object.entries(defaultValues)) {
        if (item === "gproject") {
          const gproject = selectedRow[item];
          const { ownerName, constructionCompanyName, constructionWorkTypeId, startDate, endDate, completionDate } = gproject;
          
          startDate && setValue("startDate", (new Date(startDate).getDateWithStartHours() || value));
          endDate && setValue("endDate", (new Date(endDate).getDateWithEndHours() || value));
          completionDate && setValue("completionDate", (new Date(completionDate).getDateWithEndHours() || value));
          setValue("gclientName", ownerName);
          setValue("constructionCompanyName", constructionCompanyName);
          setValue("constructionWorkTypeId", constructionWorkTypeId);
        } else {
          setValue(item, selectedRow[item] || value);
        }
      }

      // selectedRow 데이터를 requestTableData 형식으로 변환
      const newRequestData = [];
      
      // division에 따라 category 매핑
      const DIVISION_TO_CATEGORY = {
        'RAW_MATERIAL': '원자재',
        'PROCESS': '가공유리',
        'SUB_MATERIAL_PROCESS': '가공부자재',
        'SUB_MATERIAL_BUILD': '시공부자재'
      };

      // items 배열이 있는 경우에만 처리
      if (selectedRow.items && Array.isArray(selectedRow.items)) {
        selectedRow.items.forEach((item, index) => {
          const {
            id,
            division,
            gclientName,
            gclientId,
            gprojectGlassesNo,
            g04docuGCertificationName,
            gcomponentAttName,
            gcomponentItemName,
            gcomponentAttCode,
            itemCode,
            requestSize,
            supplySize,
            supplyUnit,
            startDate,
            endDate,
            requestComment,
            supplyComment,
            gcomponentItemId,
            gcomponentAttId,
            g04docuGCertificationId,
            specification,
            appliedArea,
          } = item;

          newRequestData.push({
            id,
            category: DIVISION_TO_CATEGORY[division] || division,
            subCategory: (() => {
              if (division === 'PROCESS') {
                return gprojectGlassesNo;
              } else if (division === 'RAW_MATERIAL') {
                return '원판';
              } else if (division.startsWith('SUB_MATERIAL')) {
                return g04docuGCertificationName;
              }
              return item.division;
            })(),
            company: gclientName || '',
            gclientId: gclientId || '',
            product: (() => {
              if (division === 'PROCESS') {
                return specification.replaceAll("|", " ").replaceAll("+", " + ");
              } else if (division === 'RAW_MATERIAL') {
                return gcomponentAttName ? 
                  `${gcomponentAttCode} ${gcomponentItemName.replace(/\(.*?\)/, "")}` :
                  item.gcomponentItemName.replace(/\(.*?\)/, "");
              } else if (division.startsWith('SUB_MATERIAL_PROCESS') || division.startsWith('SUB_MATERIAL_BUILD')) {
                return gcomponentItemName + 
                  (gcomponentAttCode ? ` (${gcomponentAttName})` : '');
              }
              return '';
            })() || '',
            itemCode: itemCode || '',
            appliedArea: appliedArea || '',
            requestSize: requestSize || '',
            supplySize: supplySize || '',
            supplyUnit: supplyUnit || 'EA',
            startDate: startDate ? dateFormat(new Date(startDate)).split(' ')[0] : '',
            endDate: endDate ? dateFormat(new Date(endDate)).split(' ')[0] : '',
            requestComment: requestComment || '',
            supplyComment: supplyComment || '',
            gcomponentItemId: gcomponentItemId || '',
            gcomponentAttId: gcomponentAttId || '',
            g04docuGCertificationId: g04docuGCertificationId || ''
          });
        });
      }

      console.log("requestTableData:", newRequestData);
      setRequestTableData(newRequestData);
    }
  }, [selectedRow]);

  // 입력 필드 변경 핸들러. 천단위 콤마 포맷팅 함수
  const formatNumber = (num) => {
    if (!num) return '';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // 콤마 제거 함수
  const removeCommas = (value) => {
    if (typeof value === 'number') return value.toString();
    if (!value) return '';
    return value.replace(/,/g, '');
  };

  // handleInputChange 함수. 디바운스된 업데이트 함수
  const debouncedUpdateRequestTable = useCallback(
    _.debounce((index, field, value) => {
      setRequestTableData(prevData => 
        prevData.map((row, i) => 
          i === index ? { ...row, [field]: value } : row
        )
      );
    }, 100),
    []
  );

  const handleInputChange = (index, field, value, event) => {
    if (field === 'requestSize' || field === 'supplySize') {
      // 숫자와 콤마만 허용
      const numericValue = removeCommas(value);
      if (numericValue === '' || /^\d*$/.test(numericValue)) {
        // 즉시 로컬 상태 업데이트
        setLocalInputValues(prev => ({
          ...prev,
          [`${index}-${field}`]: formatNumber(numericValue)
        }));
        
        // 실제 데이터도 즉시 업데이트하고 디바운스된 업데이트는 백그라운드에서 처리
        setRequestTableData(prevData => 
          prevData.map((row, i) => 
            i === index ? { ...row, [field]: numericValue } : row
          )
        );
        
        // 디바운스된 업데이트는 추가적인 최적화를 위해 유지
        debouncedUpdateRequestTable(index, field, numericValue);
      }
    } else {
      // 다른 필드들도 즉시 업데이트하도록 수정
      setLocalInputValues(prev => ({
        ...prev,
        [`${index}-${field}`]: value
      }));
      
      setRequestTableData(prevData => 
        prevData.map((row, i) => 
          i === index ? { ...row, [field]: value } : row
        )
      );
      
      debouncedUpdateRequestTable(index, field, value);
    }
  };

  const getInputValue = (index, field, defaultValue) => {
    const localValue = localInputValues[`${index}-${field}`];
    return localValue !== undefined ? localValue : defaultValue;
  };

  // 키보드 이벤트 핸들러
  const handleKeyDown = (event, index, field) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      
      // 다음 행의 같은 필드로 포커스 이동
      const nextIndex = index + 1;
      if (nextIndex < requestTableData.length) {
        const nextInput = document.querySelector(
          `input[name="requestTableData.${nextIndex}.${field}"]`
        );
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleClickRequest = async (mode) => {
    /**
     * 
     * 납품확인서 식별ID	| id              | String		                                            | O
     * 상태	             | status          | String	   | 변경 옵션	                                | O
     * 요청자 비고	     | requestComment   | String	  | 작성중/요청	                              
     * 발행사 비고	     | supplyComment    | String	  |  검토중/발행/반려	                         
     * 확인서 제품 목록	 | items            | Array	    | 납품 세부 품목들.                          | O
     * 제품 식별 ID	     | └ id             | String	 | 원자재, 시공부자재, 가공부자재	              | O
     * 제품코드	         | └ itemCode       | String	 | 작성중/요청/검토중/발행- 제품코드	      
     * 적용부위	         | └ appliedArea    | String	 | 작성중/요청/검토중/발행 - 적용부위	    
     * 단위	             | └ supplyUnit     | String	 | 작성중/요청/검토중/발행- 단위	             | O
     * 요청 수량	       | └ requestSize    | Double	  | 작성중/요청/검토중/발행- 요청 납품 수량	    | O
     * 발행사 납품 수량	  | └ supplySize     | Double	  | 검토중/발행	                            
     * 납품일시 (시작)	  | └ startDate      | DateTime	| 검토중/발행	
     * 납품일시 (종료)	  | └ endDate        | DateTime	| 검토중/발행	
     * 요청자 제품 비고	  | └ requestComment | String	  | 작성중/요청- 품목 요청자 비고, 요청사항 등.	
     * 발행사 제품 비고	  | └ supplyComment  | String	  | 검토중/발행
      */
      console.log(requestTableData);
      // return;
  
      if (mode === 'save' || mode === "request") {
        // 필수 입력 체크
        const invalidItems = requestTableData.filter(item => {
          const requestSize = Number(removeCommas(item.requestSize));
          return !requestSize;
        });
    
        if (invalidItems.length > 0) {
          setAlertInfo({
            titleAlert: "안내",
            messageAlert: "요청수량은 필수 입력 항목입니다.",
            open: true
          });
          return;
        }
      }
      
      setOpenBackdrop(true);
  
      try {
        const { id } = selectedRow;
  
        // 요청취소 처리는 상태 업데이트만 수행
        if (mode === "request_cancel" || mode === "request_review" || mode === "done") {
          let messageAlert = "";
          if (mode === "request_cancel") {
            await updateStatus({ id, status: "작성중" });
            messageAlert = "납품확인서 요청에 대해 취소하였습니다.";
          } else if (mode === "request_review") {
            await updateStatus({ id, status: "검토중" });
            messageAlert = "납품확인서 요청에 대해 재검토요청하였습니다.";
          } else if (mode === "done") {
            await updateStatus({ id, status: "완료" });
            messageAlert = "납품확인서 발행업무가 완료되었습니다.";
          }

          setAlertInfo({
            titleAlert: "안내",
            messageAlert,
            open: true
          });
          refreshList();
          handleDialogClose();
          return;
        }
  
        // 저장 또는 요청일 경우에만 전체 데이터 처리
        const requestData = {
          id,
          status: mode === "save" ? "작성중" : "요청",
          requestComment: selectedRow.requestComment,
          items: requestTableData.map(({
            id,
            itemCode, 
            appliedArea, 
            supplyUnit, 
            requestSize,
            requestComment,
          }) => ({
            id, // required
            itemCode,
            appliedArea,
            supplyUnit, // required
            requestSize: requestSize ? Number(requestSize) : null, // required
            requestComment,
          }))
        };
  
        console.log("요청 저장 데이터:", requestData);
        
        await modify(requestData);
      
        setAlertInfo({
          titleAlert: "안내", 
          messageAlert: mode === "save" ? 
            "납품확인서 요청 정보가 저장되었습니다." : 
            "납품확인서를 요청하였습니다.",
          open: true
        });
  
        if (mode === "save") {
          setIsDataModified(true);
        } else {
          refreshList();
          handleDialogClose();
        }
    
      } catch (error) {
        console.error("요청 저장 중 오류 발생:", error);
        setAlertInfo({
          titleAlert: "안내",
          messageAlert: error.serverResponse.message,
          open: true
        });
      } finally {
        setOpenBackdrop(false);
      }
    };

  // 업체별 첫 번째 행과 행 수를 계산하는 함수
  const calculateCompanyRowSpans = (data) => {
    const companySpans = {};
    let currentCompany = null;
    let currentCount = 0;
    let firstIndex = null;
  
    data.forEach((row, index) => {
      // 업체명이 비어있지 않은 경우에만 처리
      if (row.company) {
        // 새로운 업체 시작 또는 연속되지 않은 같은 업체
        if (currentCompany !== row.company || 
            (index > 0 && data[index - 1].company !== row.company)) {
          // 이전 업체 데이터 저장
          if (currentCompany && currentCount > 0) {
            if (!companySpans[currentCompany]) {
              companySpans[currentCompany] = [];
            }
            companySpans[currentCompany].push({
              count: currentCount,
              firstIndex: firstIndex
            });
          }
          // 새로운 업체 초기화
          currentCompany = row.company;
          currentCount = 1;
          firstIndex = index;
        } else {
          // 같은 업체 계속
          currentCount++;
        }
      }
    });
  
    // 마지막 업체 데이터 저장
    if (currentCompany && currentCount > 0) {
      if (!companySpans[currentCompany]) {
        companySpans[currentCompany] = [];
      }
      companySpans[currentCompany].push({
        count: currentCount,
        firstIndex: firstIndex
      });
    }
  
    return companySpans;
  };

  // const handleClickViewEachDoc = (documentPath) => {
  //   const path = documentPath.replace(uploadFilePath, fileServerUrl);

  //   // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //   const randNumber = Math.floor(Math.random()*99);

  //   window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  // }

  const handleClickViewEachDoc = (documentPath) => {
    const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);
    
    // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
    const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');

    const path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;

    // window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
    setCurrentUrl(path);
    if (!documentViewerDrawerOpen) {
      setDocumentViewerDrawerOpen(true);
    }
  }

  const renderActionButtons = () => {
    const status = selectedRow?.status;
    
    switch (status) {
      case '작성중':
        return (
          <>
            <Button 
              variant="outlined" 
              sx={{ mr: 1 }} 
              onClick={() => handleClickRequest('save')}
            >
              {"저장"}
            </Button>
            <Button 
              variant="contained" 
              sx={{ mr: 1 }} 
              onClick={() => handleClickRequest('request')}
            >
              {"요청하기"}
            </Button>
          </>
        );
        
      case '요청':
        return (
          <>
            {/* API 서버에서 요청중일 때 아래와 같이 처리 */}
            {/* {
              "code": 47003,
              "title": "GSupplyConfirm Update Error",
              message: "이미 요청처리중이라 내용을 수정할 수 없습니다."
            } */}
            {/* <Button 
              variant="outlined" 
              sx={{ mr: 1 }} 
              onClick={() => handleClickRequest('save')}
            >
              {"저장"}
            </Button> */}
            {/* <Button 
              variant="contained" 
              sx={{ mr: 1 }} 
              onClick={() => handleClickRequest('request_cancel')}
            >
              {"요청취소"}
            </Button> */}
          </>
        );
        
      case '발행':
        return (
          <>
            {/* <Button 
              variant="outlined" 
              sx={{ mr: 1 }} 
              onClick={handleClickProjectModify}
            >
              {"새요청"}
            </Button> */}
            <Button 
              variant="outlined" 
              sx={{ mr: 1 }} 
              onClick={() => handleClickRequest('request_review')}
            >
              {"재검토요청"}
            </Button>
            <Button 
              variant="contained" 
              sx={{ mr: 1 }} 
              onClick={() => handleClickRequest('done')}
            >
              {"완료"}
            </Button>
            <Button 
              variant="contained" 
              sx={{ mr: 1 }} 
              onClick={() => handleClickViewEachDoc(selectedRow.documentPath)}
            >
              {"납품확인서 보기"}
            </Button>
          </>
        );
        
      case '반려':
        // return (
        //   <Button 
        //     variant="outlined" 
        //     sx={{ mr: 1 }} 
        //     onClick={handleClickProjectModify}
        //   >
        //     {"새요청"}
        //   </Button>
        // );
        return null;
        
      case '완료':
        return (
          <Button 
            variant="contained" 
            sx={{ mr: 1 }} 
            onClick={() => handleClickViewEachDoc(selectedRow.documentPath)}
          >
            {"납품확인서 보기"}
          </Button>
        );
      default: // '검토중', '완료' 또는 기타 상태
        return null;
    }
  };

  // status에 따른 셀 편집 가능 여부와 스타일 결정
  const getCellStyle = (field) => {
    const status = selectedRow?.status;
    const isEditable = status !== '작성중' || 
      (status === '작성중' && !['supplySize', 'startDate', 'endDate', 'supplyComment'].includes(field));
    
    return {
      border,
      padding: '0px !important',
      backgroundColor: isEditable ? '#ffffcc' : '#ffffff',
      height: '30px'
    };
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop
          sx={{ 
            color: '#fff', 
            zIndex: (theme) => theme.zIndex.modal + 1 // Dialog의 z-index보다 1 높게 설정
          }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
          fullScreen={true}
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="draggable-dialog-title"
          maxWidth="lg"
          scroll="body"
          disableEscapeKeyDown={true}
        >
          <DialogTitleClose
            id="draggable-dialog-title"
            onClose={handleDialogClose}
            fullScreen={true}
            color="white"
            style={{ backgroundColor: "#1976d2" }}
          >
            <div id="dialog-position">
              {"납품확인서 상세"}
            </div>
          </DialogTitleClose>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sx={{ display: 'none' }}>
                <FormInputText
                  name={"id"}
                  control={control}
                  label={"아이디"}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <FormInputText
                    name={"site"}
                    control={control}
                    label={"현장명"}
                    InputProps={{ readOnly: true }}
                    sx={{ width: { /*xs: 330, */sm: 508 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    value={selectedRow?.gproject?.site}
                  />
                  <FormInputText
                    name={"siteAddress"}
                    control={control}
                    label={"현장주소"}
                    InputProps={{ readOnly: true }}
                    sx={{ width: { /*xs: 330, */sm: 508 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    value={selectedRow?.gproject?.siteAddress}
                  />
                </Stack>
              </Grid>
              {/* gclient 정보는 project 정보가 이미 저장되어 있다면 owner 정보, 그렇지 않다면  로그인 정보의 gclient 정보를 활용 */}
              <Grid item xs={12} sx={{ display: 'none' }}>
                <FormInputText
                  name={"gclientId"}
                  control={control}
                  label={"업체아이디"}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <FormInputText
                    name={"gclientName"}
                    control={control}
                    label={"업체명"}
                    InputProps={{ readOnly: true }}
                    sx={{ width: { sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    // value={selectedRow?.gproject?.owner?.name}
                  />
                  <FormInputText
                    name={"constructionCompanyName"}
                    control={control}
                    label={"건설사명(제출처)"}
                    InputProps={{ readOnly: true }}
                    sx={{ width: { sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    // value={selectedRow.constructionCompanyName}
                  />
                  <FormInputText
                    select
                    name={"constructionWorkTypeId"}
                    control={control}
                    label={"공종명"}
                    options={constructionWorkTypes.map(constructionWorkType => {
                      return {
                        label: constructionWorkType.name,
                        value: constructionWorkType.id,
                      }
                    })}
                    sx={{ width: { sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                    disabled={true}
                    // value={selectedRow.constructionWorkTypeId}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <FormInputDate
                    name="startDate"
                    control={control}
                    label={"시작"}
                    value={getValues("startDate")}
                    inputHeight={38}
                    fontSize={16}
                    customWidth={250}
                    disabled={true}
                  />
                  <FormInputDate
                    name="endDate"
                    control={control}
                    label={"마감"}
                    value={getValues("endDate")}
                    inputHeight={38}
                    fontSize={16}
                    customWidth={250}
                    disabled={true}
                  />
                  <FormInputDate
                    name="completionDate"
                    control={control}
                    label={"준공일"}
                    value={getValues("completionDate")}
                    inputHeight={38}
                    fontSize={16}
                    customWidth={250}
                    disabled={true}
                  />
                  {/* <div style={{ width: '150px', paddingLeft: '20px', backgroundColor: selectedRow.doneYN ? "#5dc061" : "#ED6C02", color: 'white', borderRadius: '5px' }}>
                    <FormInputSwitch
                      name={"doneYN"}
                      checked={selectedRow.doneYN}
                      control={control}
                      label={selectedRow.doneYN ? "마감" : "마감전"}
                      color="success"
                    />
                  </div> */}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"comments"}
                  control={control}
                  label={"설명"}
                  multiline
                  maxRows={5}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"requestComment"}
                  control={control}
                  label={"요청업체 기타사항"}
                  multiline
                  maxRows={5}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <FormInputText
                  name={"supplyComment"}
                  control={control}
                  label={"발행업체 기타사항"}
                  multiline
                  maxRows={5}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <TableContainer sx={{
                '& .MuiTableCell-root': {
                  padding: '8px 8px',
                }
              }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '50px' }}>순번</TableCell>
                      <TableCell align="center" colSpan={2} sx={{ ...requestTableHeader }}>구분</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '200px' }}>업체명</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '400px' }}>제품명</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '160px' }}>제품코드</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '160px' }}>적용부위</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '120px' }}>요청수량</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '120px' }}>납품수량</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '70px'}}>단위</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '200px' }}>납품일자</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '200px' }}>비고</TableCell>
                      <TableCell align="center" sx={{ ...requestTableHeader, width: '200px' }}>발행업체 비고</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(() => {
                      // 업체별 행 수 계산
                      const companySpans = calculateCompanyRowSpans(requestTableData);
                      
                      return requestTableData.length > 0 && 
                        requestTableData.map((row, index) => {
                          // 현재 행의 업체에 대한 그룹 정보 찾기
                          const companyGroups = companySpans[row.company] || [];
                          const currentGroup = companyGroups.find(group => 
                            index >= group.firstIndex && 
                            index < (group.firstIndex + group.count)
                          );
                          
                          const isFirstCompanyRow = currentGroup?.firstIndex === index;
                          const companyRowSpan = currentGroup?.count || 1;

                          return (
                            <TableRow key={`${row.category}-${index}`} sx={{ height: '30px' }}>
                              <TableCell align="right" sx={{ border }}>{index + 1}</TableCell>
                              <TableCell align="center" sx={{ border, width: '120px' }}>{row.category}</TableCell>
                              <TableCell align="left" sx={{ border, width: '150px' }}>{row.subCategory}</TableCell>
                              <TableCell align="left" sx={{ border }}>{row.company}</TableCell>
                              <TableCell align="left" sx={{ border }}>{row.product}</TableCell>
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.itemCode`}
                                  value={getInputValue(index, 'itemCode', row.itemCode)}
                                  onChange={(e) => handleInputChange(index, 'itemCode', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, index, 'itemCode')}
                                  size="small"
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',  // 추가: 기본 테이블 셀 폰트 크기와 동일하게 설정
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.appliedArea`}
                                  value={getInputValue(index, 'appliedArea', row.appliedArea)}
                                  onChange={(e) => handleInputChange(index, 'appliedArea', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, index, 'appliedArea')}
                                  size="small"
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',  // 추가: 기본 테이블 셀 폰트 크기와 동일하게 설정
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.requestSize`}
                                  value={getInputValue(index, 'requestSize', formatNumber(row.requestSize))}
                                  onChange={(e) => handleInputChange(index, 'requestSize', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, index, 'requestSize')}
                                  size="small"
                                  // type="number"
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',  // 추가: 기본 테이블 셀 폰트 크기와 동일하게 설정
                                      textAlign: 'right',  // 숫자 우측 정렬
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center" sx={getCellStyle('supplySize')}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.supplySize`}
                                  value={getInputValue(index, 'supplySize', formatNumber(row.supplySize))}
                                  onChange={(e) => handleInputChange(index, 'supplySize', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, index, 'supplySize')}
                                  size="small"
                                  fullWidth
                                  disabled={selectedRow?.status === '작성중'}
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',
                                      textAlign: 'right',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  select
                                  control={control}
                                  name={`requestTableData.${index}.supplyUnit`}
                                  value={getInputValue(index, 'supplyUnit', row.supplyUnit)}
                                  onChange={(e) => handleInputChange(index, 'supplyUnit', e.target.value)}
                                  size="small"
                                  options={unitOptions}
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',
                                      textAlign: 'center',
                                      display: 'flex',
                                      alignItems: 'center', // 추가
                                    },
                                    '& .MuiSelect-select': { // 추가
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      paddingRight: '24px', // 아이콘 공간 확보
                                    },
                                    '& .MuiSelect-icon': { // 추가
                                      right: 2,
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center" sx={getCellStyle('startDate')}>
                                <Stack 
                                  direction="row" 
                                  spacing={0} 
                                  justifyContent="center"
                                  alignItems="center" // 추가: 세로 중앙정렬
                                  sx={{ height: '100%' }}
                                >
                                  <FormInputText
                                    control={control}
                                    name={`requestTableData.${index}.startDate`}
                                    value={getInputValue(index, 'startDate', row.startDate)}
                                    onChange={(e) => handleInputChange(index, 'startDate', e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index, 'startDate')}
                                    type="date"
                                    size="small"
                                    fullWidth
                                    disabled={selectedRow?.status === '작성중'}
                                    sx={{
                                      mr: 1,
                                      height: '100%',
                                      width: '110px',
                                      '& .MuiInputBase-root': {
                                        height: '100%',
                                        display: 'flex',
                                        borderRadius: 0,
                                        // borderRight: '1px solid rgba(224, 224, 224, 1)',
                                      },
                                      '& .MuiInputBase-input': { 
                                        height: '100%',
                                        padding: '0 4px',
                                        fontSize: '0.875rem',
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                        borderRadius: 0,
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                          border: '1px solid rgba(0, 0, 0, 0.23)',
                                          borderRadius: 0,
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                          border: '1px solid #1976d2',
                                          borderRadius: 0,
                                        }
                                      }
                                    }}
                                  />
                                  <div style={{ display: 'flex', alignItems: 'center' }}>{"~"}</div> 
                                  <FormInputText
                                    control={control}
                                    name={`requestTableData.${index}.endDate`}
                                    value={getInputValue(index, 'endDate', row.endDate)}
                                    onChange={(e) => handleInputChange(index, 'endDate', e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e, index, 'endDate')}
                                    type="date"
                                    size="small"
                                    fullWidth
                                    disabled={selectedRow?.status === '작성중'}
                                    sx={{
                                      ml: 1,
                                      height: '100%',
                                      width: '110px',
                                      '& .MuiInputBase-root': {
                                        height: '100%',
                                        display: 'flex',
                                        borderRadius: 0,
                                      },
                                      '& .MuiInputBase-input': { 
                                        height: '100%',
                                        padding: '0 4px',
                                        fontSize: '0.875rem',
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                        borderRadius: 0,
                                      },
                                      '& .MuiOutlinedInput-root': {
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                          border: '1px solid rgba(0, 0, 0, 0.23)',
                                          borderRadius: 0,
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                          border: '1px solid #1976d2',
                                          borderRadius: 0,
                                        }
                                      }
                                    }}
                                  />
                                </Stack>
                              </TableCell>
                              <TableCell align="center" sx={{ border, padding: '0px !important', backgroundColor: '#ffffcc', height: '30px' }}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.requestComment`}
                                  value={getInputValue(index, 'requestComment', row.requestComment)}
                                  onChange={(e) => handleInputChange(index, 'requestComment', e.target.value)}
                                  onKeyDown={(e) => handleKeyDown(e, index, 'requestComment')}
                                  size="small"
                                  fullWidth
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0 8px',
                                      fontSize: '0.875rem',  // 추가: 기본 테이블 셀 폰트 크기와 동일하게 설정
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center" sx={getCellStyle('supplyComment')}>
                                <FormInputText
                                  control={control}
                                  name={`requestTableData.${index}.supplyComment`}
                                  value={getInputValue(index, 'supplyComment', row.supplyComment)}
                                  onChange={(e) => handleInputChange(index, 'supplyComment', e.target.value)}
                                  size="small"
                                  fullWidth
                                  multiline
                                  disabled={selectedRow?.status === '작성중'}
                                  sx={{ 
                                    height: '100%',
                                    '& .MuiInputBase-root': {
                                      height: '100%',
                                      display: 'flex',
                                      borderRadius: 0,
                                      alignItems: 'flex-start',
                                    },
                                    '& .MuiInputBase-input': { 
                                      height: '100%',
                                      padding: '0px 0px',
                                      fontSize: '0.875rem',
                                      alignItems: 'flex-start',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none', 
                                      borderRadius: 0,
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '&:hover .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        borderRadius: 0,
                                      },
                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #1976d2',
                                        borderRadius: 0,
                                      }
                                    }
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        });
                    })()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Grid container>
                <Grid display="flex" justifyContent="flex-end" item xs={12} sm={12}>
                {renderActionButtons()}
                  {/* <Button variant="outlined" sx={{ mr: 1 }} onClick={handleClickProjectModify}>{"새요청"}</Button>
                  <Button variant="outlined" sx={{ mr: 1 }} onClick={() => handleClickRequest('작성중')} disabled={!hasSelectedItems}>{"재검토요청"}</Button>
                  <Button variant="contained" sx={{ mr: 1 }} onClick={() => handleClickRequest('요청')} disabled={!hasSelectedItems}>{"완료"}</Button>
                  <Button variant="contained" sx={{ mr: 1 }} onClick={() => handleClickRequest('요청')} disabled={!hasSelectedItems}>{"납품확인서 보기"}</Button> */}
                  <Button onClick={handleDialogClose}>{"닫기"}</Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
        {/* 프로젝트 수정 다이얼로그 */}
        <GProjectG04Dialog
          crudMode={crudMode}
          setCrudMode={setCrudMode}
          modify={true}
          open={openProjectDialog}
          setOpen={setOpenProjectDialog}
          selectedRow={selectedRow}
        />
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
        <DocumentViewerDrawer 
          open={documentViewerDrawerOpen}
          onClose={() => setDocumentViewerDrawerOpen(false)}
          url={currentUrl}
        />
      </ThemeProvider>
    </>
  );
};

export default GSupplyConfirmResultDialog;
