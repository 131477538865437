import React, { useState } from 'react';
import { 
  Box, 
  Drawer, 
  IconButton, 
  CircularProgress,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';

const DocumentViewerDrawer = ({ open, onClose, url, anchor = "right" }) => {
  const minLoadingTime = 500; // 최소 로딩 시간 (밀리초)

  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, minLoadingTime);
  };

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "50%",
          zIndex: 9999
        },
      }}
      ModalProps={{
        sx: { zIndex: 9999 },
        disableEnforceFocus: true,
        disableAutoFocus: true,
        keepMounted: true,
        disablePortal: true,
      }}
      hideBackdrop={true}
      variant="persistent"
    >
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ p: 1, display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, position: 'relative' }}>
          {isLoading && (
            <Box 
              sx={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2
              }}
            >
              <CircularProgress />
              <Typography variant="body2" color="text.secondary">
                문서를 불러오는 중...
              </Typography>
            </Box>
          )}
          <iframe
            src={url}
            onLoad={handleIframeLoad}
            style={{ 
              width: "100%", 
              height: "100%", 
              border: "none",
              opacity: isLoading ? 0.5 : 1,
              transition: 'opacity 0.3s'
            }}
            title="문서 뷰어"
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default DocumentViewerDrawer;