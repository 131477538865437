import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Breadcrumbs,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Link,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import JsonPath from "jsonpath";
import * as menuActions from "../../store/menu";

const theme = createTheme();

const urlMaxWidth = [
  "/",
  "/association/gclients",
  "/association/settlements",
  "/constructionWorkType",
  "/gdomesticAuths",
  "/glasses",
  "/g04docuAlarmSettings",
  "/g04docuFiles",
  "/g04docuGCertifications",
  "/g04docuGenerateFile",
  "/g04docuMaterialApproval",
  "/g04docuProjects",
  "/g04docuSearch",
  "/g04GeneralDocus",
  "/gclients",
  "/gclientG04docus",
  "/gclientMailTest",
  "/gclientTypes",
  "/gclientType04docus",
  "/gcomponent",
  "/gcomponentItem",
  "/glass/dashboard",
  "/gperformanceDatas",
  "/gplans",
  "/gsendG04docus",
  "/gstandards",
  "/gstandardTypes",
  "/gsupplyConfirmGenerateFile",
  "/gsupplyConfirmGenerateFileList",
  "/gsupplyConfirmPublish",
  "/gsupplyConfirmPublishList",
  "/gsupplyConfirmRequest",
  "/gsupplyConfirmRequestList",
  "/gtype",
  "/securitiesByGClient",  
];

// json 탐색 재귀함수 예
// const selectedMenu = [];

// const process = (key, value, o) => {
//   console.log(key + " : "+value);
//   // console.log(o);
//   selectedMenu.push(o);
// }

// const traverse = (o, func, text) => {
//   const arr = Object.keys(o);
//   console.log(arr);
  
//   return arr.some(i => {
//     if (o[i] !== null && typeof(o[i]) === "object") {
//       return traverse(o[i], func, text);
//     }

//     if (i === "href" && o[i] === text) {
//       func.apply(this, [ i, o[i], o ]);
//       console.log(o);
//       return true;
//     } else {
//       return false;
//     }
//   });
// }

const searchMenu = ({ location, menus }) => {
  // traverse(menus, process, location.pathname);

  let selectedMenuInfo = {
    menu: {},
    path: [],
  };
  
  // TODO : 아래처럼 query하면 될 것으로 보이나 끝까지 찾지 않는 것 같음
  // const paths = JsonPath.paths(menus, '$..*[?(@.href)]');
  const paths = JsonPath.paths(menus, '$..*');
  console.log(paths)
  const matches = [];
  paths.forEach(path => {
    const match = path.filter(i => i === "href");
    if (match.length > 0 && `${location.pathname}${location.search}` === JsonPath.query(menus, path.join("."))[0]) {
    // if (match.length > 0 && location.pathname === JsonPath.query(menus, path.join("."))[0]) {
      matches.push(path);
    }
  });
  
  if (matches.length > 0) {
    const selectedPath = matches[0].slice(0, -1);
    selectedMenuInfo.menu = JsonPath.query(menus, selectedPath.join("."))[0];

    let queryPath = "";
    selectedPath.forEach((sPath, i) => {
      if (i === 0) {
        queryPath += sPath;
      } else {
        queryPath += "." + sPath;
      }

      if (i % 2 === 1) {
        // selectedMenuInfo.path.push(JsonPath.query(menus, queryPath));
        selectedMenuInfo.path.push(JsonPath.query(menus, queryPath)[0]);
      }
    });
  }

  return selectedMenuInfo;
}

const ButtonInTabs = ({ className, onClick, children }) => {
  return <Button className={className} onClick={onClick} children={children} />;
};

const Header = ({ menus }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tabMenus = useSelector(state => state.menu.menus);    
    const sessionUser = useSelector(state => state.session.sessionUser);
    
    // TODO : 메뉴 외 다른 페이지 열 경우 기존 선택 탭메뉴가 활성화된 상태임. 모두 비활성화 등 외부의 동작에 따라 변화를 주기 위해 추후 redux 사용 검토
    // const [ selectedMenuInfo, setSelectedMenuInfo ] = useState([]);
    const selectedMenuInfo = useSelector((state) => state.menu.selectedMenuInfo);

    const [ value, setValue ] = useState(0);

    // const handleChange = (event, newValue) => {
    //   alert(newValue)
    //   setValue(newValue);
    // };

    const handleCloseAllTabs = () => {
      dispatch(menuActions.removeAllTabMenus());

      navigate('/glass/dashboard');
      // navigate('/home', {replace: true});
    }
    
    const LinkTab = (props) => {
      // const dispatch = useDispatch();
    
      // const handleClickTab = (e) => {        
      //   if (props.tabMenu.href === location.pathname) {
      //     e.preventDefault();
      //     return;
      //   }

      
      // }

      const closeTab = async (e) => {
        // e.preventDefault(); 가 여기에 위치시 삭제됨
        e.preventDefault();
        
        await dispatch(menuActions.removeTabMenu(props.tabMenu));
        
        const selectedMenusByOrder = JSON.parse(sessionStorage.getItem('selectedMenusByOrder'));
        if (selectedMenusByOrder.length > 0) {
          navigate(selectedMenusByOrder[selectedMenusByOrder.length - 1].href);
        } else {
          navigate("/");
        }
        
        // e.preventDefault();
      }

      const { label, href, selected } = props;
      let style = { textDecoration: 'none', color: "grey" };
      if (selected) {
        // style = { textDecoration: 'none', borderRadius: "25% 10%", backgroundColor: "#eceff1", color: "#1976d2" }
        // style = { textDecoration: 'none', borderRadius: "25% 0", backgroundColor: "#eceff1", color: "#1976d2" }
        // style = { textDecoration: 'none', borderRadius: "25% 0", backgroundColor: "#1976d2", color: "#ffffff" }
        style = { textDecoration: 'none', borderRadius: "10% 10% 0 0", backgroundColor: /*"#B5B5B5"*/'white', color: "#212121" }
      }

      return (
        // <Tab
        //   component="a"
        //   // onClick={(event) => {
        //   //   event.preventDefault();
        //   // }}
        //   {...props}
        // >
        // </Tab>
        // <Button
        //   {...props}
        //   // onClick={handleClickTab}
        // >
        //     {props.label}
        //     {
        //       // props.href !== "/" && (
        //         <IconButton size="small" aria-label="close" onClick={closeTab}>
        //           <Close fontSize="inherit" />
        //         </IconButton>
        //       // )
        //     }
        // </Button>
        // <RouterLink to={location.pathname !== props.href ? props.href : "#"} style={style}>
        <RouterLink to={href} style={style} onClick={(e) => location.pathname === href && e.preventDefault()}>
          <Typography
            variant="subtitle2"
            noWrap
            component="div"
            // sx={{ ml:2, mt:0.3, mr: 1, display: { xs: 'none', md: 'flex' }, fontWeight: selected ? "bold" : "" }}
            sx={{ p: 1, mt: -0.5, display: { xs: 'none', md: 'flex' }, fontWeight: selected ? "bold" : "" }}
            display="flex" alignItems="center"
          >
            {label}
            {/* <IconButton size="small" aria-label="close" onClick={closeTab} sx={{ ml:1, color: selected ? '#212121' : "" }}> */}
              <Close fontSize="inherit" onClick={closeTab} sx={{ ml: 1, width: '10px', height: '10px' }}/>
            {/* </IconButton> */}
          </Typography>
        </RouterLink>
      );
    }

    useEffect(
      () => {
        if (sessionUser) {
          // profile, userAccount 처리
          if (location.pathname === '/profile' || location.pathname === '/userAccount') {
            const menuToAdd = location.pathname === '/profile' ? {
              menu: {
                href: "/profile",
                id: "profile",
                title: "내 정보",
              },
            } : {
              menu: {
                href: "/userAccount",
                id: "userAccount",
                title: "사용자 계정",
              },
            };
            menuToAdd.path = [{title: menuToAdd.menu.title}]; // path 추가하여 메뉴 경로 표시하도록 함
        
            dispatch(menuActions.setSelectedMenu(menuToAdd));
            dispatch(menuActions.addTabMenu(menuToAdd.menu));
          } else {
            const selectedMenuInfo = searchMenu({ location, menus});
            if (selectedMenuInfo) {
              console.log(selectedMenuInfo);
              // setSelectedMenuInfo(selectedMenuInfo);
              dispatch(menuActions.setSelectedMenu(selectedMenuInfo));
              dispatch(menuActions.addTabMenu(selectedMenuInfo.menu));
            }
          }
        }
      }, [location, menus, sessionUser] // menus는 warning 제거용으로 추가
    );

    useEffect(
      () => {
        tabMenus.forEach((tabMenu, i) => {
          // if (selectedMenuInfo.menu.id === 'profile') {
          //   setValue(i);
          //   return;
          // }

          if (tabMenu?.id === selectedMenuInfo.menu?.id) {
            setValue(i);
            return;
          }
        })
      }, [tabMenus]
    )

    const validMenu = selectedMenuInfo && selectedMenuInfo.menu && selectedMenuInfo.menu.title;
    const validPath = selectedMenuInfo && selectedMenuInfo.path && selectedMenuInfo.path.length > 0;

    // const chunk = (arr, size) => {
    //   return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    //     arr.slice(i * size, i * size + size)
    //   );
    // };
    const chunk = (arr, size) => {
      // 배열을 역순으로 정렬하여 최신 메뉴가 뒤쪽 청크에 위치하도록 함
      const reversedArr = [...arr].reverse();
      const chunks = Array.from({ length: Math.ceil(reversedArr.length / size) }, (v, i) =>
        reversedArr.slice(i * size, i * size + size)
      );
      // 각 청크 내부의 순서는 다시 원래대로 되돌림
      return chunks.map(chunk => chunk.reverse());
    };

    const closeTab = async (e, tabMenu) => {
      e.preventDefault(); // e.preventDefault(); 가 여기에 위치시 삭제됨

      // 탭이 하나만 있을 경우 삭제하지 않고 알림
      if (tabMenus.length === 1) {
        alert('마지막 화면은 닫을 수 없습니다.');
        return;
      }
      
      await dispatch(menuActions.removeTabMenu(tabMenu));
      
      const selectedMenusByOrder = JSON.parse(sessionStorage.getItem('selectedMenusByOrder'));
      if (selectedMenusByOrder.length > 0) {
        // navigate(selectedMenusByOrder[selectedMenusByOrder.length - 1].href);
        navigate(selectedMenusByOrder[0].href);
      } else {
        navigate("/");
      }
    }

    return (
      <ThemeProvider theme={theme}>
        {/* <Container component="main" maxWidth={false} sx={{ bgcolor: '#E0E0E0', minHeight: "34px", height: "34px" }}>
          <CssBaseline />
          {
            sessionUser && tabMenus.length > 0 && (
              <Grid container sx={{ mt: 0.5 }}>
                <Grid item xs={10}>
                  <Tabs
                    value={value}
                    // onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                    TabIndicatorProps={{
                      style: {
                        // backgroundColor: "#212121"
                        backgroundColor: "white"
                      }
                    }}
                    sx={{ minHeight: "30px", height: "30px", mt: 0.5 }}
                  >
                    {
                      tabMenus.map((tabMenu, i) => {
                        return (
                          <LinkTab key={`tab-${i}`} label={`${tabMenu.form_title || tabMenu.title}`} href={`${tabMenu.href}`} tabMenu={tabMenu} selected={i===value}>
                          </LinkTab> 
                        )
                      })
                    }
                  </Tabs>
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="center">
                  <Button
                    endIcon={<Close sx={{ width: '14px', height: '14px' }} />}
                    sx={{
                      // mt:2,
                      color: (theme) => theme.palette.grey[600],
                    }}
                    onClick={handleCloseAllTabs}
                  >
                    {"전체닫기"}
                  </Button>
                </Grid>
              </Grid>
            )
          }
        </Container> */}
        <Container
          component="main"
          maxWidth={false}
          sx={{ 
            bgcolor: '#E0E0E0', 
            height: "auto",
            padding: "0 !important", // 좌우 패딩 제거
            // marginTop: "-4px",
          }}
        >
          <CssBaseline />
          {
            sessionUser && tabMenus.length > 0 && (
              <Grid container>
                <Grid item xs={12}>
                  <Stack 
                    sx={{ 
                      height: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0px', // 행간 간격 (필요시 조정)
                    }}
                  >
                    {chunk(tabMenus, 12).map((row, rowIndex) => (
                      <Stack 
                        key={`row-${rowIndex}`}
                        direction="row"
                        sx={{
                          flex: 1,
                          borderTop: rowIndex > 0 ? '1px solid #C0C0C0' : 'none', // 첫 번째 행을 제외한 모든 행에 상단 구분선 추가
                        }}
                      >
                        {row.map((tabMenu, i) => {
                          // 툴팁에 표시할 메뉴 경로 생성
                          const menuPath = selectedMenuInfo.menu?.id === tabMenu.id && selectedMenuInfo.path
                            ? selectedMenuInfo.path.map(p => p.title).join(' > ')
                            : '';

                          return (
                            <Tooltip 
                              key={`tab-${rowIndex}-${i}`}
                              title={menuPath}
                              arrow
                              placement="bottom"
                              enterDelay={1000}
                              leaveDelay={0}
                              enterNextDelay={1000}
                              // PopperProps={{
                              //   modifiers: [
                              //     {
                              //       name: 'offset',
                              //       options: {
                              //         offset: [0, -30], // [세로, 가로] 오프셋 조정
                              //       },
                              //     },
                              //   ],
                              // }}                
                            >
                              <Box 
                                key={`tab-${rowIndex}-${i}`} 
                                sx={{ 
                                  flex: 1,
                                  height: '36px',
                                  backgroundColor: tabMenu.id === selectedMenuInfo.menu?.id ? '#F0F0F0' : '#DBDBDB', // value 대신 selectedMenuInfo.menu?.id 사용
                                  borderLeft: i > 0 ? '1px solid #C0C0C0' : 'none',
                                }}
                              >
                                <RouterLink 
                                  to={tabMenu.href} 
                                  style={{ 
                                    textDecoration: 'none',
                                    width: '100%',
                                    height: '100%',
                                    display: 'block'
                                  }} 
                                  onClick={(e) => location.pathname === tabMenu.href && e.preventDefault()}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      px: 2,
                                      height: '100%',
                                    }}
                                  >
                                    <Close 
                                      fontSize="small"
                                      onClick={(e) => closeTab(e, tabMenu)}
                                      sx={{ 
                                        width: '14px', 
                                        height: '14px',
                                        color: i === value ? '#212121' : 'grey'
                                      }}
                                    />
                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        color: tabMenu.id === selectedMenuInfo.menu?.id ? '#212121' : '#9E9E9E',
                                        fontWeight: tabMenu.id === selectedMenuInfo.menu?.id ? 'bold' : 'normal',
                                        flex: 1,
                                        textAlign: 'center',
                                      }}
                                    >
                                      {tabMenu.form_title || tabMenu.title}
                                    </Typography>
                                  </Box>
                                </RouterLink>
                              </Box>
                            </Tooltip>
                          )
                        })}
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            )
          }
        </Container>
        {/* <Container
          component="main"
          maxWidth={
            location.pathname === "/glass/dashboard" 
              ? false 
              : (urlMaxWidth.find(url => url === location.pathname) ? "false" : "lg")
          }
          sx={{
            ...(location.pathname === "/glass/dashboard" && {
              maxWidth: "1800px !important"
            })
          }}
        >
          {
            sessionUser && (
              <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5">
                    {validMenu ? (selectedMenuInfo.menu.form_title ? selectedMenuInfo.menu.form_title : (selectedMenuInfo.menu.title || "")) : ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end" alignItems="flex-end"> 
                  <Typography sx={{ letterSpacing: 10 }} color="text.primary">{validPath && "▷"}</Typography>
                  <Breadcrumbs aria-label="breadcrumb">
                    {
                      validPath && (
                        selectedMenuInfo.path.map((path, index) => 
                          // <Typography color="text.primary">{path[0].title}</Typography>
                          <Typography key={index} color="text.primary">{path.title}</Typography>
                        )
                      )
                    }
                  </Breadcrumbs>
                </Grid>
              </Grid>
            )
          }
        </Container> */}
      </ThemeProvider>
    );
}

export default Header;
