import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Chip,
  CircularProgress,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Add,
  Attachment,
  Delete,
  Edit,
  FilePresent,
  Filter1,
  Filter2,
  Grade,
  GradeOutlined,
  CheckBox,
  CheckBoxOutlineBlank,
  OpenInNew,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Preview,
  ListAlt,
  Close,
} from '@mui/icons-material';
import {
  FormInputAutoComplete,
} from "../form";
import {
  AlertDialog,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { StyledTooltip } from "../tooltip";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import * as gstandardActions from "../../store/gstandard";
import * as gclientG04docuGCertificationMapActions from "../../store/gclientG04docuGCertificationMap";
import * as gclientG04GeneralDocuMapActions from "../../store/gclientG04GeneralDocuMap";
import * as gclientActions from "../../store/gclient";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as g04docuGTestActions from "../../store/g04docuGTest";
import * as g04docuEtcActions from "../../store/g04docuEtc";
import * as g04GeneralDocuActions from "../../store/g04GeneralDocu";
import * as gclientGComponentItemMapActions from "../../store/gclientGComponentItemMap";
import * as gclientG04docuGTestMapActions from "../../store/gclientG04docuGTestMap";
import * as gclientG04docuEtcMapActions from "../../store/gclientG04docuEtcMap";
import * as gcomponentItemActions from "../../store/gcomponentItem";
import G04docuFileDialogGeneral from "./G04docuFileDialogGeneral";
import G04docuFileDialogSupplyRecord from "./G04docuFileDialogSupplyRecord";
import G04docuFileDialog from "./G04docuFileDialog";
import G04docuFileDialogEtc from "./G04docuFileDialogEtc";
import G04docuDialogGCertification from "./G04docuDialogGCertification";
import G04docuDialogGTest from "./G04docuDialogGTest";
import G04docuDialogEtc from "./G04docuDialogEtc";

import { getMaterialItemTypeOptions, MATERIAL_ITEM_TYPE } from '../../constants/itemTypes';
import { getGridHeight } from '../../constants/gridHeights';
import DocumentViewerDrawer from "../common/viewer/DocumentViewerDrawer";

const theme = createTheme();

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const defaultValues = {
  gclient: {},
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const G04docuFileManagement = ({ title, from }) => {
  const [gclients, setGClients] = useState([]);
  const [open, setOpen] = useState(false);
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openSupplyRecord, setOpenSupplyRecord] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadedByGComponentItem, setLoadedByGComponentItem] = useState(true);
  const [loadedEtc, setLoadedEtc] = useState(false);
  const [show, setShow] = useState(false);
  const [showByGComponentItem, setShowByGComponentItem] = useState(false);
  const [params, setParams] = useState({});
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectedGClientId, setSelectedGClientId] = useState("");
  const [selectedGClient, setSelectedGClient] = useState(null);
  // const [selectedGComponentItemG04docuId, setSelectedGComponentItemG04docuId] = useState(""); // 성적서 등록을 위해 GComponentItem 선택시 해당 GComponentItem에 연결된 g04docuId (인증서나 성적서의 g04docuId가 아닌 규격 g04docuId임) => 성적서를 인증계층에서 떼어 원부자재와 연결했으므로 필요없어짐
  const [selectedGComponentItemId, setSelectedGComponentItemId] = useState(null); // 성적서 등록을 위해 GComponentItem 선택시 gcomponentItemId 기록
  const [selectedGComponentItemName, setSelectedGComponentItemName] = useState(""); // 성적서 등록을 위해 GComponentItem 선택시 gcomponentItemName 기록
  const [selectedG04docuGCertificationId, setSelectedG04docuGCertificationId] = useState(null);
  const [selectedDocuFileType, setSelectedDocuFileType] = useState("");
  const [selectedDocumentPath, setSelectedDocumentPath] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [documentTypeGeneral, setDocumentTypeGeneral] = useState("");
  const [titleGeneral, setTitleGeneral] = useState("");
  const [g04GeneralDocuId, setG04GeneralDocuId] = useState("");
  const [gclientG04GeneralDocuMaps, setGclientG04GeneralDocuMaps] = useState([]);
  const [tabValue, setTabValue] = useState(0); // 성적서를 기본으로 했으나 국세,지방세납입증명서가 유효기간이 짧아 일반문서를 기본으로 함
  const [openGTest, setOpenGTest] = useState(false);
  const [crudModeGTest, setCrudModeGTest] = useState('');
  const [selectedRowGTest, setSelectedRowGTest] = useState({});
  const [openGCertification, setOpenGCertification] = useState(false);
  const [crudModeGCertification, setCrudModeGCertification] = useState('');
  const [selectedRowGeneral, setSelectedRowGeneral] = useState({});
  const [selectedRowGCertification, setSelectedRowGCertification] = useState({});
  const [gstandardId, setGStandardId] = useState("");
  const [alertInfo, setAlertInfo] = useState({});
  const [selectedGComponentItemType, setSelectedGComponentItemType] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeObject, setRemoveObject] = useState({});
  const [confirmOpenMajority, setConfirmOpenMajority] = useState(false);
  const [modifyObjectMajority, setModifyObjectMajority] = useState({});
  const [checked, setChecked] = useState(false);
  const [openEtc, setOpenEtc] = useState(false);
  const [crudModeEtc, setCrudModeEtc] = useState('');
  const [selectedRowEtc, setSelectedRowEtc] = useState({});
  const [removeObjectEtc, setRemoveObjectEtc] = useState({});
  const [paramsEtc, setParamsEtc] = useState({});
  const [confirmOpenEtc, setConfirmOpenEtc] = useState(false);
  const [checkedEtc, setCheckedEtc] = useState(false);
  const [openFileEtc, setOpenFileEtc] = useState(false);
  const [removeFileObject, setRemoveFileObject] = useState({});
  const [confirmOpenRemoveFile, setConfirmOpenRemoveFile] = useState(false);
  const [documentViewerDrawerOpen, setDocumentViewerDrawerOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  // 현재 화면에 맞는 Grid 높이 계산
  const GRID_HEIGHT = getGridHeight('TWO_LINE_BUTTON_LAYER', 0); 

  const handleSelectGCertification = async ({ type, params }) => {
    console.log(params.row)

    const { id, name, gclientId, documentPath, validYN, startDate, endDate } = params.row;
    if (type === 'attachDocument') {
      setSelectedRowGCertification({
        g04docuGCertificationId: id,
        g04docuGCertificationName: name,
        documentPath,
        validYN,
        startDate,
        endDate,
      });
      setSelectedDocuFileType('CERTIFICATION');
      setOpen(true);
    } else if (type === 'deleteDocument') {
      // 파일 삭제와 매핑 테이블 삭제 동시에 함
      if (documentPath) {
        setRemoveFileObject({ gclientId, g04docuGCertificationId: id, documentPath, type: 'CERTIFICATION' });
        setConfirmOpenRemoveFile(true);
      }
    } else if (type === 'edit') {
      const res = await selectG04docuGCertificationDirect(id, selectedGClientId); // gclientId는 첨부된 파일이 없을 경우 null 임
      // console.log(res[0]);
      setSelectedRowGCertification(res[0]);
      setCrudModeGCertification('U');
      setOpenGCertification(true);
    }
  }

  const handleSelectGTest = async ({ type, params }) => {
    console.log(params.row)
    const { id, code, selectedClassifications, sample, gcomponentItemId, g04docuGCertificationId, g04docuGCertificationCode, g04docuGCertificationName, division, name, gclientId, documentPath, validYN, startDate, endDate, gstandardId, gstandardNo, gstandardName, guideLink } = params.row;
    
    setSelectedGComponentItemId(gcomponentItemId);
    setSelectedGComponentItemType(division);
    
    if (type === 'attachDocument') {
      setSelectedRowGTest({
        division,
        g04docuGTestId : id,
        g04docuGTestName : name,
        code,
        selectedClassifications,
        sample,
        g04docuGCertificationId,
        g04docuGCertificationName,
        documentPath,
        validYN,
        startDate,
        endDate,
        gstandardId,
        gstandardNo,
        gstandardName,
        guideLink,
      });
      setSelectedDocuFileType('TEST');      
      setOpen(true);
    } else if (type === 'deleteDocument') {
      // TODO : 파일 삭제와 매핑 테이블 삭제 동시에 할 것
      if (documentPath) {
        setRemoveFileObject({ gclientId, g04docuGTestId: id, division, g04docuGCertificationId, gcomponentItemId, documentPath, type: 'TEST' });
        setConfirmOpenRemoveFile(true);
      }
    } else if (type === 'detail') {
      const res = await selectG04docuGTestDirect(id);
      if (division === 'RAW_MATERIAL') {
        await selectAllByGcomponentCode('GL THICKNESS'); // TODO : 매번 호출하지 않아도 될 듯
      } else if (division === 'SUB_MATERIAL_PROCESS' && g04docuGCertificationCode === 'LAMINATED_FILM') {
        await selectAllByGcomponentCode('FILM THICKNESS');
      }
      console.log(res[0])
      setSelectedRowGTest(res[0]);
      setCrudModeGTest('R');
      setOpenGTest(true);
    } else if (type === 'edit') {
      const res = await selectG04docuGTestDirect(id);
      if (division === 'RAW_MATERIAL') {
        await selectAllByGcomponentCode('GL THICKNESS'); // TODO : 매번 호출하지 않아도 될 듯
      } else if (division === 'SUB_MATERIAL_PROCESS' && g04docuGCertificationCode === 'LAMINATED_FILM') {
        await selectAllByGcomponentCode('FILM THICKNESS');
      }
      setSelectedRowGTest(res[0]);
      setCrudModeGTest('U');
      setOpenGTest(true);
    } else if (type === 'delete') {
      setRemoveObject({ id, gcomponentItemId, g04docuGCertificationId, division, gclientId, g04docuGTestId: id, documentPath });
      setParams(params);
      setConfirmOpen(true);
    }
  }

  const remove = (removeObject) => {
    // console.log(removeObject);
    const { id, gcomponentItemId, g04docuGCertificationId, division, gclientId, g04docuGTestId, documentPath } = removeObject;

    removeG04docuGTestWithFile({ id, gclientId, g04docuGTestId, documentPath })
      .then(async res => {
        await selectAllByGComponentItemOrProcessByQuery(division === 'PROCESS' ? g04docuGCertificationId : gcomponentItemId, division, selectedGClientId);
      })
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  const generateActions = (params/*, type*/) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<Attachment />}
        label={"서류첨부"}
        onClick={() => handleSelectGCertification({ type: 'attachDocument', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"서류삭제"}
        onClick={() => handleSelectGCertification({ type: 'deleteDocument', params })}
        showInMenu
        disabled={params.row.documentPath ? false : true}
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelectGCertification({ type: 'edit', params })}
        showInMenu
      />,
    ];
    
    // if (params.row.type === type) {
      return arrActions;
    // } else {
    //   return [];
    // }
  }

  const handleSelectMyItem = ({ /*type, */params }) => {
    console.log(params.row)
    const { id, name, code, classifications, gstandardId, gstandard, g04docuGCertificationId, g04docuGCertificationName, g04docuGCertificationCode, gcomponentItemType, selectedClassifications } = params.row;

    if (!gstandardId) {
      setAlertInfo({
        titleAlert: "안내",
        messageAlert: (
          <div>
            <span>{"원부자재에 "}</span>
            <span style={{ color: "#d32f2f" }}><i><u>{"규격이 설정되어 있지 않습니다."}</u></i></span>
            <br/>
            <span>{"먼저 규격을 설정하세요."}</span>
          </div>
        ),
        open: true,
      });

      return;
    }

    setGStandardId(gstandardId);

    if (gcomponentItemType === 'PROCESS') {
      setSelectedG04docuGCertificationId(id); // 공정의 경우 id와 g04docuGCertificationId는 같음
    } else { // 원부자재
      if (gcomponentItemType === 'RAW_MATERIAL') {
        selectAllByGcomponentCode('GL THICKNESS'); // TODO : 매번 호출하지 않아도 될 듯
      } else if (gcomponentItemType === 'SUB_MATERIAL_PROCESS' && g04docuGCertificationCode === 'LAMINATED_FILM') {
        selectAllByGcomponentCode('FILM THICKNESS');
      }

      setSelectedGComponentItemId(id);
      setSelectedGComponentItemName(name);
    }
    
    // TODO : 아래에서 selectedRowGTest에 행 전체를 넘기므로 위의 set~ 들은 제거 검토
    // setSelectedGComponentItemType(gcomponentItemType);
    
    // 아래는 params.row 데이터임 (1)
    // GClientGComponentItemMaps : gigcm, GComponentItems : gci, GComponents : gc, G04docuGCertification : g04docugc
    // gigcm."gcomponentItemId" AS id,
    // gci.name,
    // gigcm."itemProductName",
    // gci.code,
    // gigcm."gcomponentItemType",
    // gigcm."gclientId",
    // gigcm.gclient,
    // gci."gcomponentId" AS "gId",
    // gc.name AS "gName",
    // gci."g04docuGCertificationId",
    // g04docugc.name AS "g04docuGCertificationName",
    // g04docugc."gstandardId",
    // gst.no AS "gstandardNo",
    // gst.name AS "gstandardName",
    // gci.comments,
    // gci.order,
    // gci."orderDetail",
    // gci."createdAt",
    // gci."updatedAt"

    // 아래는 G04docuGTests의 구조임 (2)
    // id: "",
    // name: "",
    // code: "",
    // comments: "",
    // majority: false,
    // divider: " ",
    // gcomponentItemId: null,
    // g04docuGCertificationId: null,
    // gstandardTypeId: null,

    // if (type === 'open') {
    // G04docuDialogGTest에서 selectedRowGTest가 등록시, 수정시 사용되는데 등록시는 (1)에서 (2)로 데이터를 맞추어 설정해줘야 함
    const newSelectedRow = {
      id: "",
      gcomponentItemId: gcomponentItemType === 'PROCESS' ? null : id,
      // gcomponentItemName: name, // 별도의 값으로 넘김. selectedGComponentItemName
      g04docuGCertificationId: g04docuGCertificationId,
      g04docuGCertificationName,
      g04docuGCertificationCode,
      division: gcomponentItemType,
      name: "",
      // code: gcomponentItemType === 'PROCESS' ? "" : code,
      // code: "",
      code: gcomponentItemType === 'PROCESS' || gcomponentItemType === 'RAW_MATERIAL' ? "" : code,
      // gcertificationDetails: details,
      // selectedClassifications: gstandard && Array.isArray(gstandard) && gstandard.length === 1 && gstandard[0].durabilityYN === true ? classifications : selectedClassifications,
      classifications, // G04docuGCertifications의 classifications
      selectedClassifications, // 원부자재에서 선택한 인증등급
      divider: " ",
      comments: "",
      majority: false,
      // gstandardId, // TODO : 별도의 값으로 넘길 것
      gstandardTypeId: null,
      // gcomponentItemCode: gcomponentItemType === 'PROCESS' ? null : code,
      gstandard,
    };
    
    setSelectedRowGTest(newSelectedRow);

    setCrudModeGTest('C');
    setOpenGTest(true);
    // }
  }

  const generateItemActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        // label={"성적서 목록 등록"}
        label={"성적서 규격 등록"}
        onClick={() => handleSelectMyItem({ /*type: 'open', */params })}
        showInMenu
      />,
    ];
    
    return arrActions;
  }

  const columnsGeneral = [
    {
      field: 'name',
      headerName: '이름',
      width: 240,
      // editable: true,
    },
    {
      field: 'documentPath',
      headerName: '서류',
      width: 100,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        // console.log(params)
        const { validYN, valid, documentPath } = params.row;
        if (params.value) {
          return (
            // <StyledTooltip
            //   title={`[다운로드] ${documentPath?.split("/")[documentPath?.split("/")?.length-1]}`}
            //   placement="right"
            //   sx={tooltipTop}
            // >
            //   <IconButton
            //     color="primary"
            //     aria-label="file"
            //     edge="end"
            //     onClick={(e) => handleClickFileDownload(e, documentPath)}
            //     sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
            //   >
            //     <FilePresent />
            //   </IconButton>
            // </StyledTooltip>
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(documentPath)}
                sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    {
      field: 'endDate',
      headerName: '만료일',
      width: 120,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        console.log(params)
        // params.value => endDate
        if (params.row.validYN) {
          return "없음";
        } else {
          return params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "";
        }
      },
    },
    {
      field: 'mapUpdatedAt',
      headerName: '최종수정일시',
      width: 180,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value ? dateFormat(params.value) : "",
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"서류첨부/서류삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateGeneralDocuActions(params),
    },
  ];

  const columnsEtc = [
    {
      field: 'name',
      headerName: '이름',
      width: 240,
      // editable: true,
    },
    {
      field: 'type',
      headerName: '구분',
      width: 140,
      // editable: true,
      valueGetter: (params) => {
        const { value } = params;
        let text = "";
        if (value) {
          if (value === 'GENERAL') {
            text = "일반문서";
          } else if (value === 'CERTIFICATION') {
            text = "인증서";
          } else if (value === 'TEST') {
            text = "성적서";
          } else if (value === 'ETC') {
            text = "기타";
          }
        }

        return text;
      }
    },
    {
      field: 'documentPath',
      headerName: '서류',
      width: 100,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { validYN, valid, documentPath } = params.row;
        if (params.value) {
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(documentPath)}
                sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    {
      field: 'endDate',
      headerName: '만료일',
      width: 120,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      // valueGetter: (params) => params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "",
      valueGetter: (params) => {
        console.log(params)
        // params.value => endDate
        if (params.row.validYN) {
          return "없음";
        } else {
          return params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "";
        }
      },
    },
    {
      field: 'mapUpdatedAt',
      headerName: '최종수정일시',
      width: 180,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value ? dateFormat(params.value) : "",
    },
    {
      field: 'comments',
      headerName: '설명',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"서류첨부/서류삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: checkedEtc ? 200 : 70,
      type: 'actions',
      getActions: (params) => generateEtcActions(params),
    },
  ];

  const generateEtcActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<Attachment />}
        label={"서류첨부"}
        onClick={() => handleSelectEtc({ type: 'attachDocument', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"서류삭제"}
        onClick={() => handleSelectEtc({ type: 'deleteDocument', params })}
        showInMenu
        disabled={params.row.documentPath ? false : true}
      />,
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelectEtc({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelectEtc({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelectEtc({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checkedEtc) {
      arrActions = arrActions.concat([
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowUp />}
          label={"맨위로"}
          onClick={() => handleSelectUpDownEtc({ type: 'first', id: params.id })}
          disabled={params.row.orderDetail === 'F'}
        />,
        <GridActionsCellItem
          icon={<KeyboardArrowUp />}
          label={"위로"}
          onClick={() => handleSelectUpDownEtc({ type: 'up', id: params.id })}
          disabled={params.row.orderDetail === 'F'}
        />,
        <GridActionsCellItem
          icon={<KeyboardArrowDown />}
          label={"아래로"}
          onClick={() => handleSelectUpDownEtc({ type: 'down', id: params.id })}
          disabled={params.row.orderDetail === 'L'}
        />,
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowDown />}
          label={"맨아래로"}
          onClick={() => handleSelectUpDownEtc({ type: 'last', id: params.id })}
          disabled={params.row.orderDetail === 'L'}
        />
      ]);
    }

    return arrActions;
  }

  const handleSelectEtc = async ({ type, params }) => {
    console.log(params.row)
    const { id, name, code, gclientId, documentPath, validYN, startDate, endDate } = params.row;
    
    if (type === 'attachDocument') {
      setSelectedRowEtc({
        g04docuEtcId: id,
        documentType: code,
        title: name,
        documentPath,
        validYN,
        startDate,
        endDate,
      });
      
      setOpenFileEtc(true);
    } else if (type === 'deleteDocument') {
      const { documentPath } = params.row;
      // 파일 삭제와 매핑 테이블 삭제 동시에 함
      if (documentPath) {
        setRemoveFileObject({ gclientId, g04docuEtcId: id, documentPath, type: 'ETC' });
        setConfirmOpenRemoveFile(true);
      }
    } else if (type === 'detail') {
      const res = await selectG04docuEtcDirect(id);
      setSelectedRowEtc(res[0]);
      setCrudModeEtc('R');
      setOpenEtc(true);
    } else if (type === 'edit') {
      const res = await selectG04docuEtcDirect(id);
      setSelectedRowEtc(res[0]);
      setCrudModeEtc('U');
      setOpenEtc(true);
    } else if (type === 'delete') {
      setRemoveObjectEtc({ id, gclientId, g04docuEtcId: id, documentPath });
      setParamsEtc(params);
      setConfirmOpenEtc(true);
    }
  }

  const removeEtc = (removeObject) => {
    // console.log(removeObject);
    const { id, gclientId, g04docuEtcId, documentPath } = removeObject;

    removeG04docuEtcWithFile({ id, gclientId, g04docuEtcId, documentPath })
      .then(async res => {
        await selectG04docuEtcAllByGClientByQuery(gclientId);
      })
      .catch(async (res) => {
        const data = await res.json();
        if (data && data.errors) setErrors(data.errors);
      });
  }

  // 일반서류 기능
  const generateGeneralDocuActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<Attachment />}
        label={params.row.code === 'SUPPLY_RECORD' ? "서류직접첨부" : "서류첨부"}
        onClick={() => handleSelectGeneralDocu({ type: 'attachDocument', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"서류삭제"}
        onClick={() => handleSelectGeneralDocu({ type: 'deleteDocument', params })}
        showInMenu
        disabled={params.row.documentPath ? false : true}
      />,
    ];

    // 납품실적 문서인 경우 납품실적관리 메뉴 추가
    if (params.row.code === 'SUPPLY_RECORD') {
      arrActions.push(
        <GridActionsCellItem
          icon={<ListAlt />}
          label={"납품실적관리"}
          onClick={() => handleSelectGeneralDocu({ type: 'manageSupplyRecord', params })}
          showInMenu
        />
      );
    }

    return arrActions;
  }

  const columnsBasic = [
    // {
    //   field: 'type',
    //   headerName: '구분',
    //   width: 120,
    //   // hide: true,
    //   // editable: true,
    //   // headerAlign: 'center',
    //   // align: 'center',
    //   valueGetter: (params) => {
    //     if (params.value === "STANDARD") {
    //       return "규격";
    //     } else if (params.value === "CERTIFICATION") {
    //       return "인증서";
    //     } else if (params.value === "TEST") {
    //       return "성적서";
    //     }
    //   },
    // },
    {
      field: 'name',
      headerName: '이름',
      width: 300,
      // editable: true,
    },
    {
      field: 'division',
      headerName: '분류',
      width: 120,
      // editable: true,
      valueGetter: (params) => {
        if (params.value === "RAW_MATERIAL") {
          return "원자재";
        } else if (params.value === "SUB_MATERIAL_PROCESS") {
          return "가공부자재";
        } else if (params.value === "SUB_MATERIAL_BUILD") {
          return "시공부자재";
        } else if (params.value === "PROCESS") {
          return "공정";
        }
      }
    },
    {
      field: 'gstandardNo',
      headerName: '표준번호',
      width: 140,
      // editable: true,
    },
    {
      field: 'gstandardName',
      headerName: '표준명(한글)',
      width: 200,
      // editable: true,
    },
    // {
    //   field: 'comments',
    //   headerName: '설명',
    //   width: 280,
    //   // editable: true,
    // },
    {
      field: 'documentPath',
      headerName: '서류',
      width: 100,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { validYN, valid, documentPath } = params.row;
        if (params.value) {
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(documentPath)}
                sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    // {
    //   field: 'startDate',
    //   headerName: '유효기간 시작',
    //   width: 120,
    //   // editable: true,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "",
    // },
    {
      field: 'endDate',
      headerName: '만료일',
      width: 120,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      // valueGetter: (params) => params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "",
      valueGetter: (params) => {
        console.log(params)
        // params.value => endDate
        if (params.row.validYN) {
          return "없음";
        } else {
          return params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "";
        }
      },
    },
    {
      field: 'selectedClassifications',
      headerName: '종류·등급·호칭 또는 모델',
      flex: 1,
      renderCell: (params) => {
        // return params.value.filter(value => value.checked === true).map(value=> value.type).join(", ");
        return params.value?.filter(value => value.checked === true).map(value=> <Chip label={value.type} size="small" sx={{ ml: 1 }}></Chip>);
      }
    },
    // {
    //   field: 'pid',
    //   headerName: '부모아이디',
    //   width: 100,
    //   hide: true,
    //   // editable: true,
    // },
    // {
    //   field: 'createdAt',
    //   headerName: '생성일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: '수정일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
  ];

  const columnsG04docuGCertification = columnsBasic.concat([
    {
      field: 'actions',
      headerName: <Tooltip title={"서류첨부/서류삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateActions(params, 'CERTIFICATION'),
    },
  ]);

  const columnsItems = [
    {
      field: 'name',
      headerName: '이름',
      width: 250,
      // editable: true,
    },
    // {
    //   field: 'itemProductName',
    //   headerName: '세부제품명',
    //   width: 200,
    //   // editable: true,
    //   hide: true,
    // },
    // {
    //   field: 'gcomponentItemType',
    //   headerName: '대분류',
    //   width: 150,
    //   // editable: true,
    //   hide: true,
    //   valueGetter: (params) => {
    //     const { value } = params;
    //     let division = "";
    //     if (value === 'RAW_MATERIAL') {
    //       division = "원자재";
    //     } else if (value === 'SUB_MATERIAL_PROCESS') {
    //       division = "가공부자재";
    //     } else if (value === 'SUB_MATERIAL_BUILD') {
    //       division = "시공부자재";
    //     } else if (value === 'PROCESS') {
    //       division = "공정";
    //     }

    //     return division;
    //   },
    // },
    // {
    //   field: 'gName',
    //   headerName: '분류',
    //   width: 150,
    //   // editable: true,
    //   hide: true,
    // },
    {
      field: 'g04docuGCertification_dummy', // 필드는 없음
      // headerName: '규격',
      renderHeader: () => (<Stack direction="row" spacing={1} alignItems="center">
        <Chip label={"표준번호"} size="small" sx={{ mr: 1 }}></Chip><span>{"인증규격명"}</span>
      </Stack>),
      width: 600,
      // editable: true,
      // valueGetter: (params) => {
      //   if (params.row.g04docuName && params.row.gstandardNo) {
      //     return `${params.row.g04docuName} ( ${params.row.gstandardNo} )`
      //   }

      //   return "";
      // },
      renderCell: (params) => {
        if (params.row.g04docuGCertificationName && params.row.gstandardNo) {
          const { g04docuGCertificationName, gstandardNo, gstandardName } = params.row;
          return <>
            <Tooltip title={`표준명: ${gstandardName}`}><Chip label={gstandardNo} size="small" sx={{ mr: 1 }}></Chip></Tooltip>
            {g04docuGCertificationName}
          </>;
          // return <><Chip label={gstandardNo} size="small" sx={{ mr: 1 }}></Chip>{gstandardName}</>;
        } else {
          return "";
        }
      }
    },
    {
      field: 'valid',
      headerName: 'ON/OFF',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value ? "" : "OFF",
    },
    // {
    //   field: 'classifications',
    //   headerName: '인증등급',
    //   width: 300,
    //   hide: true,
    // },
    // {
    //   field: 'createdAt',
    //   headerName: '생성일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: '수정일시',
    //   width: 160,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => dateFormat(params.value),
    // },
    {
      field: 'actions',
      headerName: <Tooltip title={"성적규격등록"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateItemActions(params),
    },
  ];

  const handleSelectUpDown = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorder({ type, id });
    setLoadedByGComponentItem(false)
    await selectAllByGComponentItemOrProcessByQuery(selectedGComponentItemType === 'PROCESS' ? selectedG04docuGCertificationId : selectedGComponentItemId, selectedGComponentItemType, selectedGClientId);
    setTimeout(() => setLoadedByGComponentItem(true), 300)

    setOpenBackdrop(false);
  }

  const handleSelectUpDownEtc = async ({ type, id }) => {
    setOpenBackdrop(true);

    await reorderEtc({ type, id });
    setLoadedEtc(false)
    await selectG04docuEtcAllByGClientByQuery(selectedGClientId);
    setTimeout(() => setLoadedEtc(true), 300)

    setOpenBackdrop(false);
  }

  const generateGTestActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<Attachment />}
        label={"서류첨부"}
        onClick={() => handleSelectGTest({ type: 'attachDocument', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"서류삭제"}
        onClick={() => handleSelectGTest({ type: 'deleteDocument', params })}
        showInMenu
        disabled={params.row.documentPath ? false : true}
      />,
      <GridActionsCellItem
        icon={<OpenInNew />}
        label={"상세"}
        onClick={() => handleSelectGTest({ type: 'detail', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Edit />}
        label={"수정"}
        onClick={() => handleSelectGTest({ type: 'edit', params })}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<Delete />}
        label={"삭제"}
        onClick={() => handleSelectGTest({ type: 'delete', params })}
        showInMenu
      />,
    ];

    if (checked) {
      arrActions = arrActions.concat([
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowUp />}
          label={"맨위로"}
          onClick={() => handleSelectUpDown({ type: 'first', id: params.id })}
          disabled={params.row.orderDetail === 'F'}
        />,
        <GridActionsCellItem
          icon={<KeyboardArrowUp />}
          label={"위로"}
          onClick={() => handleSelectUpDown({ type: 'up', id: params.id })}
          disabled={params.row.orderDetail === 'F'}
        />,
        <GridActionsCellItem
          icon={<KeyboardArrowDown />}
          label={"아래로"}
          onClick={() => handleSelectUpDown({ type: 'down', id: params.id })}
          disabled={params.row.orderDetail === 'L'}
        />,
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowDown />}
          label={"맨아래로"}
          onClick={() => handleSelectUpDown({ type: 'last', id: params.id })}
          disabled={params.row.orderDetail === 'L'}
        />
      ]);
    }

    return arrActions;
  }

  const handleChangeOrder = () => {
    setChecked(!checked);
  }

  const handleClickMajority = (majority/* true */, params) => {
    console.log(params)
    const { id, g04docuGCertificationId, gcomponentItemId, division } = params;
    setModifyObjectMajority({ id, g04docuGCertificationId, gcomponentItemId, majority, division, gclientId: selectedGClientId });
    setConfirmOpenMajority(true);
  }

  const modifyMajority = async (modifyObjectMajority) => {
    const { id, g04docuGCertificationId, gcomponentItemId, majority, division, gclientId } = modifyObjectMajority;
    await modifyGTestMajority({ id, g04docuGCertificationId, gcomponentItemId, majority, division, gclientId });
  }

  const columnsByGComponentItem = [
    {
      field: 'name',
      // headerName: '시료',
      headerName: /*'제품명'*/'성적서명',
      width: 200,
      // editable: true,
    },
    // {
    //   field: 'majority',
    //   // headerName: '시료',
    //   headerName: '대표성적서',
    //   width: 140,
    //   headerAlign: 'center',
    //   align: 'center',
    //   // editable: true,
    //   hide: true,
    //   renderCell: (params) => {
    //     if (params.value) {
    //       return (
    //         <IconButton>
    //           <CheckBox color='primary' />
    //         </IconButton>
    //       );
    //     } else {
    //       return (
    //         <IconButton onClick={() => handleClickMajority(true, params.row)}>
    //           <CheckBoxOutlineBlank color='primary' />
    //         </IconButton>
    //       );
    //     }
    //   }
    // },
    {
      field: 'gstandardTypeName',
      headerName: '표준 종류',
      width: 100,
    },
    {
      field: 'selectedClassifications',
      headerName: '종류·등급·호칭 또는 모델',
      width: 300,
      renderCell: (params) => {
        // return params.value.filter(value => value.checked === true).map(value=> value.type).join(", ");
        return params.value?.filter(value => value.checked === true).map(value=> <Chip label={value.type} size="small" sx={{ ml: 1 }}></Chip>);
      }
    },
    {
      field: 'documentPath',
      headerName: '서류',
      width: 100,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { validYN, valid, documentPath } = params.row;
        if (params.value) {
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="left"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(documentPath)}
                sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    {
      field: 'gtestComparisonDocumentPath',
      headerName: '시험성과대비표',
      width: 100,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.value) {
          const { validYN, valid, gtestComparisonDocumentPath } = params.row;
          return (
            <StyledTooltip
              title={<Stack direction="row" display="flex" alignItems="center">
                <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                  {`미리보기`}
                </Box>
                <Typography variant="subtitle2">{gtestComparisonDocumentPath?.split("/")[gtestComparisonDocumentPath?.split("/")?.length-1]}</Typography>
              </Stack>}
              placement="right"
              sx={tooltipTop}
            >
              <IconButton
                color="primary"
                aria-label="file"
                onClick={() => handleClickViewEachDoc(gtestComparisonDocumentPath)}
                sx={{ color: (validYN || valid) ? "#1976D2" : "#ED6C02" }}
              >
                <Preview />
              </IconButton>
            </StyledTooltip>
          );
        }
      }
    },
    // {
    //   field: 'startDate',
    //   headerName: '유효기간 시작',
    //   width: 120,
    //   // editable: true,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueGetter: (params) => params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "",
    // },
    {
      field: 'endDate',
      headerName: '만료일',
      width: 120,
      // editable: true,
      headerAlign: 'center',
      align: 'center',
      // valueGetter: (params) => params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "",
      valueGetter: (params) => {
        console.log(params)
        // params.value => endDate
        if (params.row.validYN) {
          return "없음";
        } else {
          return params.value ? dateFormat(params.value, 'yyyy-MM-dd') : "";
        }
      },
    },
    {
      field: 'comments',
      headerName: '설명',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"서류첨부/서류삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: checked ? 200 : 70,
      type: 'actions',
      getActions: (params) => generateGTestActions(params),
    },
  ];

  const { handleSubmit, control, setValue } = useForm({ defaultValues: defaultValues });
  
  const sessionUser = useSelector(state => state.session.sessionUser);
  const rowsG04docuGCertifications = useSelector((state) => state.gclientG04docuGCertificationMap.gclientG04docuGCertificationMaps);
  const rowsGComponentItem = useSelector((state) => state.gclientGComponentItemMap.gcomponentItems);
  // const rowsTestByGComponentItem = useSelector((state) => state.gclientG04docuMap.gclientG04docuMapsForTest);
  const rowsTestByGComponentItem = useSelector((state) => state.gclientG04docuGTestMap.gclientG04docuGTestMaps);
  const rowsEtc = useSelector((state) => state.gclientG04docuEtcMap.gclientG04docuEtcMaps);

  // 데이터 관리
  const dispatch = useDispatch();

  const selectAll = (queryOptions) => dispatch(gstandardActions.selectPaging(queryOptions))
  const selectAllGClientDirect = () => gclientActions.selectAllDirect()
  const initializeGClientG04docuGCertificationMaps = () => dispatch(gclientG04docuGCertificationMapActions.initializeGClientG04docuGCertificationMaps())
  const selectAllByGClient = (gclientId) => dispatch(gclientG04docuGCertificationMapActions.selectAllByGClient(gclientId))
  const selectG04docuGCertificationDirect = (id, gclientId) => gclientG04docuGCertificationMapActions.selectByQueryDirect(id, gclientId)
  // const selectAllByGClientByG04docu = (gclientId, g04docuGCertificationId) => dispatch(gclientG04docuGCertificationMapActions.selectAllByGClientByG04docuGCertification(gclientId, g04docuGCertificationId))
  // const initByGClientByG04docuForTest = () => dispatch(gclientG04docuGCertificationMapActions.initializeByGClientByG04docuForTest())
  const selectAllByGComponentItemOrProcessByQuery = (id, division, gclientId) => dispatch(gclientG04docuGTestMapActions.selectAllByGComponentItemOrProcessByQuery(id, division, gclientId))
  const initializeGClientG04docuGTestMaps = () => dispatch(gclientG04docuGTestMapActions.initializeGClientG04docuGTestMaps())
  const removeGClientG04docuGTestMap = ({ gclientId, g04docuGTestId, documentPath }) => dispatch(gclientG04docuGTestMapActions.remove({ gclientId, g04docuGTestId, documentPath }))
  const removeG04docuGTestWithFile = ({ id, gclientId, g04docuGTestId, documentPath }) => dispatch(g04docuGTestActions.removeWithFile({ id, gclientId, g04docuGTestId, documentPath }))
  const modifyGTestMajority = ({ id, g04docuGCertificationId, gcomponentItemId, majority, division, gclientId }) => dispatch(gclientG04docuGTestMapActions.modifyMajority({ id, g04docuGCertificationId, gcomponentItemId, majority, division, gclientId }))
  const reorder = ({ type, id }) => g04docuGTestActions.reorder({ type, id })
  const selectG04docuGTestDirect = (id) => gclientG04docuGTestMapActions.selectByQueryDirect(id)
  const removeGClientG04docuCertificationMap = ({ gclientId, g04docuGCertificationId, documentPath }) => dispatch(gclientG04docuGCertificationMapActions.remove({ gclientId, g04docuGCertificationId, documentPath }))
  const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
  const removeG04docuFile = (documentPath) => g04docuFileActions.removeDirect(documentPath)
  const selectAllG04GeneralDocus = () => dispatch(g04GeneralDocuActions.selectAll())
  const initMines = () => dispatch(gclientGComponentItemMapActions.initializeMyGComponentItems())
  const selectAllMines = (gclientId) => dispatch(gclientGComponentItemMapActions.selectAllMyGComponentItems(gclientId))
  const selectAllGClientG04GeneralDocuByGClientDirect = (gclientId) => gclientG04GeneralDocuMapActions.selectAllByGClientDirect(gclientId)
  const removeGClientG04GeneralDocuMap = ({ gclientId, documentPath }) => dispatch(gclientG04GeneralDocuMapActions.remove({ gclientId, documentPath }))
  const selectG04docuEtcAllByGClientByQuery = (gclientId) => dispatch(gclientG04docuEtcMapActions.selectAllByGClientByQuery(gclientId))
  const selectG04docuEtcDirect = (id) => gclientG04docuEtcMapActions.selectByQueryDirect(id)
  const removeGClientG04docuEtcMap = ({ gclientId, g04docuEtcId, documentPath }) => dispatch(gclientG04docuEtcMapActions.remove({ gclientId, g04docuEtcId, documentPath }))
  const removeG04docuEtcWithFile = ({ id, gclientId, g04docuEtcId, documentPath }) => dispatch(g04docuEtcActions.removeWithFile({ id, gclientId, g04docuEtcId, documentPath }))
  const reorderEtc = ({ type, id }) => g04docuEtcActions.reorder({ type, id })
  const selectAllByGcomponentCode = (gcomponentCode) => dispatch(gcomponentItemActions.selectAllByGcomponentCode(gcomponentCode));

  const queryPaging = useMemo(
    () => ({
      page,
      pageSize,
    }), [page]
  );

  console.log("queryPaging")
  console.log(queryPaging)

  setTimeout(() => hideWatermark(), 300); // TODO : 검색시(탭변경이나 거래선 선택 등) hideWatermark()를 호출해도 이 코드가 있어야 매끄럽게 처리됨???

  useEffect(
    async () => {
      // console.log(sessionUser)
      // console.log(from)
      if (from?.params?.gcomponentItemId) {
        // setTabValue(1); // 없으면 TabPanel이 보이지 않음
        setTabValue(0); // 없으면 TabPanel이 보이지 않음
      }

      const resGClients = await selectAllGClientDirect();
      setGClients(resGClients);

      await selectAllG04GeneralDocus();
      
      initializeGClientG04docuGCertificationMaps(); // 인증서 부분 초기화
      initializeGClientG04docuGTestMaps(); // 제품별/공정별 성적서 부분 초기화

      // from?.params?.gclientId 만 있거나 둘다 있는 경우로 호출됨 (현재 거래선 화면에서 이곳을 사용함)
      if (from) {
        setLoaded(false);
        // setLoadedByGComponentItem(false);

        console.log(from.params)
        const { gclientId, g04docuGCertificationId, gcomponentItemId, gcomponentItemType } = from.params;
        if (!g04docuGCertificationId) { // 거래선 화면에서 제품에서 "자재승인서류" 버튼 선택시
          // 일반문서
          const maps = await selectAllGClientG04GeneralDocuByGClientDirect(from.params.gclientId);
          setGclientG04GeneralDocuMaps(maps);

          await selectAllByGClient(gclientId); // 인증서 규격 부분
          
          setSelectedGClientId(gclientId);
          setSelectedGClient(resGClients.find(gclient => gclient.id === gclientId));

          await selectAllMines(gclientId); // 관련 제품 부분
        }

        // TODO : "제품/가공품" => "제품/가공품"으로 전체 교체
        if (g04docuGCertificationId && gcomponentItemId) { // 거래선 화면의 제품 목록에서 "성적서" 메뉴 선택시 TODO : 점검필요
          await selectAllByGComponentItemOrProcessByQuery(gcomponentItemType === 'PROCESS' ? g04docuGCertificationId : gcomponentItemId, gcomponentItemType, gclientId); //제품/가공품별 성적서 부분
        }

        setSelectedGComponentItemType(gcomponentItemType);
        setSelectedG04docuGCertificationId(g04docuGCertificationId);
        setSelectedGComponentItemId(gcomponentItemId);
        setSelectedGClientId(gclientId);

        // setTimeout(async () => {
        //   setShow(true);
        //   setShowByGComponentItem(true);

        //   setTimeout(() => {
        //     setLoaded(true);
        //     setLoadedByGComponentItem(true);
        //   }, 300);
        // }, 300);
        setLoaded(true);
        // setLoadedByGComponentItem(true);
      } else {
        await initMines(); // 제품목록 초기화

        if (sessionUser) {
          const { id: gclientId, type } = sessionUser;
          console.log(sessionUser)
          if (gclientId) {
            // setValue("gclient", gclientId); // TODO : 거래선명 입력하는 방법 필요(필수는 아니나 FormInputAutoComplete에 값을 설정하는 방법 마련 필요)
            setSelectedGClientId(gclientId);
            setSelectedGClient(resGClients.find(gclient => gclient.id === gclientId));

            selectInfosByGClient(gclientId);

            if (type === 'GCLIENT' || type === 'ADMIN') {
              setTabValue(0); // 성적서를 기본으로 했으나 국세,지방세납입증명서가 유효기간이 짧아 일반문서를 기본으로 함
              // 거래선에서 관리(생산 또는 판매)하는 제품 또는 공정 목록과 성적 규격 조회
              await selectAllMines(gclientId);
            }
          }
        }

        // setTimeout(async () => {
        //   setTimeout(() => {
            setLoaded(true);
            // setLoadedByGComponentItem(true);
        //   }, 300);
        // }, 300);
      }
    }, [dispatch]
  );

  const selectPaging = async () => {
    setLoaded(false)

    await selectAll(queryPaging);
    
    setLoaded(true);
  }

  useEffect(
    () => {
      // selectPaging();
    }, [queryPaging]
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  }

  const selectInfosByGClient = async (id) => {
    setLoaded(false);
    // setLoadedByGComponentItem(false);
    setLoadedEtc(false);
    
    // 1. 일반문서
    // const maps = await selectAllGClientG04GeneralDocuByGClientDirect(id);
    // setGclientG04GeneralDocuMaps(maps);

    if (tabValue === 0) {
      // TODO 1. 일반문서
      const maps = await selectAllGClientG04GeneralDocuByGClientDirect(id);
      setGclientG04GeneralDocuMaps(maps);
    } else if (tabValue === 1) {
      // 2. 해당 제품의 인증 규격 조회
      await selectAllByGClient(id);
    } else if (tabValue === 2) {
      // 3. 거래선에서 관리(생산 또는 판매)하는 제품 또는 공정 목록과 성적 규격 조회
      await selectAllMines(id);
      // // 4. 제품/가공품별 성적서 부분 초기화
      // await initializeGClientG04docuGTestMaps();
    } else if (tabValue === 3) {
      await selectG04docuEtcAllByGClientByQuery(id);
    }

    if (tabValue !== 2) {
      // 4. 제품/가공품별 성적서 부분은 초기화 => 다른 탭으로 넘어갈 때 초기화하는 것이 좋겠다. 그렇지 않으면 이전에 검색했던 목록이 있다면 보였다가 사라진다.
      await initializeGClientG04docuGTestMaps();
    }

    // setTimeout(async () => {
    //   setTimeout(() => {
    //     setLoaded(true);
    //     setLoadedByGComponentItem(true);
    //     setLoadedEtc(true);
    //   }, 300);
    // }, 300);
    setLoaded(true);
    // setLoadedByGComponentItem(true);
    setLoadedEtc(true);
  }

  const handleChangeGClient = (e, value) => {
    const gclientId = value.id;
    
    setSelectedGClientId(gclientId);
    setSelectedGClient(gclients.find(gclient => gclient.id === gclientId));

    // PDF Drawer 닫기
    setDocumentViewerDrawerOpen(false);

    selectInfosByGClient(gclientId);
  }

  const refreshG04GeneralDocus = async (id) => {
    const maps = await selectAllGClientG04GeneralDocuByGClientDirect(id);
    setGclientG04GeneralDocuMaps(maps);
  }

  const handleSelectGeneralDocu = async ({ type, params }) => {
    console.log(params)
    const { id, code, name, documentPath, validYN, startDate, endDate } = params.row;
    
    if (type === 'attachDocument') {
      setSelectedRowGeneral({
        g04GeneralDocuId: id,
        documentType: code,
        title: name,
        documentPath,
        validYN,
        startDate,
        endDate,
      });
  
      // 납품증명서 첨부시 파일 업로드 화면 표시
      // if (code === 'SUPPLY_RECORD') {
      //   setOpenSupplyRecord(true);  
      // } else {
        setOpenGeneral(true);  
      // }
      
    } else if (type === 'deleteDocument') {
      // 파일 삭제와 매핑 테이블 삭제 동시에 함
      if (documentPath) {
        setRemoveFileObject({ gclientId: selectedGClientId, documentPath, type: 'GENERAL' });
        setConfirmOpenRemoveFile(true);
      }
    } else if (type === 'manageSupplyRecord') {
      setSelectedRowGeneral({
        title: name,
      });

      setOpenSupplyRecord(true);
    }
  }

  const removeFile = async () => {
    const { gclientId, g04docuGCertificationId, g04docuGTestId, g04docuEtcId, gcomponentItemId, division, documentPath, type } = removeFileObject;
    await removeG04docuFile(documentPath);

    if (type === 'GENERAL') {
      await removeGClientG04GeneralDocuMap({ gclientId, documentPath });
      refreshG04GeneralDocus(gclientId);
    } else if (type === 'CERTIFICATION') {
      await removeGClientG04docuCertificationMap({ gclientId, g04docuGCertificationId, documentPath });
      selectAllByGClient(gclientId);
    } else if (type === 'TEST') {
      await removeGClientG04docuGTestMap({ gclientId, g04docuGTestId, documentPath })
      selectAllByGComponentItemOrProcessByQuery(division === 'PROCESS' ? g04docuGCertificationId : gcomponentItemId, division, selectedGClientId);
    } else if (type === 'ETC') {
      await removeGClientG04docuEtcMap({ gclientId, g04docuEtcId, documentPath })
      selectG04docuEtcAllByGClientByQuery(selectedGClientId);
    }
  }

  // const handleClickGeneralDocu = ({ id, name, code, documentPath, startDate, endDate }) => {
  //   setSelectedRowGeneral({
  //     g04GeneralDocuId: id,
  //     documentType: code,
  //     title: name,
  //     documentPath,
  //     startDate,
  //     endDate,
  //   });

  //   setOpenGeneral(true);
  // }

  const handleClickFileDownload = async (e, documentPath) => {
    e.stopPropagation();

    const arr = documentPath.split("/");
    const fileName = arr[arr.length - 1];

    const res = await downloadG04docuFile(documentPath);
    const blob = await res.blob(); // res.blob는 promise를 리턴함???
    
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));            
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }

  // const handleClickViewEachDoc = (documentPath) => {
  //   const path = documentPath.replace(uploadFilePath, fileServerUrl);

  //   // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //   const randNumber = Math.floor(Math.random()*99);

  //   window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  // }

  const handleClickViewEachDoc = (documentPath) => {
    const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);
    
    // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
    const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');

    const path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;

    // window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
    setCurrentUrl(path);
    // Drawer가 닫혀있을 때만 열기
    if (!documentViewerDrawerOpen) {
      setDocumentViewerDrawerOpen(true);
    }
  }

  const handleChangeTab = async (event, newValue) => {
    setTabValue(newValue);

    setLoaded(false);
    // setLoadedByGComponentItem(false);
    setLoadedEtc(false);

    // setShow(false);
    // setShowByGComponentItem(false);
    
    const gclientId = selectedGClientId || from?.params?.gclientId;
    if (gclientId) {
      if (newValue === 0) {
        // 일반문서
        const maps = await selectAllGClientG04GeneralDocuByGClientDirect(gclientId);
        setGclientG04GeneralDocuMaps(maps);
      } else if (newValue === 1) {
        // 해당 제품의 인증 규격 조회
        await selectAllByGClient(gclientId);
      } else if (newValue === 2) {
        // 거래선에서 관리(생산 또는 판매)하는 제품 또는 공정 목록과 성적 규격 조회
        await selectAllMines(gclientId);
      } else if (newValue === 3) {
        await selectG04docuEtcAllByGClientByQuery(gclientId);
      }

      if (newValue !== 2) {
        // 제품/가공품별 성적서 부분은 초기화 => 다른 탭으로 넘어갈 때 초기화하는 것이 좋겠다. 그렇지 않으면 이전에 검색했던 목록이 있다면 보였다가 사라진다.
        await initializeGClientG04docuGTestMaps();
      }
    }

    // setTimeout(async () => {
    //   // await hideWatermark();

    //   setShow(true);
    //   setShowByGComponentItem(true);

    //   setTimeout(() => {
    //     setLoaded(true);
    //     setLoadedByGComponentItem(true);
    //     setLoadedEtc(true);
    //   }, 300);
    // }, 300);
    setLoaded(true);
    // setLoadedByGComponentItem(true);
    setLoadedEtc(true);

    // setTabValue(newValue); // 탭을 먼저 바꾸지 않으면 전 탭의 동작이 다 보임. 따라서 제일 위로 옮김
  }

  const handleClickAddEtc = () => {
    setCrudModeEtc('C');
    setOpenEtc(true);
  }

  const handleChangeOrderEtc = () => {
    setCheckedEtc(!checkedEtc);
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <G04docuFileDialogGeneral
          openGeneral={openGeneral}
          setOpenGeneral={setOpenGeneral}
          selectedRow={selectedRowGeneral}
          selectedGClientId={selectedGClientId}
          selectedGClient={selectedGClient}
          refresh={refreshG04GeneralDocus}
        />
        <G04docuFileDialogSupplyRecord
          openSupplyRecord={openSupplyRecord}
          setOpenSupplyRecord={setOpenSupplyRecord}
          selectedRow={selectedRowGeneral}
          selectedGClientId={selectedGClientId}
          selectedGClient={selectedGClient}
          refresh={refreshG04GeneralDocus}
        />
        <G04docuFileDialog
          open={open}
          setOpen={setOpen}
          selectedRow={selectedDocuFileType === 'CERTIFICATION' ? selectedRowGCertification : selectedRowGTest}
          selectedGClientId={selectedGClientId}
          selectedGClient={selectedGClient}
          selectedGComponentItemId={selectedGComponentItemId}
          selectedGComponentItemName={selectedGComponentItemName}
          selectedGComponentItemType={selectedGComponentItemType}
          setSelectedGComponentItemId={setSelectedGComponentItemId}
          setSelectedGComponentItemName={setSelectedGComponentItemName}
          setSelectedGComponentItemType={setSelectedGComponentItemType}
          selectedDocuFileType={selectedDocuFileType}
          refresh={
            selectedDocuFileType === 'CERTIFICATION' ? 
              selectAllByGClient : 
              selectAllByGComponentItemOrProcessByQuery
          }
        />
        <G04docuFileDialogEtc
          open={openFileEtc}
          setOpen={setOpenFileEtc}
          selectedRow={selectedRowEtc}
          gclientId={selectedGClientId}
          gclient={selectedGClient}
          refresh={selectG04docuEtcAllByGClientByQuery}
        />
        <G04docuDialogGCertification
          open={openGCertification}
          setOpen={setOpenGCertification}
          crudMode={crudModeGCertification}
          setCrudMode={setCrudModeGCertification}
          selectedGClientId={from?.params?.gclientId || selectedGClientId}
          selectedRow={selectedRowGCertification}
          refresh={selectAllByGClient}
        />
        <G04docuDialogGTest
          open={openGTest}
          setOpen={setOpenGTest}
          crudMode={crudModeGTest}
          setCrudMode={setCrudModeGTest}
          selectedRow={selectedRowGTest}
          selectedGComponentItemName={selectedGComponentItemName}
          gstandardId={gstandardId}
          gclientId={selectedGClientId}
          gclient={selectedGClient}
          refresh={selectAllByGComponentItemOrProcessByQuery}
        />
        <G04docuDialogEtc
          open={openEtc}
          setOpen={setOpenEtc}
          crudMode={crudModeEtc}
          setCrudMode={setCrudModeEtc}
          selectedRow={selectedRowEtc}
          gclientId={selectedGClientId}
          refresh={selectG04docuEtcAllByGClientByQuery}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {
                  title && (
                    <Grid item xs={10}>
                      <Typography variant="h5">
                        {title}
                      </Typography>
                    </Grid>
                  )
                }
                {
                  (!from?.params?.g04docuGCertificationId && !from?.params?.gclientId) && (
                    <>
                      {
                        sessionUser.type === 'ADMIN' && (<Grid item xs={4} display="flex" justifyContent="flex-start" alignItems="center">
                          <FormInputAutoComplete
                            name={"gclient"}
                            control={control}
                            label={"거래선명"}
                            noOptionsText={'해당 거래선이 없습니다.'}
                            options={gclients.map(gclient => ({ label: gclient.name, id: gclient.id }))}
                            setValue={setValue}
                            onCustomChange={handleChangeGClient}
                          />
                        </Grid>)
                      }
                    </>
                  )
                }
              </Grid>
            </Grid>
            {
              !from?.params?.gcomponentItemId && (
                <>
                  <Grid item xs={6}>
                    <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                      { /*sessionUser.type === 'ADMIN' && */<Tab label={"일반서류"} {...a11yProps(0)} sx={{ fontWeight: 'bold' }} value={0} /> }
                      { /*sessionUser.type === 'ADMIN' && */<Tab label={"인증서"} {...a11yProps(1)} sx={{ fontWeight: 'bold' }} value={1} /*disabled={from?.params?.gcomponentItemId}*/ /> }
                      <Tab label={"성적서"} {...a11yProps(2)} sx={{ fontWeight: 'bold' }} value={2} />
                      <Tab label={"기타"} {...a11yProps(3)} sx={{ fontWeight: 'bold' }} value={3} />
                    </Tabs>
                  </Grid>
                  {
                    tabValue === 3 && (
                      <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                        <Button
                          variant="contained"
                          sx={{ mr: 3 }}
                          startIcon={<Add />}
                          onClick={handleClickAddEtc}
                        >
                          {"등록하기"}
                        </Button>
                        <FormControlLabel
                          control={
                          <Switch 
                            checked={checkedEtc}
                            onChange={handleChangeOrderEtc}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={"순서"}
                      />
                      </Grid> 
                    )
                  }
                  {/* <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                  {
                    gclientG04GeneralDocuMaps?.map(g04GeneralDocu => {
                      const { id, name, code, documentPath, startDate, endDate } = g04GeneralDocu;
                      return (
                        <Button
                          variant={documentPath ? "contained" : "outlined"}
                          endIcon={documentPath ? (<Tooltip title={"다운로드"}><FilePresent onClick={(e) => handleClickFileDownload(e, documentPath)} /></Tooltip>) : <></>}
                          onClick={() => handleClickGeneralDocu({ id, name, code, documentPath, startDate, endDate })}
                          sx={{ ml: 1 }}
                        >
                          {name}
                        </Button>
                      )
                    })
                  }
                  </Grid> */}
                </>
              )
            }
            <Grid item xs={12}>
              {/* 일반서류 목록 */}
              <TabPanel value={tabValue} index={0}>
                <div style={{ height: GRID_HEIGHT.FULL, width: '100%' }}>
                  <DataGridPro
                    localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                    sx={{
                      cursor: 'pointer',
                      fontSize: '0.85em',
                    }}
                    initialState={{ pinnedColumns: { right: ['actions'] } }}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                      loadingOverlay: LinearProgress,
                    }}
                    columnHeaderHeight={38}
                    rowHeight={34}
                    loading={!loaded}
                    rows={gclientG04GeneralDocuMaps}
                    columns={columnsGeneral}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    pagination
                    // headerHeight={50}
                    // rowHeight={46}
                  />
                </div>
              </TabPanel>
              {/* 인증서 목록 */}
              <TabPanel value={tabValue} index={1}>
                {/* Skeleton이 div 안에 있어야 함. 그렇지 않으면 높이 문제 발생 */}
                {/* <Skeleton variant="rounded" height={show ? 0 : 800} /> */}
                <div style={{ height: GRID_HEIGHT.FULL, width: '100%' }}>
                  {/* <Skeleton variant="rounded" height={show ? 0 : 800} /> */}
                  <DataGridPro
                    localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                    sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                    initialState={{ pinnedColumns: { right: ['actions'] } }}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                      loadingOverlay: LinearProgress,
                    }}
                    columnHeaderHeight={38}
                    rowHeight={34}
                    loading={!loaded}
                    rows={rowsG04docuGCertifications}
                    columns={columnsG04docuGCertification}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    pagination
                    // headerHeight={50}
                    // rowHeight={46}
                    onRowDoubleClick={(params) => handleSelectGCertification({ type: 'edit', params })}
                  />
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {
                  !from?.params?.gcomponentItemId && (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} display="flex" alignItems="center">
                          <Filter1 />
                          <Typography variant="h6" sx={{ mt: 2, mb: 2, ml: 2 }}>{"제품/가공품 목록"}</Typography>
                        </Grid>
                      </Grid>
                      <div style={{ height: 400, width: '100%' }}>
                        <DataGridPro
                          localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                          sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                          initialState={{ pinnedColumns: { right: ['actions'] } }}
                          slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                            loadingOverlay: LinearProgress,
                          }}
                          columnHeaderHeight={38}
                          rowHeight={34}
                          loading={!loaded}
                          rows={rowsGComponentItem}
                          columns={columnsItems}
                          pageSize={pageSize}
                          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                          rowsPerPageOptions={[10, 20, 50, 100]}
                          pagination
                          // headerHeight={50}
                          // rowHeight={46}
                          onRowSelectionModelChange={async (ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRows = rowsGComponentItem.filter((row) =>
                              selectedIDs.has(row.id),
                            );
                            
                            // 현재는 단일 선택 기준임
                            if (selectedRows.length === 1) {
                              setLoadedByGComponentItem(false);

                              const { id, name, gclientId, g04docuGCertificationId, gstandardId, gcomponentItemType } = selectedRows[0];
                              console.log(selectedRows[0])
                              if (gcomponentItemType === 'PROCESS') {
                                setSelectedG04docuGCertificationId(id); // 공정의 경우 id와 g04docuGCertificationId는 같음
                                setSelectedGComponentItemId("");
                                setSelectedGComponentItemName(""); 
                              } else { // 원부자재
                                setSelectedGComponentItemId(id);
                                setSelectedGComponentItemName(name);
                              }
                              
                              setSelectedGComponentItemType(gcomponentItemType);
                              
                              if (!gstandardId) {
                                setAlertInfo({
                                  titleAlert: "안내",
                                  messageAlert: (
                                    <div>
                                      <span>{"원부자재에 "}</span>
                                      <span style={{ color: "#d32f2f" }}><i><u>{"규격이 설정되어 있지 않습니다."}</u></i></span>
                                      <br/>
                                      <span>{"먼저 규격을 설정하세요."}</span>
                                    </div>
                                  ),
                                  open: true,
                                });

                                await initializeGClientG04docuGTestMaps();

                                setTimeout(() => setLoadedByGComponentItem(true), 300);
                                
                                return;
                              }

                              setGStandardId(gstandardId);

                              await selectAllByGComponentItemOrProcessByQuery(id, gcomponentItemType, selectedGClientId); // 공정의 경우 id와 g04docuGCertificionId는 같음
                                
                              setTimeout(() => setLoadedByGComponentItem(true), 300);
                            }
                          }}
                          // onRowDoubleClick={(params) => handleSelect({ type: 'detail', params })}
                        />
                      </div>
                    </Box>
                  )
                }
                {/* {
                  JSON.stringify(rowsTestByGComponentItem)
                } */}
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Grid item xs={12} display="flex" alignItems="center">
                        { from?.params?.g04docuGCertificationId ? <Filter1 /> : <Filter2 /> }
                        <Typography variant="h6" sx={{ mt: 2, mb: 2, ml: 2 }}>{"제품/가공품별 성적서"}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch 
                            checked={checked}
                            onChange={handleChangeOrder}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label={"순서"}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ height: from?.params?.g04docuGCertificationId ? GRID_HEIGHT.FULL : 400, width: '100%' }}>
                    {/* <Skeleton variant="rounded" height={show ? 0 : from?.params?.g04docuGCertificationId ? 800 : 400} /> */}
                    <DataGridPro
                      localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                      sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                      initialState={{ pinnedColumns: { right: ['actions'] } }}
                      slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        loadingOverlay: LinearProgress,
                      }}
                      columnHeaderHeight={38}
                      rowHeight={34}
                      loading={!loadedByGComponentItem}
                      rows={rowsTestByGComponentItem}
                      columns={columnsByGComponentItem}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      rowsPerPageOptions={[10, 20, 50, 100]}
                      pagination
                      // headerHeight={50}
                      // rowHeight={46}
                      onRowDoubleClick={(params) => handleSelectGTest({ type: 'edit', params })}
                    />
                  </div>
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <div style={{ height: GRID_HEIGHT.FULL, width: '100%' }}>
                  <DataGridPro
                    localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
                    sx={{ cursor: 'pointer', fontSize: '0.85em' }}
                    initialState={{ pinnedColumns: { right: ['actions'] } }}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                      loadingOverlay: LinearProgress,
                    }}
                    columnHeaderHeight={38}
                    rowHeight={34}
                    loading={!loadedEtc}
                    rows={rowsEtc}
                    columns={columnsEtc}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    pagination
                    // headerHeight={50}
                    // rowHeight={46}
                    onRowDoubleClick={(params) => handleSelectEtc({ type: 'edit', params })}
                  />
                </div>
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
        <ConfirmDialog
          removeId={removeObject}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={remove}
          onCancel={() => {}}
        >
          {/* {`"${params && params.row && params.row.name || ""}(아이디 : ${params && params.id  || ""})"를 삭제하시겠습니까?`} */}
          <div>
            <span style={{ color: "#1976d2" }}>{params && params.row && params.row.name || ""}</span>{` 성적서를 삭제하시겠습니까?`}
            <br /><br />
            <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
              { `아이디 : ${params && params.id  || ""}` }
            </Box>
          </div>
        </ConfirmDialog>
        <ConfirmDialog
          removeId={removeObjectEtc}
          title={"삭제"}
          open={confirmOpenEtc}
          setOpen={setConfirmOpenEtc}
          onConfirm={removeEtc}
          onCancel={() => {}}
        >
          {/* {`"${paramsEtc && paramsEtc.row && paramsEtc.row.name || ""}(아이디 : ${paramsEtc && paramsEtc.id  || ""})"를 삭제하시겠습니까?`} */}
          <div>
            <span style={{ color: "#1976d2" }}>{paramsEtc && paramsEtc.row && paramsEtc.row.name || ""}</span>{` 을(를) 삭제하시겠습니까?`}
            <br /><br />
            <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
              { `아이디 : ${paramsEtc && paramsEtc.id  || ""}` }
            </Box>
          </div>
        </ConfirmDialog>
        <ConfirmDialog
          removeId={modifyObjectMajority}
          title={"대표성적서 변경"}
          open={confirmOpenMajority}
          setOpen={setConfirmOpenMajority}
          onConfirm={modifyMajority}
          onCancel={() => {}}
        >
          {`대표성적서를 변경하시겠습니까?`}
        </ConfirmDialog>
        <ConfirmDialog
          removeId={removeFileObject}
          title={"삭제"}
          open={confirmOpenRemoveFile}
          setOpen={setConfirmOpenRemoveFile}
          onConfirm={removeFile}
          onCancel={() => {}}
        >
          <div>
            <span style={{ color: "#1976d2" }}>{"첨부된 서류를 삭제하시겠습니까?"}</span>
            <br /><br />
            <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
              { `파일명 : ${removeFileObject?.documentPath?.split("/")[removeFileObject?.documentPath?.split("/")?.length-1]}` }
            </Box>
          </div>
        </ConfirmDialog>
        <DocumentViewerDrawer 
          open={documentViewerDrawerOpen}
          onClose={() => setDocumentViewerDrawerOpen(false)}
          url={currentUrl}
        />
      </Container>
    </ThemeProvider>
  );
};

export default G04docuFileManagement;
