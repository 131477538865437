import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { Slide } from '@mui/material';
import { Box, Grid, Typography } from '@mui/material';

import Intro from "../Intro";
import LoginFormPage from '../components/LoginFormPage';
import { GLogin, GLoginGClient, GLoginGUser } from '../components/GLoginFormPage';
import { SignupGClient, SignupGUser } from '../components/GSignup';
import { Navigation } from "../components/Navigation";
import Header from '../components/layout/Header';
import AppRoutes from './AppRoutes';

import Copyright from "../components/layout/Copyright";
import CopyrightKFGWA from "../components/layout/CopyrightKFGWA";
import { ErrorPage } from "../components/error";
import ConsentDialog from "../ConsentDialog";
import NotificationDialog from "../NotificationDialog";
import G04docuMaterialApprovalProgress from "../components/G04docuMaterialApproval/G04docuMaterialApprovalProgress";
import CmsDialog from "../CmsDialog";
import { getMenusByUserType } from '../utils/menuUtils';
import { useMenuLayout } from '../components/Navigation/Navigation';

const cellStyleTop = {
    borderColor: '#dcdcdc',
    borderWidth: 1, borderStyle: 'solid',
    bgcolor: "#F2F2F2",
  };
  
  const cellStyleTop2 = {
    borderColor: '#dcdcdc',
    borderWidth: 1, borderStyle: 'solid',
    borderLeftWidth: 0,
  };
  
  const cellStyle = {
    borderColor: '#dcdcdc',
    borderWidth: 1, borderStyle: 'solid',
    borderTopWidth: 0,
    bgcolor: '#F7F7F7',
  };
  
  const cellStyle2 = {
    borderColor: '#dcdcdc',
    borderWidth: 1, borderStyle: 'solid',
    borderTopWidth: 0,
    borderLeftWidth: 0,
  };
  

const MainRoute = ({ isLoaded, sessionUser, error, handleClose, open, setOpen, openCmsDialog, setOpenCmsDialog }) => {
    const { pathname } = useLocation();
    const footer = true; // 필요에 따라 조정
    const [menuLayout] = useMenuLayout();
    const  SlideTransition = (props) => <Slide {...props} direction="up" />

    const shouldShowNavigation = pathname !== "/gsendG04docus/download" && 
                               pathname !== "/intro" && 
                               pathname !== "/";

    const shouldShowHeader = shouldShowNavigation && pathname !== "/glass/signin";

    const marginLeft = shouldShowNavigation && 
                      pathname !== "/glass" &&
                      pathname !== "/glass/signin" &&
                      pathname !== "/glass/signin/gclient" &&
                      pathname !== "/glass/signin/guser" &&
                      pathname !== "/glass/signup/gclient" &&
                      pathname !== "/glass/signup/guser" &&
                      menuLayout === 'left' ? '320px' : 0;

    return (
        <>
        {/**
         * 현재 sessionUser.type 은 ADMIN, GCLIENT만 서버로부터 받음.
         * 자바 이전에는 회원사 사용자 로그인할 때 sessionUser.type을 sessionUser.user.type으로 교체했었음(USER)
         * 그렇지만 지금은 sessionUser.type을 ADMIN과 그 외 것으로만 구분하므로 (USER는 사용하지 않음) 현상태 유지
         * TODO : role 관련해아 전체 구조 개선 필요 */}
        <Box sx={{ height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          {shouldShowNavigation && <Navigation isLoaded={isLoaded} menus={getMenusByUserType(sessionUser)} />}
          <Box sx={{ 
              marginLeft: { xs: 0, md: marginLeft },
              transition: 'margin 0.3s',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 0, // 추가: 스크롤 문제 해결을 위해
          }}>
            {shouldShowHeader && <Header menus={getMenusByUserType(sessionUser)} />}
            <Box sx={{ 
                flexGrow: 1,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0, // 추가: 스크롤 문제 해결을 위해
            }}>
                {isLoaded && <AppRoutes sessionUser={sessionUser} isLoaded={isLoaded} />}
                {footer && (pathname !== "/" && pathname !== "/intro" ? <CopyrightKFGWA /> : <Copyright />)}
            </Box>
          </Box>
        </Box>
        {
          error?.serverResponse?.details?.severityLevel >= 3 ? (
            <Snackbar
              open={error || false}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              TransitionComponent={SlideTransition}
              autoHideDuration={5000}
              onClose={handleClose}
              // message={error?.serverResponse?.details.message}
              // action={<IconButton
              //   size="small"
              //   aria-label="close"
              //   color="inherit"
              //   onClick={handleClose}
              // >
              //   <Close fontSize="small" />
              // </IconButton>}
          >
              <Alert
              onClose={handleClose}
              severity="warning" // success, info, warning, error
              variant="filled"
              sx={{ width: '100%' }}
              >
              {error?.serverResponse?.details.message}
              </Alert>
            </Snackbar>
          ) : (<></>)
        }
        <NotificationDialog>
          <Box sx={{ p: 1 }}>
            {"KFGWA 공사다큐 서비스를 사용하시는 고객님께 최초 1회 문서 업로드 서비스를 제공해 드립니다."}<br /><br />
            {"가지고 계신 서류를 아래 메일로 보내 주십시요."}<br /><br />
            {"보내실 메일 주소 "}<span style={{ color: "#1976d2", marginLeft: '10px' }}>{"wan@warpcore.co.kr"}</span>
            <Grid container sx={{ mt: 4 }}>
                <Grid item xs={12} sx={cellStyleTop}>
                <Typography sx={{ p: 1, fontWeight: 'bold' }}>{"서류목록"}</Typography>
                </Grid>
                <Grid item xs={12} sm={3} display="flex" sx={cellStyle}>
                <Typography sx={{ p: 1 }}>{"일반서류"}</Typography>
                </Grid>
                <Grid item xs={12} sm={9} display="flex" sx={cellStyle2}>
                <Grid container sx={{ p: 1 }}>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 사업자등록증"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 국세 납세증명서"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 지방세 납세증명서"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 회사소개자료"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 납품실적"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 공장등록증"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 기타"}</Typography>
                    </Grid>
                </Grid>
                </Grid>
                <Grid item xs={12} sm={3} display="flex" sx={cellStyle}>
                <Typography sx={{ p: 1 }}>{"그 외 서류"}</Typography>
                </Grid>
                <Grid item xs={12} sm={9} display="flex" sx={cellStyle2}>
                <Grid container sx={{ p: 1 }}>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 인증서"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 성적서"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography>{"- 기타"}</Typography>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
          </Box>
        </NotificationDialog>
        <ConsentDialog open={open} setOpen={setOpen} />
        <G04docuMaterialApprovalProgress />
        <CmsDialog open={openCmsDialog} setOpen={setOpenCmsDialog} />
    </>
  );

};

export default MainRoute;