// TODO : 아래 컬러 => HEX에서 name 정해서 사용할 것. 예) 유효기간 지난 것: NO_VALID, 파일 없음: NO_FILE 등
import React from 'react';
import { useSelector } from "react-redux";
import { alpha, styled } from '@mui/material/styles';
// TODO : 최신버전은 TreeView가 @mui/lab에서 @mui/x-tree-view로 이동했으나 기존 개발버전이 작동하지 않아 올드버전 사용
// -import { TreeView, TreeItem } from '@mui/lab';
// +import { TreeView, TreeItem } from '@mui/x-tree-view';
import MuiTreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import SvgIcon from '@mui/material/SvgIcon';
// import * as MuiTreeItem from '@mui/x-tree-view/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from '@react-spring/web';
// import Checkbox from '@mui/joy/Checkbox';
import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import  {
  AddBox,
  Article,
  CorporateFare,
  ChromeReaderMode,
  Comment,
  FileCopy,
  FilePresent,
  FormatListBulleted,
  Grain,
  MoveDown,
  Preview,
  Circle,
  Clear,
  Warning,
  Block,
  Close,
  DisabledByDefault,
  Download,
  Delete,
  DoNotTouch,
  WebAsset,
} from '@mui/icons-material';
import {
  grey,
  red,
  deepOrange,
} from '@mui/material/colors';
import {
  dateFormat,
  // SecurityUtils,
} from "../../utils";
import * as g04docuFileActions from "../../store/g04docuFile";
import * as g04docuGTestSubstituteActions from "../../store/g04docuGTestSubstitute";

const ITEM_HEIGHT = 48;

const typographyVariant = "subtitle1";
// const height = '38px';
let height;

function EndSquare(props) {
  return (
    <SvgIcon
      fontSize="inherit" 
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* 문서 외곽선 - 접힌 모서리 반영 */}
      <path 
        d="M4 2h12 L22 8 L22 22 L4 22 L4 2" 
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      {/* 문서 내부 선 */}
      <path 
        d="M6 10h14M6 14h14M6 18h14" 
        stroke="currentColor" 
        strokeWidth="1.2" 
        fill="none"
      />
      {/* 우측 상단 접힘 표시 삼각형 */}
      <path
        d="M16 2l6 6h-6z"
        fill="#7e7e7e"
      />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props) => (
  <MuiTreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.label}`]: {
    height,
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: /*0.3*/0, // 자식없는 노드 안보이도록 함
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    // paddingLeft: 0, // indentation 조정
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, /*0.4*/0)}`, // 확대점선 삭제
  },
  // 모든 계층의 트리 아이템에 분리선 추가
  [`& .${treeItemClasses.content}`]: {
    '&::after': {
      content: '""',
      position: 'absolute',
      // left: '40px',  // 체크박스 이후로 시작
      left: 0,
      bottom: 0,
      right: 0,  // 오른쪽 끝까지
      borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
    },
    position: 'relative',
  },
}));

const NotOpened = () => (
  <Tooltip title={"비공개 거래선의 문서는 열람할 수 없습니다."} placement="left-start">
    <IconButton
      color="primary"
      aria-label="file"
      edge="end"
      // disabled
      sx={{ color: "#BDBDBD"}}
    >
      <DoNotTouch />
    </IconButton>
  </Tooltip>
);

const TreeItem = ({
  crudMode,
  items,
  selected,
  onSelect,
  disableMultiParentSelection,
  handleClickViewEachDoc,
  handleClickViewSummary,
  handleClickAddSubstituteTestDoc,
  reGenerate,
  readOnly,
}) => {
  // const securityOpenedGClientsAboutMe = useSelector((state) => state.security.securityOpenedGClientsAboutMe);

  const [anchorEl, setAnchorEl] = React.useState(null);
  // console.log(handleClickViewEachDoc);
  const tree = React.useMemo(() => flattenTree({ items }), [items]);
  const marksUncheckedRef = React.useRef(
    createMarksUnchecked({ tree, items, selected }),
  );
  
  const activeParentRef = React.useRef('');

  const openFunctionMenu = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    // marksUncheckedRef.current = createMarksUnchecked({ tree, items, selected });
    // console.log('marksUnchecked', marksUncheckedRef.current);
    // console.clear();
    // console.log(JSON.stringify(marksUncheckedRef.current, null, 2));
    // console.log('activeParent', activeParentRef.current);
  }, [items, selected, tree]);

  const handleChange = ({ event, parents = [] }) => {
    // 읽기전용 모드일 때 체크박스 변경 방지
    if (readOnly) {
      return;
    }

    const {
      target: { value, checked },
    } = event;
    let newSelect = selected.slice();
    console.log({ value, checked, parents })
    if (checked) {
      newSelect = [...parents, value].reverse().reduce(
        (prev, curr, index) => {
          const node = curr;
          let newSelectSoFar = prev;

          if (index === 0) {
            const childNodes = getTreeNodes({ tree, node });
            const childNodesLength = childNodes.length;

            if (childNodesLength > 0) {
              newSelectSoFar = [
                ...newSelectSoFar.filter(
                  (select) => !childNodes.includes(select),
                ),
              ];

              marksUncheckedRef.current = marksUncheckedRef.current.filter(
                (marksUnchecked) =>
                  ![...childNodes, node].includes(marksUnchecked),
              );
            }
          } else {
            // const childNodes = getTreeNodes({ tree, node, depth: 1 });
            const childNodes = getTreeNodes({ tree, node });
            const childNodesLength = childNodes.length;

            if (childNodesLength > 0) {
              const isEveryChildrenExist = childNodes.every((childNode) =>
                newSelectSoFar.includes(childNode),
              );

              if (isEveryChildrenExist) {
                newSelectSoFar = [
                  ...newSelectSoFar.filter(
                    (select) => !childNodes.includes(select),
                  ),
                  node,
                ];

                marksUncheckedRef.current = marksUncheckedRef.current.filter(
                  (marksUnchecked) =>
                    ![...childNodes, node].includes(marksUnchecked),
                );
              }
            }
          }

          marksUncheckedRef.current = marksUncheckedRef.current.filter(
            (marksUnchecked) => !newSelectSoFar.includes(marksUnchecked),
          );

          return newSelectSoFar;
        },
        [...newSelect, value],
      );
    } else if (!checked && !newSelect.includes(value)) {
      let toExclude = value;
      newSelect = parents
        .slice()
        .reverse()
        .reduce(
          (prev, curr, index) => {
            const node = curr;
            let newSelectSoFar = prev;
            let childNodes;

            marksUncheckedRef.current = [
              ...new Set([...marksUncheckedRef.current, toExclude]),
            ];

            if (index === 0) {
              childNodes = getTreeNodes({ tree, node, depth: 1 });
              console.log(
                toExclude,
                childNodes,
                childNodes.filter((childNode) => childNode !== toExclude),
              );
              newSelectSoFar = [
                ...newSelectSoFar,
                ...childNodes.filter((childNode) => childNode !== toExclude),
              ];
            } else {
              childNodes = getTreeNodes({ tree, node, depth: 1 }).filter(
                (childNode) => childNode !== toExclude,
              );
              console.log(
                toExclude,
                childNodes,
                childNodes.filter((childNode) => childNode !== toExclude),
              );
              newSelectSoFar = [
                ...newSelectSoFar.filter(
                  (select) => !childNodes.includes(select),
                ),
                ...childNodes.filter(
                  (childNode) => !marksUncheckedRef.current.includes(childNode),
                ),
              ];
            }

            toExclude = curr;

            return newSelectSoFar;
          },
          newSelect.filter((select) => !parents.includes(select)),
        );
    } else {
      [...parents, value]
        .slice()
        .reverse()
        .forEach((item) => {
          const node = item;
          const childNodes = getTreeNodes({ tree, node, depth: 1 });

          if (childNodes.length > 0) {
            marksUncheckedRef.current = [
              ...new Set([...marksUncheckedRef.current, ...childNodes]),
            ];
          } else {
            marksUncheckedRef.current = [
              ...new Set([...marksUncheckedRef.current, node]),
            ];
          }
        });
      newSelect = newSelect.filter((select) => select !== value);
    }

    if (disableMultiParentSelection) {
      if (checked) {
        activeParentRef.current = parents.length > 0 ? parents[0] : value;
      } else {
        const childNodes = getTreeNodes({
          tree,
          node: parents.length > 0 ? parents[0] : value,
        });

        if (!childNodes.some((childNode) => newSelect.includes(childNode))) {
          activeParentRef.current = '';
        }
      }
    }

    console.log(newSelect)
    onSelect(newSelect);
  };

  const renderTreeItem = ({ nodes, parents = [], level = 0 }) => {
    // console.log(handleClickViewEachDoc);
    return nodes.filter(node => {
      // '기타' 노드이고 자식이 없으면 필터링
      if (node.label === '기타' && (!node.children || node.children.length === 0)) {
        return false;
      }
      return true;
    }).map((node) => {
      // TODO : node에 필요정보가 다 있음
      // console.log(node)
      const { id: value, label, type, data, children } = node;
      const checked =
        selected.includes(value) ||
        parents.some((parent) => selected.includes(parent));
      let disabled = activeParentRef.current
        ? !parents.includes(activeParentRef.current)
        : false;

      if (activeParentRef.current === value) {
        disabled = false;
      }

      // disabled = true; // TODO : 임시
      // 문서 타입인지 확인
      const isDocType = type?.substring(0, 3) === 'DOC' || type === 'DOMESTIC_AUTH';
      // console.log(isDocType)
      // 문서 타입일 때만 EndSquare 아이콘 사용
      const treeItemProps = {
        ...(isDocType && {
          expandIcon: <EndSquare />,
          collapseIcon: <EndSquare />,
          endIcon: <EndSquare />  // endIcon도 추가
        })
      };

      if (children && children.length > 0) {
        const indeterminate = isIndeterminate({ tree, selected, node: value });
        const treeItemLabel = createTreeItemLabel({
          formControlLabelProps: { label },
          nodeData: { label, type, data },
          checkboxProps: {
            value,
            checked,
            indeterminate,
            disabled,
            onChange: (event) => {
              // 읽기전용
              if (crudMode === 'R') {
                return;
              }
              
              handleChange({ event, parents });
            },
          },
          handleClickViewEachDoc,
          handleClickViewSummary,
          handleClickAddSubstituteTestDoc,
          reGenerate,
          openFunctionMenu,
          handleClick,
          anchorEl,
          handleClose,
          // securityOpenedGClientsAboutMe,
          level,
        });

        return (
          <StyledTreeItem
            key={value}
            nodeId={value}
            label={treeItemLabel}
            {...treeItemProps}
          >
            {renderTreeItem({
              nodes: children,
              parents: [...parents, value],
              level: level + 1,
            })}
          </StyledTreeItem>
        );
      }

      const treeItemLabel = createTreeItemLabel({
        formControlLabelProps: { label },
        nodeData: { label, type, data },
        checkboxProps: {
          value,
          checked,
          disabled,
          onChange: (event) => {
            // 읽기전용
            if (crudMode === 'R') {
              return;
            }

            handleChange({ event, parents });
          },
        },
        handleClickViewEachDoc,
        handleClickViewSummary,
        handleClickAddSubstituteTestDoc,
        reGenerate,
        openFunctionMenu,
        handleClick,
        anchorEl,
        handleClose,
        readOnly,
        level, // 리프 노드에도 현재 레벨 전달
      });

      return (
        <StyledTreeItem
          key={value}
          nodeId={value}
          label={treeItemLabel}
          {...treeItemProps}
        />
      );
    });
  };

  return renderTreeItem({ nodes: items });
};

export default TreeItem;

function flattenTree({ items, parent = 'root', depth = 0 }) {
  return items.reduce((prev, curr) => {
    Object.assign(prev, { [parent]: [...(prev[parent] || []), curr.id] });

    if (curr.children && curr.children.length > 0) {
      return {
        ...prev,
        ...flattenTree({
          items: curr.children,
          depth: depth + 1,
          parent: curr.id,
        }),
      };
    }

    return prev;
  }, {});
}

function createMarksUnchecked({ tree, items, selected }) {
  return items.reduce((prev, { id: node }) => {
    return [...prev, node, ...getTreeNodes({ tree, node })];
  }, []);
}

// TODO : 다운로드할 때 원본을 그대로 내려줄 경우 거래선의 문서들이 무작위로 배포될 염려가 있으므로 원본대조필 및 프로젝트 관련 문구를 추가하고
// 그것을 내려준다. 단, 문서가 많이 적재되므로 그 처리를 고려할 것
const handleClickFileDownload = async (documentPath) => {
  const arr = documentPath.split("/");
  const fileName = arr[arr.length - 1];

  // 1. Download
  const res = await downloadG04docuFile(documentPath);
  const blob = await res.blob(); // res.blob는 promise를 리턴함???
  
  // 2. Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
}

const downloadG04docuFile = (documentPath) => g04docuFileActions.downloadDirect(documentPath)
const removeSubstitutes = ({ id, gclientId, g04docuGTestSubstituteId, documentPath }) => g04docuGTestSubstituteActions.removeWithFileDirect({ id, gclientId, g04docuGTestSubstituteId, documentPath });

const handleClickRemoveSubstituteDoc = async (data, func) => {
  console.log(data)
  const { gclientId, g04docuGTestSubstituteId, documentPath } = data;
  await removeSubstitutes({ id: g04docuGTestSubstituteId, gclientId, g04docuGTestSubstituteId, documentPath })
  func && func();
}

function createTreeItemLabel({
  formControlLabelProps = {},
  nodeData = {},
  checkboxProps = {},
  handleClickViewEachDoc,
  handleClickViewSummary,
  handleClickAddSubstituteTestDoc,
  reGenerate,
  openFunctionMenu,
  handleClick,
  anchorEl,
  handleClose,
  readOnly,
  level = 0,  // 레벨 정보 추가
}) {
  // console.log(securityOpenedGClientsAboutMe);
  // console.log(formControlLabelProps);
  // console.log(handleClickViewEachDoc);
  const { label, type, data, substitute } = nodeData;
  // console.log(data)
  // 참고로 공정에서 유리 스펙은 data.elements에 들어있음
  
  let clientName = "";
  let itemList = "";
  let nodeTitle; // 국내가공유리제품 사전 인증과 업체명 타이틀


  if (type === 'GSUPPLY_CONFIRM_SUMMARY') {
    nodeTitle = (
      <Grid container>
        <Grid item xs={5} display="flex" alignItems="center">
          <Stack direction="row">
            {label}
          </Stack>
        </Grid>
        <Grid item xs={7}>
        {
          <Tooltip title={"납품확인 요약정보 보기"} placement="right-start">
            <IconButton
              aria-label="view"
              edge="end"
              onClick={handleClickViewSummary}
              sx={{ color: "#1976D2", ml: /*4.8*/'39px' }}
            >
              <WebAsset />
            </IconButton>
          </Tooltip>
        }
        </Grid>
      </Grid>
    );
  } else if (type === 'GSUPPLY_CONFIRM_DOC') {
    nodeTitle = (
      <Grid container>
        <Grid item xs={5} display="flex" alignItems="center">
          <Stack direction="row">
            {label}
          </Stack>
        </Grid>
        <Grid item xs={7}>
          {
            data.documentPath && (
              <Tooltip title={"납품확인서 보기"} placement="right-start">
                <IconButton
                  aria-label="view"
                  edge="end"
                  onClick={() => handleClickViewEachDoc(data.documentPath, data.gclientId)}
                  sx={{ color: "#1976D2", ml: /*4.8*/'39px' }}
                >
                  <Preview />
                </IconButton>
              </Tooltip>
            )
          }
        </Grid>
      </Grid>
    );
  } else if (type === 'DOMESTIC_AUTH') {
    if (label.split(":").length > 1) {
      const titles = label.split(":");
      const no = titles[0];
      const glassName = titles[1];
      const specification = titles[2];
      
      const authYN = data.authYN ? data.authYN : "N/A";
      // const authName = data.authName ? data.authName : "N/A";

      nodeTitle = (
        <Stack direction="row" display="flex" alignItems="center">
          <Tooltip title={"품번"} placement="left-start">
            <Typography variant={typographyVariant} color="primary" sx={{ mr: 2 }}>
              {no}
            </Typography>
          </Tooltip>
          <Typography variant={typographyVariant} sx={{ mr: 2 }}>{specification}</Typography>
          {glassName && (
            <Tooltip title={glassName} placement="right">
              <IconButton
                size="small"
                sx={{ ml: 1 }}
              >
                <Comment fontSize="small" sx={{ color: 'grey.500' }} />
              </IconButton>
            </Tooltip>
          )}
          {/* {
            authYN === 'Y' && data.authDocumentPath && (
              <Tooltip title={"국내가공유리제품 사전 인증서 보기"} placement="right-start">
                <IconButton
                  aria-label="view"
                  edge="end"
                  onClick={() => handleClickViewEachDoc(data.authDocumentPath, data.gclientId)}
                  sx={{ color: "#1976D2", ml: '39px' }}
                >
                  <Preview />
                </IconButton>
              </Tooltip>
            )
          } */}
        </Stack>
      );
    }
  } else if (type === 'CLIENT') {
    if (label.split(":").length > 1) {
      clientName = label.split(":")[0];
      itemList = label.split(":")[1].split(",");
      
      nodeTitle = (
        <>
          <Typography variant={typographyVariant} sx={{ mr: 3  }}>{clientName}</Typography>
          {/* {
            itemList.map(item => <Chip variant="outlined" color="primary" label={item}  size="small" sx={{ mr: 1, borderRadius: 1, border: '1.5px solid #2196f3' }} />)
          } */}
          {
            itemList.length > 0 && (
              <Typography variant={typographyVariant} color="primary" sx={{ fontSize: '0.9rem' }}>
                {itemList.join(' / ')}
              </Typography>
            )
          }
        </>
      );
    } else {
      nodeTitle = <Typography variant={typographyVariant}>{label}</Typography>;
    }
  }

  // console.log(nodeData)
  // if (type && type.substring(0, 3) === 'DOC' && data.gclient && data.gclient.id) {
    
  // }
  
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        borderColor: grey[200],
        width: '100%', // 전체 너비 사용
      }}
    >
      <Stack direction={"row"}>
        {/* 문서 포함 여부 체크박스 */}
        <Checkbox 
          color="error" 
          checkedIcon={<DisabledByDefault size="small" />} 
          {...checkboxProps}
          onClick={(event) => { 
            event.stopPropagation();
            if (!readOnly) {
              checkboxProps.onChange(event);
            }
          }}
        />
        {/* 문서 유효 여부 표시등 */}
        {
          type?.substring(0, 3) === 'DOC' && (
            (data.documentPath && (data.valid || data.validYN)) || 
            (!data.documentPath && data.performanceData?.performanceDocumentPath)
          ) && (
            <Box display="flex" alignItems="center" sx={{ 
              color: data.documentPath 
                ? '#4caf50' 
                : (data.performanceData?.performanceDocumentPath ? "#1a237e" : '#4caf50') 
            }}>
              <Tooltip title="유효" placement="right-start">
                <Circle sx={{ width: 14, height: 14 }} />
              </Tooltip>
            </Box>
          )
        }
        {
          type?.substring(0, 3) === 'DOC' && 
          data.documentPath && 
          !(data.valid || data.validYN) && (
            <Box display="flex" alignItems="center" sx={{ color: '#ED6C02' }}>
              <Tooltip title="유효기간 지남" placement="right-start">
                <Circle sx={{ width: 14, height: 14 }} />
              </Tooltip>
            </Box>
          )
        }
        {
          type?.substring(0, 3) === 'DOC' && 
          !(data.documentPath || data.performanceData?.performanceDocumentPath) && (
            <Box display="flex" alignItems="center" sx={{ color: '#ef5350' }}>
              <Tooltip title="서류없음" placement="right-start">
                <Circle sx={{ width: 14, height: 14 }} />
              </Tooltip>
            </Box>
          )
        }
      </Stack>
      <Grid container sx={{ flex: 1 }}>
        {/* 목차 영역 */}
        <Grid
          item
          xs={3}
          display="flex"
          alignItems="center"
        >
          {
            (type === 'CLIENT' || type === 'DOMESTIC_AUTH' || type === 'GSUPPLY_CONFIRM_SUMMARY' || type === 'GSUPPLY_CONFIRM_DOC') ? (
              <Box display="flex" alignItems="center" sx={{ height }}>
                {nodeTitle}
              </Box>
            ) : (
              <Box display="flex" alignItems="center" sx={{ height }}>
                <Typography variant={typographyVariant}>{label}</Typography>
                {/* {
                  type === 'DOCTEST' && data.selectedClassifications && data.selectedClassifications.filter(c => c.checked === true).length > 0 && (
                    <Tooltip title={"종류·등급·호칭 또는 모델"}>
                      <Box sx={{ ml: 2, pl: 1, pr: 1, border: '1.5px solid #9B9B9B', borderRadius: 1 }}>
                        <Typography variant="caption">{data.selectedClassifications?.filter(c => c.checked === true)[0]?.type}</Typography>
                      </Box>
                    </Tooltip>
                  )
                } */}
                {
                  type === 'DOCTEST' && data.selectedClassifications && data.selectedClassifications.filter(c => c.checked === true).length > 0 && (
                    <Tooltip title={`종류·등급·호칭 또는 모델 : ${data.selectedClassifications?.filter(c => c.checked === true)[0]?.type}`} placement="right">
                      <IconButton
                        size="small"
                        sx={{ ml: 1 }}
                      >
                        <Comment fontSize="small" sx={{ color: '#9B9B9B' }} />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </Box>
            )
          }
        </Grid>
        {/* 미리보기 만료일 최종수정일시 영역 */}
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            // 레벨에 따라 패딩 조정 (리프노드 기준으로 상위 레벨은 더 많은 패딩)
            // 레벨 계산으로는 정확히 맞출 수 없어 직접 지정함
            // paddingLeft: `${((5 - level) * 18)}px`,  // 5레벨이 기준점
            paddingLeft: (() => {
              // 레벨별 패딩값 직접 지정 (단위: px)
              switch(level) {
                case 0: return '0px';
                case 1: return '58px';
                case 2: return '0px';
                case 3: return '24px';
                case 4: return '12px';
                case 5: return '0px';
                default: return '0px';
              }
            })(),
          }}
        >
          {/* {level} */}
          <Stack
            direction="row"
            spacing={5}
            alignItems="center"
            // justifyContent="flex-start"
            sx={{
              position: 'relative',
              left: 0, // 왼쪽 정렬 시작점
            }}
          >
            {/* Preview & action buttons */}
            {
              data?.authYN === 'Y' && data?.authDocumentPath && (
                <Tooltip title={"국내가공유리제품 사전 인증서 보기"} placement="right-start">
                  <IconButton
                    aria-label="view"
                    edge="end"
                    onClick={() => handleClickViewEachDoc(data.authDocumentPath, data.gclientId)}
                    sx={{ color: "#1976D2", ml: '39px' }}
                  >
                    <Preview />
                  </IconButton>
                </Tooltip>
              )
            }
            {
              type?.substring(0, 3) === 'DOC' && data && (data.documentPath || data.performanceData?.performanceDocumentPath) && (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{  height }}
                  >
                    {
                      data.gclient?.opened === false ? (
                          <NotOpened />
                        ) : (
                          data.substitute === 'Y' ? (
                            <>
                              <Tooltip title={(<div>{"대체성적서입니다."}<br />{"상세메뉴를 보시려면 클릭해주세요."}</div>)} placement="left-start">
                                <IconButton
                                  edge="end"
                                  sx={{ color: data?.valid === false ? "#ED6C02" : "#1976D2"}}
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={openFunctionMenu ? 'long-menu' : undefined}
                                  aria-expanded={openFunctionMenu ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={handleClick}
                                >
                                  <MoveDown />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openFunctionMenu}
                                onClose={handleClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                  },
                                }}
                              >
                                <MenuItem
                                  key={"download"}
                                  onClick={
                                    () => {
                                      // console.log(data.documentPath)
                                      // handleClickFileDownload(data.documentPath);
                                      handleClickViewEachDoc(data.documentPath, data.gclient.id)
                                      handleClose();
                                    }
                                  }
                                >
                                  <ListItemIcon><Preview fontSize="small" /></ListItemIcon>
                                  <ListItemText>{"보기"}</ListItemText>
                                </MenuItem>
                              <MenuItem
                                key={"remove"}
                                onClick={
                                  () => {
                                    handleClickRemoveSubstituteDoc(data, reGenerate);
                                    handleClose();
                                  }
                                }
                              >
                                <ListItemIcon><Delete fontSize="small" /></ListItemIcon>
                                <ListItemText>{"삭제"}</ListItemText>
                              </MenuItem>
                            </Menu>
                          </>
                          ) : (
                            <>
                              <Tooltip title={`보기${!data.documentPath && data.performanceData?.performanceDocumentPath ? " (성능확인서) " : ""}`} placement="left-start">
                                <IconButton
                                  aria-label="view"
                                  edge="end"
                                  onClick={() => handleClickViewEachDoc(data.documentPath || data.performanceData?.performanceDocumentPath, data.gclient.id)}
                                  sx={{ color: !data.documentPath && data.performanceData?.performanceDocumentPath ? "#1a237e" : ((data?.validYN || data?.valid) ? "#1976D2" : "#ED6C02") }}
                                >
                                  <Preview />
                                </IconButton>
                              </Tooltip>
                              {
                                type === 'DOCTEST' && data.gtestComparisonDocumentPath && (
                                  <>
                                    <Tooltip title={"시험성과대비표 보기"} placement="right-start">
                                      <IconButton
                                        aria-label="view"
                                        edge="end"
                                        onClick={() => handleClickViewEachDoc(data.gtestComparisonDocumentPath, data.gclient.id)}
                                        sx={{ color: (data?.validYN || data?.valid) ? "#1976D2" : "#ED6C02" }}
                                      >
                                        <Preview />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )
                              }
                            </>
                          )
                      )
                    }
                  </Box>
                </>
              )
            }
            {
              type?.substring(0, 3) === 'DOC' && type !== 'DOCTEST' && data && !(data.documentPath || data.performanceData?.performanceDocumentPath) && ( // 성적서도 없고 성능확인서도 없는 경우
                data.gclient?.opened === false ? (
                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ height }}>
                    <NotOpened />
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="center" alignItems="center" sx={{ height }}>
                    <Tooltip title={"서류없음"} placement="left-start">
                      <IconButton
                        aria-label="download"
                        edge="end"
                        sx={{ color: '#ef5350' }}
                      >
                        <Block />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )
              )
            }
            {
              type === 'DOCTEST' && data && !data.documentPath && data.substitute === 'N' && (
                data.gclient?.opened === false ? (
                  <Box
                    display="flex" justifyContent="center" alignItems="center"
                    sx={{ height }}
                  >
                    <NotOpened />
                  </Box>
                ) : (
                  <Box
                    display="flex" justifyContent="center" alignItems="center"
                    sx={{ height }}
                  >
                    <Tooltip title={"서류없음"} placement="left-start">
                      <IconButton
                        aria-label="download"
                        edge="end"
                        sx={{ color: '#ef5350' }}
                      >
                        <Block />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )
              )
            }
            {
              type === 'DOCTEST' && !(data.substitute === 'N') && data && !data.documentPath && !data.performanceData?.performanceDocumentPath && ( // 성적서도 없고 성능확인서도 없는 경우
                data.gclient?.opened === false ? (
                  <Box
                    display="flex" justifyContent="center" alignItems="center"
                    sx={{ height }}
                  >
                    <NotOpened />
                  </Box>
                ) : (
                  <Box
                    display="flex" justifyContent="center" alignItems="center"
                    sx={{ height }}
                  >
                    <Tooltip title={"대체성적서 추가"} placement="left-start">
                      <IconButton
                        aria-label="download"
                        edge="end"
                        onClick={(e) => handleClickAddSubstituteTestDoc(e, data)}
                        sx={{ color: '#ef5350' }}
                      >
                        <AddBox />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )
              )
            }
            {/* Expiration date */}
            {
              data?.gclient?.opened === true && (
                type?.substring(0, 3) === 'DOC' && data && data.validYN ? (
                  <Box
                    display="flex" justifyContent="center" alignItems="center"
                    sx={{
                      height,
                      // 시험성과대비표가 없을 때 패딩 추가
                      pl: data.gtestComparisonDocumentPath ? 0 : '28px',
                    }}
                  >
                    <Tooltip title={"만료일 없음"}>
                      <Chip
                        label={"만료일 없음"}
                        color={"primary"}
                        variant={"outlined"}
                        sx={{ minWidth: '140px' }}  // 최소 너비 설정
                      />
                    </Tooltip>
                  </Box>
                ) : (
                  data && data.endDate ? (
                    <Box
                      display="flex" justifyContent="center" alignItems="center" 
                      sx={{
                        height,
                        // 시험성과대비표가 없을 때 패딩 추가
                        pl: data.gtestComparisonDocumentPath ? 0 : '28px',
                      }}
                    >
                      <Tooltip title={"만료일"}>
                        <Chip
                          label={` ~ ${dateFormat(data.endDate, 'yyyy-MM-dd')}`}
                          color={data?.valid === false ? "warning" : "primary"}
                          variant={data?.valid === false ? "" : "outlined"}
                          sx={{ minWidth: '140px' }}  // 최소 너비 설정
                        />
                      </Tooltip>
                    </Box>
                  ) : <></>
                )
              )
            }
            {/* Last modified date */}
            {
              data?.gclient?.opened === true && (
                type?.substring(0, 3) === 'DOC' && data && data.mapUpdatedAt && (
                  data.mapUpdatedAt ? (
                    <Box
                      display="flex" justifyContent="center" alignItems="center"
                      sx={{ height }}
                    >
                      <Tooltip title={"최종수정일시"}>
                        <Chip label={dateFormat(data.mapUpdatedAt)}></Chip>
                      </Tooltip>
                    </Box>
                  ) : <></>
                )
              )
            }
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

function getTreeNodes({ tree, node = 'root', depth, currentDepth = 1 }) {
  const branches = tree[node];

  if (!branches) {
    return [];
  }

  return branches.reduce((prev, curr) => {
    let newPrev = [...prev, curr];

    if (tree[curr] && (typeof depth === 'undefined' || depth > currentDepth)) {
      newPrev = [
        ...newPrev,
        ...getTreeNodes({
          tree,
          node: curr,
          depth,
          currentDepth: currentDepth + 1,
        }),
      ];
    }

    return newPrev;
  }, []);
}

function isIndeterminate({ tree, node: value, selected }) {
  return getTreeNodes({ tree, node: value }).some((node) =>
    selected.includes(node),
  );
}
