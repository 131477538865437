import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Add,
  Delete,
  Edit,
  FactCheck,
  OpenInNew,
  Preview,
  Search,
} from '@mui/icons-material';
import {
  FormInputDate,
  FormInputText,
} from "../form";
import {
  AlertDialog,
  ConfirmDialog,
} from "../dialog";
import {
  CustomNoRowsOverlay,
  // CustomLoadingOverlay,
} from "../datagrid";
import { StyledTooltip } from "../tooltip";
import {
  dateFormat,
  hideWatermark,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import * as gsupplyConfirmActions from "../../store/gsupplyConfirm";
import GSupplyConfirmResultDialog from "./GSupplyConfirmResultDialog";
import GSupplyConfirmPublishDialog from "./GSupplyConfirmPublishDialog";
import { getGridHeight } from "../../constants/gridHeights";
import DocumentViewerDrawer from "../common/viewer/DocumentViewerDrawer";
const GRID_HEIGHT = getGridHeight('ONE_LINE_LAYER', 29); // 0px 추가 여백

const theme = createTheme();

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const defaultValues = {
  constructionCompanyName: "",
  site: "",
  startDate: "",
  endDate: "",
  completionDate: "",
  requestAt: "",
  confirmAt: "",
};

const GSupplyConfirmManagement = () => {
  const [columns, setColumns] = useState([]);
  const [modify, setModify] = useState(false);
  const [crudMode, setCrudMode] = useState('');
  const [open, setOpen] = useState(false);
  const [openG04, setOpenG04] = useState(false);
  const [openG04Old, setOpenG04Old] = useState(false);
  const [openG04Generator, setOpenG04Generator] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeId, setRemoveId] = useState();
  const [params, setParams] = useState({});
  // const [checked, setChecked] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [selectedRowG04, setSelectedRowG04] = useState(undefined);
  const [selectedRowG04Gen, setSelectedRowG04Gen] = useState(undefined);
  // const [selectedGcomponentItems, setSelectedGcomponentItems] = useState([]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [gprojectId, setGprojectId] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [requestAt, setRequestAt] = useState(null);
  const [confirmAt, setConfirmAt] = useState(null);
  const [selectedRowG04MaterialDeli, setSelectedRowG04MaterialDeli] = useState([]);
  const [openMaterailDeliReq, setOpenMaterailDeliReq] = useState(false);
  const [openGSupplyConfirmResultDialog, setOpenGSupplyConfirmResultDialog] = useState(false);
  const [openGSupplyConfirmPublishDialog, setOpenGSupplyConfirmPublishDialog] = useState(false);
  const [statusRequestListFilters, setStatusRequestListFilters] = useState(['작성중', '요청', '검토중', '발행', '반려', '완료']);
  const [statusPublishFilters, setStatusPublishFilters] = useState(['요청', '검토중', '반려']);
  const [statusPublishListFilters, setStatusPublishListFilters] = useState(['발행', '완료']);
  const [issueType, setIssueType] = useState('requestSupply'); // 'requestSupply' 또는 'selfSupply'
  const [isDataModified, setIsDataModified] = useState(false);
  const [alertInfo, setAlertInfo] = useState({});
  const [documentViewerDrawerOpen, setDocumentViewerDrawerOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  
  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });

  const sessionUser = useSelector(state => state.session.sessionUser);
  const rows = useSelector((state) => state.gsupplyConfirm.gsupplyConfirms);

  const dispatch = useDispatch();
  const location = useLocation();
  
  const selectAllGSupplyConfirms = ({ searchType, constructionCompanyName, site, startDate, endDate, completionDate, requestAt, confirmAt, status }) => {
    return new Promise((resolve, reject) => {
      dispatch(gsupplyConfirmActions.selectAll({ searchType, constructionCompanyName, site, startDate, endDate, completionDate, requestAt, confirmAt, status }))
        .then(resolve)
        .catch(reject);  // 오류를 명시적으로 reject
    });
  }

  const selectDirect = (id) => gsupplyConfirmActions.selectDirect(id);
  
  const updateStatus = ({ id, status }) => {
    return new Promise((resolve, reject) => {
      dispatch(gsupplyConfirmActions.updateStatus({ id, status }))
        .then(resolve)
        .catch(reject);  // 오류를 명시적으로 reject
    });
  };

  const remove = (id) => {
    return new Promise((resolve, reject) => {
      dispatch(gsupplyConfirmActions.remove(id))
        .then(resolve)
        .catch(reject);  // 오류를 명시적으로 reject
    });
  }

  const handleSelect = async ({ type, params }) => {
    const { pathname } = location;
    const { id, status } = params.row;

    setOpenBackdrop(true);

    try {
      if (pathname === '/gsupplyConfirmPublish' && type === 'publish') {
        if (status === '요청') {
          await updateStatus({ id, status: '검토중' }); // 발행 선택하면 상태를 '검토중'으로 변경
          setIsDataModified(true); // 다이얼로그 닫을 때 목록 재검색하기 위해 필요
        }
        
        // 데이터 조회하고 발행 다이얼로그 열기
        const selectedRow = await selectDirect(id);
        setSelectedRow(selectedRow);
        setOpenGSupplyConfirmPublishDialog(true);
      } else if (pathname === '/gsupplyConfirmRequestList' && (type === "edit" || type === "detail" || type === "review")) {
        // TODO : 상세일때는 다이얼로그에 편집 불가 속성 부여
        // if (type === "detail") {
        // }
        const selectedRow = await selectDirect(id);
        setSelectedRow(selectedRow);
        setOpenGSupplyConfirmResultDialog(true);
        
      } else if (pathname === '/gsupplyConfirmPublishList' && type === "detail") {
        const selectedRow = await selectDirect(id);
        setSelectedRow(selectedRow);
        setOpenGSupplyConfirmPublishDialog(true);
      } else if (type === "delete") {
        setRemoveId(id);
        setParams(params);
        setConfirmOpen(true);
      }
    } catch (error) {
      console.error('Status update failed:', error);
    } finally {
      setOpenBackdrop(false);
    }
  }

  const removeGSupplyConfirm = async (removeId) => {
    setLoaded(false);
    
    try {
      await remove(removeId);
  
      setAlertInfo({
        titleAlert: "안내", 
        messageAlert: "납품확인서 정보가 삭제되었습니다.",
        open: true
      });

      await refreshGSupplyConfirms();
    } catch (error) {
      console.error('납품확인서 삭제 실패:', error);
      setAlertInfo({
        titleAlert: "오류",
        messageAlert: "납품확인서 삭제 중 오류가 발생했습니다.",
        open: true
      });
    } finally {
      setLoaded(true);
    }
  }

  const handleChangePeriod = (newValue, name) => {
    console.log({ name, newValue, today: new Date() });
    
    if (!newValue) {
      return true;
    }

    if (name === "startDate") {
      const endDate = getValues("endDate");
      if (endDate && newValue > endDate) {
        setValue("endDate", newValue);
        setEndDate(newValue);
      }
      setStartDate(newValue);
    } else if (name === "endDate") {
      const startDate = getValues("startDate");
      if (startDate && newValue < startDate) {
        setValue("startDate", newValue);
        setStartDate(newValue);
      }
      setEndDate(newValue);
    } else if (name === "requestAt") {
      setRequestAt(newValue);
    } else if (name === "confirmAt") {
      setConfirmAt(newValue);
    }
  
    setValue(name, newValue);
    return true;
  }

  const handleClickSearch = () => {
    refreshGSupplyConfirms();
  }

  // const generateActions = (params) => {
  //   const { pathname } = location;
    
  //   if (pathname === '/gsupplyConfirmPublish') {
  //     return [
  //       <GridActionsCellItem
  //         icon={<Edit />}
  //         label={"발행"}
  //         onClick={() => handleSelect({ type: 'publish', params })}
  //         showInMenu
  //       />
  //     ];
  //   }
  
  //   const { status } = params.row;
  //   const isDeleteEnabled = status === '작성중' || status === '요청';
  //   const actions = [];
  
  //   // 발행 상태일 때는 완료 메뉴만 표시
  //   if (pathname === '/gsupplyConfirmRequestList' && status === '발행') {
  //     actions.push(
  //       <GridActionsCellItem
  //         icon={<Edit />}
  //         label={"완료"}
  //         onClick={() => handleSelect({ type: 'complete', params })}
  //         showInMenu
  //       />
  //     );
  //   } else {
  //     // 발행 상태가 아닐 때는 수정/삭제 메뉴 표시
  //     actions.push(
  //       <GridActionsCellItem
  //         icon={<Edit />}
  //         label={"수정"}
  //         onClick={() => handleSelect({ type: 'edit', params })}
  //         showInMenu
  //       />,
  //       <GridActionsCellItem
  //         icon={<Delete />}
  //         label={"삭제"}
  //         onClick={() => handleSelect({ type: 'delete', params })}
  //         showInMenu
  //         disabled={!isDeleteEnabled}
  //       />
  //     );
  //   }
  
  //   return actions;
  // }
  const generateActions = (params) => {
    const { pathname } = location;
    const { status } = params.row;
    const actions = [];
  
    if (pathname === '/gsupplyConfirmRequestList') {
      switch (status) {
        case '작성중':
        case '요청':
          actions.push(
            <GridActionsCellItem
              icon={<Edit />}
              label="수정"
              onClick={() => handleSelect({ type: 'edit', params })}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<Delete />}
              label="삭제"
              onClick={() => handleSelect({ type: 'delete', params })}
              showInMenu
            />
          );
          break;
        case '검토중':
          actions.push(
            <GridActionsCellItem
              icon={<OpenInNew />}
              label="상세"
              onClick={() => handleSelect({ type: 'detail', params })}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<Edit />}
              label="수정"
              showInMenu
              disabled
            />,
            <GridActionsCellItem
              icon={<Delete />}
              label="삭제"
              showInMenu
              disabled
            />
          );
          break;
        case '발행':
          actions.push(
            <GridActionsCellItem
              icon={<FactCheck />}
              label="발행검토"
              onClick={() => handleSelect({ type: 'review', params })}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<Delete />}
              label="삭제"
              // onClick={() => handleSelect({ type: 'delete', params })}
              showInMenu
              disabled
            />
          );
          break;
        case '완료':
          actions.push(
            <GridActionsCellItem
              icon={<OpenInNew />}
              label="상세"
              onClick={() => handleSelect({ type: 'detail', params })}
              showInMenu
            />,
            <GridActionsCellItem
              icon={<Edit />}
              label="수정"
              showInMenu
              disabled
            />,
            <GridActionsCellItem
              icon={<Delete />}
              label="삭제"
              showInMenu
              disabled
            />
          );
          break;
        case '반려':
          actions.push(
            <GridActionsCellItem
              icon={<OpenInNew />}
              label="상세"
              onClick={() => handleSelect({ type: 'detail', params })}
              showInMenu
            />,
            // <GridActionsCellItem
            //   icon={<Add />}
            //   label="새요청"
            //   onClick={() => handleSelect({ type: 'new', params })}
            //   showInMenu
            // />,
            <GridActionsCellItem
              icon={<Delete />}
              label="삭제"
              onClick={() => handleSelect({ type: 'delete', params })}
              showInMenu
              // disabled
            />
          );
          break;
      }
    } else if (pathname === '/gsupplyConfirmPublish') {
      switch (status) {
        case '요청':
        case '검토중':
          actions.push(
            <GridActionsCellItem
              icon={<Edit />}
              label="발행"
              onClick={() => handleSelect({ type: 'publish', params })}
              showInMenu
            />
          );
          break;
        case '발행':
        case '완료':
          actions.push(
            <GridActionsCellItem
              icon={<OpenInNew />}
              label="상세"
              onClick={() => handleSelect({ type: 'detail', params })}
              showInMenu
            />
          );
          break;
      }
    } else if (pathname === '/gsupplyConfirmPublishList') {
      if (status === '발행' || status === '완료') {
        actions.push(
          <GridActionsCellItem
            icon={<OpenInNew />}
            label="상세"
            onClick={() => handleSelect({ type: 'detail', params })}
            showInMenu
          />
        );
      }
    }
  
    return actions;
  };

  const COLUMN_CONFIGS = {
    // 요청목록 : 요청업체, 발행업체, 요청일시, 발행일시
    requestList: [
      {
        field: 'owner',
        headerName: '요청업체',
        width: 200,
        valueGetter: (params) => params.row?.gproject?.ownerName,
      },
      {
        field: 'supplyGClientName',
        headerName: '발행업체',
        width: 250,
      },
      {
        field: 'requestAt',
        headerName: '요청일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => params.value ? dateFormat(params.value) : '',
      },
      {
        field: 'confirmAt',
        headerName: '발행일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => params.value ? dateFormat(params.value) : '',
      },
    ],
    // 발행하기 : 발행업체, 요청업체, 요청일시
    publish: [
      {
        field: 'supplyGClientName',
        headerName: '발행업체',
        width: 250,
      },
      {
        field: 'owner',
        headerName: '요청업체',
        width: 200,
        valueGetter: (params) => params.row?.gproject?.ownerName,
      },
      {
        field: 'requestAt',
        headerName: '요청일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => params.value ? dateFormat(params.value) : '',
      },
    ],
    // 발행목록 : 발행업체, 요청업체, 요청일시, 발행일시
    publishList: [
      {
        field: 'supplyGClientName',
        headerName: '발행업체',
        width: 250,
      },
      {
        field: 'owner',
        headerName: '요청업체',
        width: 200,
        valueGetter: (params) => params.row?.gproject?.ownerName,
      },
      {
        field: 'requestAt',
        headerName: '요청일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => params.value ? dateFormat(params.value) : '',
      },
      {
        field: 'confirmAt',
        headerName: '발행일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => params.value ? dateFormat(params.value) : '',
      },
    ],
    // 기본 컬럼 (모든 화면에서 공통으로 사용)
    basic: [
      {
        field: 'documentPath',
        headerName: '납품확인서',
        width: 200,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          // console.log(params)
          const { documentPath } = params.row;
          if (params.value) {
            return (
              <StyledTooltip
                title={<Stack direction="row" display="flex" alignItems="center">
                  <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                    {`미리보기`}
                  </Box>
                  <Typography variant="subtitle2">{documentPath?.split("/")[documentPath?.split("/")?.length-1]}</Typography>
                </Stack>}
                placement="right"
                sx={tooltipTop}
              >
                <IconButton
                  color="primary"
                  aria-label="file"
                  onClick={() => handleClickViewEachDoc(documentPath)}
                  sx={{ color: "#1976D2" }}
                >
                  <Preview />
                </IconButton>
              </StyledTooltip>
            );
          }
        }
      },
      {
        field: 'status',
        headerName: '상태',
        headerAlign: 'center',
        align: 'center',
        width: 100,
      },
      // {
      //   field: 'documentPath',
      //   headerName: '납품확인서',
      //   headerAlign: 'center',
      //   align: 'center',
      //   width: 100,
      // },
      {
        field: 'site',
        headerName: '현장명',
        width: 250,
        valueGetter: (params) => {
          return params.row?.gproject?.site;
        },
      },
      {
        field: 'siteAddress',
        headerName: '현장주소',
        width: 400,
        valueGetter: (params) => {
          return params.row?.gproject?.siteAddress;
        },
      },
      {
        field: 'constructionCompanyName',
        headerName: '건설사명',
        width: 160,
        valueGetter: (params) => {
          return params.row?.gproject?.constructionCompanyName;
        },
      },
      {
        field: 'startDate',
        headerName: '시작',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => params.row?.gproject?.startDate && dateFormat(params.row.gproject.startDate, 'yyyy-MM-dd'),
      },
      {
        field: 'endDate',
        headerName: '마감',
        width: 120,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => params.row?.gproject?.endDate && dateFormat(params.row.gproject.endDate, 'yyyy-MM-dd'),
      },
      {
        field: 'completionDate',
        headerName: '준공일',
        width: 110,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => params.row?.gproject?.completionDate && dateFormat(params.row.gproject.completionDate, 'yyyy-MM-dd'),
      },
      {
        field: 'createdAt',
        headerName: '생성일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'updatedAt',
        headerName: '수정일시',
        width: 160,
        headerAlign: 'center',
        align: 'center',
        valueGetter: (params) => dateFormat(params.value),
      },
      {
        field: 'actions',
        headerName: <Tooltip title={"상세/수정/발행검토/삭제"} followCursor><Box>{"기능"}</Box></Tooltip>,
        // width: checked ? 200 : 70,
        width: 70,
        // description: "수정/삭제", // 불편해서 주석처리
        type: 'actions',
        getActions: (params) => generateActions(params),
      },
    ],
  };
  
  const getColumnsByRoute = () => {
    const { pathname } = location;
    console.log(pathname);
    if (pathname === '/gsupplyConfirmRequestList') {
      return sessionUser.type === 'ADMIN' 
        ? [...COLUMN_CONFIGS.requestList, ...COLUMN_CONFIGS.basic]
        : [...COLUMN_CONFIGS.requestList.slice(1), ...COLUMN_CONFIGS.basic];
    }
    
    if (pathname === '/gsupplyConfirmPublish') {
      return sessionUser.type === 'ADMIN'
        ? [...COLUMN_CONFIGS.publish, ...COLUMN_CONFIGS.basic]
        : [...COLUMN_CONFIGS.publish.slice(1), ...COLUMN_CONFIGS.basic];
    }

    if (pathname === '/gsupplyConfirmPublishList') {
      return sessionUser.type === 'ADMIN' 
        ? [...COLUMN_CONFIGS.publishList, ...COLUMN_CONFIGS.basic]
        : [...COLUMN_CONFIGS.publishList.slice(1), ...COLUMN_CONFIGS.basic];
    }
    
    return COLUMN_CONFIGS.basic;
  };

  useEffect(() => {
    setColumns(getColumnsByRoute());
  }, [location.pathname, sessionUser.type]);

  const refreshGSupplyConfirms = async () => {
    const { pathname } = location;

    try {
      setLoaded(false);

      const constructionCompanyName = getValues("constructionCompanyName");
      const site = getValues("site");
      let startDate = getValues("startDate");
      let endDate = getValues("endDate");
      let completionDate = getValues("completionDate");
      startDate = startDate ? dateFormat(startDate.getDateWithStartHours()) : null;
      endDate = endDate ? dateFormat(endDate.getDateWithEndHours()) : null;
      completionDate = completionDate ? dateFormat(completionDate.getDateWithEndHours()) : null;

      let requestAt = getValues("requestAt");
      let confirmAt = getValues("confirmAt");
      requestAt = requestAt ? dateFormat(requestAt) : null; // yyyy-MM-dd hh:mm:ss 형태여야 함. API 서버에서는 시간부분 떼고 조회함. yyyy-MM-dd 형태로 조회하면 오류 발생
      confirmAt = confirmAt ? dateFormat(confirmAt) : null;
      
      let status; // 작성중, 요청, 검토중, 발행, 반려, 완료
      if (pathname === '/gsupplyConfirmRequestList') {
        // 토글이 모두 해제되었을 때는 기본 status 값 사용
        status = statusRequestListFilters.length === 0 
          ? ['작성중', '요청', '검토중', '발행', '반려', '완료']  // 기본값
          : ['작성중', '요청', '검토중', '발행', '반려', '완료'].filter(s => statusRequestListFilters.includes(s));
        
        await selectAllGSupplyConfirms({ searchType: 'request', constructionCompanyName, site, startDate, endDate, completionDate, requestAt, confirmAt, status });
      } else if (pathname === '/gsupplyConfirmPublish') {
        status = statusPublishFilters.length === 0
          ? ['요청', '검토중', '반려']  // 기본값
          : ['요청', '검토중', '발행', '반려'].filter(s => statusPublishFilters.includes(s));
        
        await selectAllGSupplyConfirms({ searchType: 'checking', constructionCompanyName, site, startDate, endDate, completionDate, requestAt, confirmAt, status });
      } else if (pathname === '/gsupplyConfirmPublishList') {
        status = statusPublishListFilters.length === 0
          ? ['발행', '완료']  // 기본값
          : ['발행', '완료'].filter(s => statusPublishListFilters.includes(s));
        
        await selectAllGSupplyConfirms({ searchType: 'supply', constructionCompanyName, site, startDate, endDate, completionDate, requestAt, confirmAt, status });
      }
      
    } finally {
      setLoaded(true);
    }
  }

  useEffect(() => {
    refreshGSupplyConfirms();
  }, [
    location.pathname, // location.pathname 의존성 추가: 그래야 라우팅 변경될 때 다시 조회함
    statusRequestListFilters,
    statusPublishFilters,
    statusPublishListFilters,
  ]);
  
  var timer;
  const handleChangeSearch = async (e) => {
    // // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    
    // 타이머 설정
    timer = setTimeout(async () => {
      refreshGSupplyConfirms();
    }, 500);
  };

  // 토글 버튼 핸들러 추가
  const handleStatusChange = (event, newStatuses) => {
    const { pathname } = location;
    if (pathname === '/gsupplyConfirmRequestList') {
      setStatusRequestListFilters(newStatuses);
    } else if (pathname === '/gsupplyConfirmPublish') {
      setStatusPublishFilters(newStatuses);
    } else if (pathname === '/gsupplyConfirmPublishList') {
      setStatusPublishListFilters(newStatuses);
    }
  };
  
  const handleIssueTypeChange = (event) => {
    setIssueType(event.target.value);
  };

  // // 그리드 높이 계산 함수 추가
  // const calculateGridHeight = () => {
  //   const rowCount = rows.length;
  //   const headerHeight = 38;
  //   const rowHeight = 34;
  //   const paginationHeight = 60;
    
  //   // 실제 데이터 기반 높이 계산 (최대 20행까지)
  //   const dataHeight = headerHeight + (Math.min(rowCount, 20) * rowHeight) + paginationHeight;
    
  //   // 최소 높이 400px 설정
  //   return Math.max(dataHeight, 400);
  // };

  // const handleClickViewEachDoc = (documentPath) => {
  //   const path = documentPath.replace(uploadFilePath, fileServerUrl);

  //   // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //   const randNumber = Math.floor(Math.random()*99);

  //   window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  // }
  const handleClickViewEachDoc = (documentPath) => {
    const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);
    
    // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
    const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');

    const path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;

    // window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
    setCurrentUrl(path);
    if (!documentViewerDrawerOpen) {
      setDocumentViewerDrawerOpen(true);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container component="main" maxWidth="false">
        <CssBaseline />
        <GSupplyConfirmResultDialog
          open={openGSupplyConfirmResultDialog}
          setOpen={(open) => {
            setOpenGSupplyConfirmResultDialog(open);
            if (!open && isDataModified) {  // 다이얼로그 안에서 납품확인서 데이터가 수정되고 다이얼로그가 닫힐 때
              refreshGSupplyConfirms();  // 목록 새로고침
              setIsDataModified(false);
            }
          }}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setIsDataModified={setIsDataModified}
          refreshList={refreshGSupplyConfirms}
        />
        <GSupplyConfirmPublishDialog
          open={openGSupplyConfirmPublishDialog}
          setOpen={(open) => {
            setOpenGSupplyConfirmPublishDialog(open);
            if (!open && isDataModified) {  // 다이얼로그 안에서 납품확인서 데이터가 수정되고 다이얼로그가 닫힐 때
              refreshGSupplyConfirms();  // 목록 새로고침
              setIsDataModified(false);
            }
          }}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          setIsDataModified={setIsDataModified}
          refreshList={refreshGSupplyConfirms}
        />
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" spacing={1}>
                {
                  sessionUser.type === 'ADMIN' && (
                    <FormInputText
                      name={"constructionCompanyName"}
                      control={control}
                      label={"건설사명으로 검색하세요."}
                      onCustomChange={handleChangeSearch}
                      sx={{
                        width: 408,
                        '& .MuiInputBase-root': {
                          background: "#E3EEFA"
                        },
                        '& .MuiFormLabel-root' : {
                          color: '#2196f3',
                          fontSize: '0.85rem',
                        },
                        input: { color: '#2196f3', fontSize: '0.85rem' }
                      }}
                    />
                  )
                }
                <FormInputText
                  name={"site"}
                  control={control}
                  label={"현장명 또는 현장주소로 검색하세요."}
                  onCustomChange={handleChangeSearch}
                  sx={{
                    width: 408,
                    '& .MuiInputBase-root': {
                      background: "#E3EEFA"
                    },
                    '& .MuiFormLabel-root' : {
                      color: '#2196f3',
                      fontSize: '0.85rem',
                    },
                    input: { color: '#2196f3', fontSize: '0.85rem' }
                  }}
                />
                <FormInputDate
                  name="startDate"
                  control={control}
                  label={"시작"}
                  onChangePeriodValue={handleChangePeriod}
                  // value={getValues("startDate")}
                  value={startDate}
                  getValues={getValues}
                  setValue={setValue}
                  inputHeight={37}
                  fontSize={14}
                  clearable={true}
                  customWidth={250} // TODO : FormInputDate 내부의 FormControl의 sx 속성에 width를 반영했는데 아래처럼 sx 속성을 통해 할 수 있는 방안이 있는지 추후 검토 필요
                  iconSize={"small"}
                  // sx={{ width: { /*xs: 330, */sm: 500 } }}
                />
                <FormInputDate
                  name="endDate"
                  control={control}
                  label={"마감"}
                  onChangePeriodValue={handleChangePeriod}
                  // value={getValues("endDate")}
                  value={endDate}
                  getValues={getValues}
                  setValue={setValue}
                  inputHeight={37}
                  fontSize={14}
                  clearable={true}
                  customWidth={250}
                  iconSize={"small"}
                />
                <FormInputDate
                  name="completionDate"
                  control={control}
                  label={"준공일"}
                  onChangePeriodValue={handleChangePeriod}
                  // value={getValues("completionDate")}
                  setValue={setValue}
                  inputHeight={37}
                  clearable={true}
                  fontSize={14}
                  customWidth={250}
                  iconSize={"small"}
                />
              </Stack>
            </Grid>
            {
              location.pathname === '/gsupplyConfirmRequestList' && (
                <>
                  <Grid item xs={12} sm={10}>
                    <Stack direction="row" spacing={1}>
                      <FormInputText
                        name={"constructionCompanyName"}
                        control={control}
                        label={"발행업체로 검색하세요."}
                        onCustomChange={handleChangeSearch}
                        sx={{
                          width: 408,
                          '& .MuiInputBase-root': {
                            background: "#E3EEFA"
                          },
                          '& .MuiFormLabel-root' : {
                            color: '#2196f3',
                            fontSize: '0.85rem',
                          },
                          input: { color: '#2196f3', fontSize: '0.85rem' }
                        }}
                      />
                      <FormInputDate
                        name="requestAt"
                        control={control}
                        label={"요청일자"}
                        onChangePeriodValue={handleChangePeriod}
                        // value={getValues("startDate")}
                        value={requestAt}
                        getValues={getValues}
                        setValue={setValue}
                        inputHeight={37}
                        fontSize={14}
                        clearable={true}
                        customWidth={250} // TODO : FormInputDate 내부의 FormControl의 sx 속성에 width를 반영했는데 아래처럼 sx 속성을 통해 할 수 있는 방안이 있는지 추후 검토 필요
                        iconSize={"small"}
                        // sx={{ width: { /*xs: 330, */sm: 500 } }}
                      />
                      <FormInputDate
                        name="confirmAt"
                        control={control}
                        label={"발행일자"}
                        onChangePeriodValue={handleChangePeriod}
                        // value={getValues("startDate")}
                        value={confirmAt}
                        getValues={getValues}
                        setValue={setValue}
                        inputHeight={37}
                        fontSize={14}
                        clearable={true}
                        customWidth={250} // TODO : FormInputDate 내부의 FormControl의 sx 속성에 width를 반영했는데 아래처럼 sx 속성을 통해 할 수 있는 방안이 있는지 추후 검토 필요
                        iconSize={"small"}
                        // sx={{ width: { /*xs: 330, */sm: 500 } }}
                      />
                      <Box>
                        <ToggleButtonGroup
                          value={statusRequestListFilters}
                          onChange={handleStatusChange}
                          aria-label="상태 필터"
                          multiple
                          size="small"
                          color="primary"
                          sx={{ height: 37, ml: 2.5 }}
                        >
                          <ToggleButton value="작성중" aria-label="작성중" color="primary">
                            작성중
                          </ToggleButton>
                          <ToggleButton value="요청" aria-label="요청" color="primary">
                            요청
                          </ToggleButton>
                          <ToggleButton value="검토중" aria-label="검토중" color="primary">
                            검토중
                          </ToggleButton>
                          <ToggleButton value="발행" aria-label="발행" color="primary">
                            발행
                          </ToggleButton>
                          <ToggleButton value="반려" aria-label="반려" color="primary">
                            반려
                          </ToggleButton>
                          <ToggleButton value="완료" aria-label="완료" color="primary">
                            완료
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      startIcon={<Search />}
                      onClick={handleClickSearch}
                    >
                      {"검색"}
                    </Button>
                  </Grid>
                </>
              )
            }
            {
              location.pathname === '/gsupplyConfirmPublish' && (
                <>
                  <Grid item xs={12} sm={10}>
                    <Stack direction="row" spacing={1}>
                      <FormInputText
                        name={"constructionCompanyName"}
                        control={control}
                        label={"요청업체로 검색하세요."}
                        onCustomChange={handleChangeSearch}
                        sx={{
                          width: 408,
                          '& .MuiInputBase-root': {
                            background: "#E3EEFA"
                          },
                          '& .MuiFormLabel-root' : {
                            color: '#2196f3',
                            fontSize: '0.85rem',
                          },
                          input: { color: '#2196f3', fontSize: '0.85rem' }
                        }}
                      />
                      <FormInputDate
                        name="requestAt"
                        control={control}
                        label={"요청일자"}
                        onChangePeriodValue={handleChangePeriod}
                        // value={getValues("startDate")}
                        value={requestAt}
                        getValues={getValues}
                        setValue={setValue}
                        inputHeight={37}
                        fontSize={14}
                        clearable={true}
                        customWidth={250} // TODO : FormInputDate 내부의 FormControl의 sx 속성에 width를 반영했는데 아래처럼 sx 속성을 통해 할 수 있는 방안이 있는지 추후 검토 필요
                        iconSize={"small"}
                        // sx={{ width: { /*xs: 330, */sm: 500 } }}
                      />
                      <Box>
                        <ToggleButtonGroup
                          value={statusPublishFilters}
                          onChange={handleStatusChange}
                          aria-label="상태 필터"
                          multiple
                          size="small"
                          color="primary"
                          sx={{ height: 37, ml: 2.5 }}
                        >
                          <ToggleButton value="요청" aria-label="요청" color="primary">
                            요청
                          </ToggleButton>
                          <ToggleButton value="검토중" aria-label="검토중" color="primary">
                            검토중
                          </ToggleButton>
                          <ToggleButton value="반려" aria-label="반려" color="primary">
                            반려
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                      <Box>
                        <RadioGroup
                          row
                          value={issueType}
                          onChange={handleIssueTypeChange}
                          sx={{ ml: 2.5 }} // Box로 감싸야지만 적용됨
                        >
                          <FormControlLabel 
                            value="requestSupply" 
                            control={<Radio size="small" />} 
                            label="요청발행" 
                            sx={{ 
                              '& .MuiFormControlLabel-label': { 
                                fontSize: '0.85rem',
                                color: '#2196f3'
                              }
                            }}
                          />
                          <FormControlLabel 
                            value="selfSupply" 
                            control={<Radio size="small" />} 
                            label="자체발행" 
                            sx={{ 
                              '& .MuiFormControlLabel-label': { 
                                fontSize: '0.85rem',
                                color: '#2196f3'
                              }
                            }}
                          />
                        </RadioGroup>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      startIcon={<Search />}
                      onClick={handleClickSearch}
                      sx={{ mr: 1 }}
                    >
                      {"검색"}
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<Add />}
                      onClick={handleClickSearch}
                    >
                      {"프로젝트 생성하기"}
                    </Button>
                  </Grid>
                </>
              )
            }
            {
              location.pathname === '/gsupplyConfirmPublishList' && (
                <>
                  <Grid item xs={12} sm={10}>
                    <Stack direction="row" spacing={1}>
                      <FormInputText
                        name={"constructionCompanyName"}
                        control={control}
                        label={"요청업체로 검색하세요."}
                        onCustomChange={handleChangeSearch}
                        sx={{
                          width: 408,
                          '& .MuiInputBase-root': {
                            background: "#E3EEFA"
                          },
                          '& .MuiFormLabel-root' : {
                            color: '#2196f3',
                            fontSize: '0.85rem',
                          },
                          input: { color: '#2196f3', fontSize: '0.85rem' }
                        }}
                      />
                      <FormInputDate
                        name="requestAt"
                        control={control}
                        label={"요청일자"}
                        onChangePeriodValue={handleChangePeriod}
                        // value={getValues("startDate")}
                        value={requestAt}
                        getValues={getValues}
                        setValue={setValue}
                        inputHeight={37}
                        fontSize={14}
                        clearable={true}
                        customWidth={250} // TODO : FormInputDate 내부의 FormControl의 sx 속성에 width를 반영했는데 아래처럼 sx 속성을 통해 할 수 있는 방안이 있는지 추후 검토 필요
                        iconSize={"small"}
                        // sx={{ width: { /*xs: 330, */sm: 500 } }}
                      />
                      <FormInputDate
                        name="confirmAt"
                        control={control}
                        label={"발행일자"}
                        onChangePeriodValue={handleChangePeriod}
                        // value={getValues("startDate")}
                        value={confirmAt}
                        getValues={getValues}
                        setValue={setValue}
                        inputHeight={37}
                        fontSize={14}
                        clearable={true}
                        customWidth={250} // TODO : FormInputDate 내부의 FormControl의 sx 속성에 width를 반영했는데 아래처럼 sx 속성을 통해 할 수 있는 방안이 있는지 추후 검토 필요
                        iconSize={"small"}
                        // sx={{ width: { /*xs: 330, */sm: 500 } }}
                      />
                      <Box>
                        <ToggleButtonGroup
                          value={statusPublishListFilters}
                          onChange={handleStatusChange}
                          aria-label="상태 필터"
                          multiple
                          size="small"
                          color="primary"
                          sx={{ height: 37, ml: 2.5 }}
                        >
                          <ToggleButton value="발행" aria-label="발행" color="primary">
                            발행
                          </ToggleButton>
                          <ToggleButton value="완료" aria-label="완료" color="primary">
                            완료
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={2} display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      startIcon={<Search />}
                      onClick={handleClickSearch}
                    >
                      {"검색"}
                    </Button>
                  </Grid>
                </>
              )
            }
          </Grid>
          <div style={{ height: GRID_HEIGHT.REDUCED, width: '100%' }}>
            <DataGridPro
              localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'publish', params })}
            />
          </div>
        </Box>
        <ConfirmDialog
          removeId={removeId}
          title={"삭제"}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={removeGSupplyConfirm}
          onCancel={() => {}}
        >
          <div>
            <span style={{ color: "#1976d2" }}>{`${params?.row?.supplyGClientName || ''}`}</span>{"에 요청한 납품의뢰서 정보를 삭제하시겠습니까?"}<br /><br />
            <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em' }}>
              {/* {`아이디 : ${params?.id || ''}`}<br /> */}
              {`현장명 : ${params?.row?.gproject?.site || ''}`}<br />
              {`현장주소 : ${params?.row?.gproject?.siteAddress || ''}`}<br /> 
              {`건설사명 : ${params?.row?.gproject?.constructionCompanyName || ''}`}<br />
              {`준공일 : ${params?.row?.gproject?.completionDate ? dateFormat(params.row.gproject.completionDate, 'yyyy-MM-dd') : ''}`}
            </Box>
          </div>
        </ConfirmDialog>
        <AlertDialog
          alertInfo={alertInfo}
          setAlertInfo={setAlertInfo}
        />
        <DocumentViewerDrawer 
          open={documentViewerDrawerOpen}
          onClose={() => setDocumentViewerDrawerOpen(false)}
          url={currentUrl}
        />
      </Container>
    </ThemeProvider>
  );
};

export default GSupplyConfirmManagement;
