import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro, GridActionsCellItem, koKR } from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import  {
  Assessment,
  OpenInNew,
  Preview,
  Search,
} from '@mui/icons-material';
import {
  FormInputDate,
  FormInputText,
} from "../form";
import {
  DialogTitleClose,
} from "../dialog";
import {
  CustomNoRowsOverlay,
} from "../datagrid";
import { StyledTooltip } from "../tooltip";
import {
  dateFormat,
} from "../../utils";
import { uploadFilePath, fileServerUrl } from '../../config';
import DocumentViewerDrawer from "../common/viewer/DocumentViewerDrawer";
import * as gglassPerformanceActions from "../../store/gglassPerformance";

const theme = createTheme();

const tooltipTop = {
  "& .MuiTooltip-tooltip": {
    borderRadius: "0px",
  }
};

const defaultValues = {
  searchName: "",
  searchSite: "",
  startDate: "",
  endDate: "",
  completionDate: "",
};

const border = '1px solid rgba(224, 224, 224, 1)';
const headerBackgroundColor = '#f5f5f5';

const ProjectWithPerformance = () => {
  const { handleSubmit, control, setValue, getValues } = useForm({ defaultValues });

  const [loaded, setLoaded] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGProject, setSelectedGProject] = useState(null);
  const [documentViewerDrawerOpen, setDocumentViewerDrawerOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  const sessionUser = useSelector((state) => state.session.sessionUser);
  const gprojectsWithPerformance = useSelector((state) => state.gglassPerformance.gprojectsWithPerformance);
  const gglassPerformMaps = useSelector((state) => state.gglassPerformance.gglassPerformMaps);

  const dispatch = useDispatch();
  
  const selectProjectsWithPerformance = (searchName, searchSite, startDate, endDate, completionDate) => dispatch(gglassPerformanceActions.selectProjectsWithPerformance(searchName, searchSite, startDate, endDate, completionDate))
  const selectPerformanceByGProject = (gprojectId) => dispatch(gglassPerformanceActions.selectPerformanceByGProject(gprojectId))

  const columns = [
    {
      field: 'gclientName',
      headerName: '공급업체',
      width: 200,
    },
    {
      field: 'site',
      headerName: '현장명',
      width: 250,
      // editable: true,
    },
    {
      field: 'siteAddress',
      headerName: '현장주소',
      width: 400,
      // editable: true,
    },
    {
      field: 'constructionCompanyName',
      headerName: '건설사명',
      width: 160,
    },
    {
      field: 'startDate',
      headerName: '시작',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'endDate',
      headerName: '마감',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'completionDate',
      headerName: '준공일',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => params.value && dateFormat(params.value, 'yyyy-MM-dd'),
    },
    {
      field: 'doneYN',
      headerName: '마감여부',
      headerAlign: 'center',
      align: 'center',
      width: 120,
      valueGetter: (params) => params.value ? '마감' : '마감전',
    },
    {
      field: 'comments',
      headerName: '설명',
      width: 400,
      // editable: true,
    },
    {
      field: 'createdAt',
      headerName: '생성일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'updatedAt',
      headerName: '수정일시',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => dateFormat(params.value),
    },
    {
      field: 'actions',
      headerName: <Tooltip title={"성능확인서 보기"} followCursor><Box>{"기능"}</Box></Tooltip>,
      width: 70,
      type: 'actions',
      getActions: (params) => generateActions(params),
    },
  ];

  const generateActions = (params) => {
    let arrActions = [
      <GridActionsCellItem
        icon={<Assessment />}
        label={"성능확인서 보기"}
        onClick={() => handleSelect({ type: 'performanceView', params })}
        showInMenu
      />,
    ];

    return arrActions;
  }

  useEffect(() => {
    const fetchData = async () => {
      await refreshProjectsWithPerformance();
    };

    fetchData();
  }, []);

  const refreshProjectsWithPerformance = async () => {
    try {
      const searchName = getValues("searchName");
      const searchSite = getValues("searchSite");
      let startDate = getValues("startDate");
      let endDate = getValues("endDate");
      let completionDate = getValues("completionDate");
      startDate = startDate ? dateFormat(startDate.getDateWithStartHours()) : null;
      endDate = endDate ? dateFormat(endDate.getDateWithEndHours()) : null;
      completionDate = completionDate ? dateFormat(completionDate.getDateWithEndHours()) : null;

      console.log({ searchName, searchSite, startDate, endDate, completionDate });

      setLoaded(false);

      await selectProjectsWithPerformance(searchName, searchSite, startDate, endDate, completionDate);
      setLoaded(true);
    } catch (error) {
      console.error('데이터 로딩 중 오류 발생:', error);
      setLoaded(true); // 에러가 발생해도 로딩 상태는 해제
    }
  }

  const handleSelect = async ({ type, params }) => {
    console.log(params)
    // setOpenBackdrop(true);
    // const id = selectedRows[0].id;
    const { id } = params;
    
    // if (type === "detail") {
    //   await select(id);
    //   setCrudMode('R');
    //   setOpen(true);
    // } else if (type === "edit") {
    //   await select(id);
    //   setCrudMode('U');
    //   setOpen(true);
    // } else if (type === "delete") {
    //   setRemoveId(id);
    //   setParams(params);
    //   setConfirmOpen(true);
    // }
    if (type === "performanceView") {
      setSelectedGProject(params.row)
      setOpenDialog(true);
    }
    // setOpenBackdrop(false);
  }

  var timer;
  const handleChangeSearch = async (e) => {
    // // 과도한 API 호출 방지를 위한 debouncing 코드. TODO : 라이브러리 활용 검토
    if (timer) {
      clearTimeout(timer);
    }
    
    // 타이머 설정
    timer = setTimeout(async () => {
      refreshProjectsWithPerformance();
    }, 500);
  };

  const handleChangePeriod = (newValue, name) => {
    console.log({ name, newValue, today: new Date() });
    
    if (!newValue) {
      return true;
    }

    if (name === "startDate") {
      const endDate = getValues("endDate");
      if (endDate && newValue > endDate) {
        setValue("endDate", newValue);
        setEndDate(newValue);
      }
      setStartDate(newValue);
    } else if (name === "endDate") {
      const startDate = getValues("startDate");
      if (startDate && newValue < startDate) {
        setValue("startDate", newValue);
        setStartDate(newValue);
      }
      setEndDate(newValue);
    }
  
    setValue(name, newValue);
    return true;
  }

  const handleClickSearch = () => {
    refreshProjectsWithPerformance();
  }

  const handleDialogClose = () => {
    setOpenDialog(false);

    initDialog();
  };

  const initDialog = () => {
  }

  useEffect(() => {
    if (selectedGProject) {
      selectPerformanceByGProject(selectedGProject.id);
    }
  }, [selectedGProject]);

  const generatePerformanceTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>No.</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>품번</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>유리 사양</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} colSpan={2}>가시광선(%)</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} colSpan={2}>태양열선(%)</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>열관류율(KS)<br/>(W/m²K)</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>차폐계수<br/>(SC)</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>태양열취득률<br/>(SHGC)</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>취득총열량<br/>(W/m²)</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>발행일자</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }} rowSpan={2}>성능확인서</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }}>투과율</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }}>반사율(실외)</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }}>투과율</TableCell>
              <TableCell align="center" sx={{ border, backgroundColor: headerBackgroundColor }}>반사율(실외)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gglassPerformMaps.map((glass, index) => (
              <TableRow key={glass.id}>
                <TableCell align="right" sx={{ border }}>{index + 1}</TableCell>
                <TableCell sx={{ border }}>{glass.no}</TableCell>
                <TableCell sx={{ border }}>{glass.specification.replaceAll("|", " ").replaceAll("+", " + ").replace(/\(.*?\)/, "")}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.vlt ? glass.performanceData.vlt : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.vlr_ex ? glass.performanceData.vlr_ex : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.st ? glass.performanceData.st : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.sr_ex ? glass.performanceData.sr_ex : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.u_value_ks ? glass.performanceData.u_value_ks.toFixed(2) : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.sc ? glass.performanceData.sc.toFixed(2) : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.shgc ? glass.performanceData.shgc.toFixed(2) : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.performanceData?.rhg ? glass.performanceData.rhg.toFixed(0) : '-'}</TableCell>
                <TableCell align="right" sx={{ border }}>{glass.createdAt ? dateFormat(glass.createdAt, 'yyyy-MM-dd') : '-'}</TableCell>
                <TableCell align="center" sx={{ border }}>
                  <StyledTooltip
                    title={<Stack direction="row" display="flex" alignItems="center">
                      <Box sx={{ solid: 1, borderRadius: 1, bgcolor: '#eee', color: '#aaa', p: 1, fontSize: '0.8em', mr: 1 }}>
                        {`미리보기`}
                      </Box>
                      <Typography variant="subtitle2">{glass.performanceDocumentPath?.split("/")[glass.performanceDocumentPath?.split("/")?.length-1]}</Typography>
                    </Stack>}
                    placement="right"
                    sx={tooltipTop}
                  >
                    <IconButton
                      color="primary"
                      aria-label="file"
                      onClick={() => handleClickViewEachDoc(glass.performanceDocumentPath)}
                    >
                      <Preview />
                    </IconButton>
                  </StyledTooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  // const handleClickViewEachDoc = (documentPath) => {
  //   const path = documentPath.replace(uploadFilePath, fileServerUrl);

  //   // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
  //   const randNumber = Math.floor(Math.random()*99);

  //   window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
  // }
  const handleClickViewEachDoc = (documentPath) => {
    const pathParts = documentPath.replace(uploadFilePath, '').split('/');
    
    // 캐싱으로 인해 이전 문서가 계속 보이는 문제 해결
    const randNumber = Math.floor(Math.random()*99);
    
    // 경로를 '/'로 분리하고 각 부분을 개별적으로 인코딩
    const encodedPath = pathParts.map(part => encodeURIComponent(part)).join('/');

    const path = `${fileServerUrl}${encodedPath}?q=cat&${randNumber}`;

    // window.open(`${path}?q=cat&${randNumber}`, "미리보기", 'target="_self"');
    setCurrentUrl(path);
    if (!documentViewerDrawerOpen) {
      setDocumentViewerDrawerOpen(true);
    }
  }

  return (
    <>
      <Paper sx={{ p: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={{ p: 1, border: '1px solid #E0E0E0', background: '#F2F2F2', borderRadius: 1 }}>
              {"프로젝트"}
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack direction="row" spacing={1}>
              <FormInputText
                name={"searchName"}
                control={control}
                label={"공급업체 또는 건설사명으로 검색하세요."}
                onCustomChange={handleChangeSearch}
                sx={{
                  width: 408,
                  '& .MuiInputBase-root': {
                    background: "#E3EEFA"
                  },
                  '& .MuiFormLabel-root' : {
                    color: '#2196f3',
                    fontSize: '0.85rem',
                  },
                  input: { color: '#2196f3', fontSize: '0.85rem' }
                }}
              />
              <FormInputText
                name={"searchSite"}
                control={control}
                label={"현장명 또는 현장주소로 검색하세요."}
                onCustomChange={handleChangeSearch}
                sx={{
                  width: 408,
                  '& .MuiInputBase-root': {
                    background: "#E3EEFA"
                  },
                  '& .MuiFormLabel-root' : {
                    color: '#2196f3',
                    fontSize: '0.85rem',
                  },
                  input: { color: '#2196f3', fontSize: '0.85rem' }
                }}
              />
              <FormInputDate
                name="startDate"
                control={control}
                label={"시작"}
                onChangePeriodValue={handleChangePeriod}
                // value={getValues("startDate")}
                value={startDate}
                getValues={getValues}
                setValue={setValue}
                inputHeight={37}
                fontSize={14}
                clearable={true}
                customWidth={250} // TODO : FormInputDate 내부의 FormControl의 sx 속성에 width를 반영했는데 아래처럼 sx 속성을 통해 할 수 있는 방안이 있는지 추후 검토 필요
                iconSize={"small"}
                // sx={{ width: { /*xs: 330, */sm: 500 } }}
              />
              <FormInputDate
                name="endDate"
                control={control}
                label={"마감"}
                onChangePeriodValue={handleChangePeriod}
                // value={getValues("endDate")}
                value={endDate}
                getValues={getValues}
                setValue={setValue}
                inputHeight={37}
                fontSize={14}
                clearable={true}
                customWidth={250}
                iconSize={"small"}
              />
              <FormInputDate
                name="completionDate"
                control={control}
                label={"준공일"}
                onChangePeriodValue={handleChangePeriod}
                // value={getValues("completionDate")}
                setValue={setValue}
                inputHeight={37}
                clearable={true}
                fontSize={14}
                customWidth={250}
                iconSize={"small"}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              startIcon={<Search />}
              onClick={handleClickSearch}
            >
              {"검색"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: 400, width: '100%' }}>
              <DataGridPro
                localeText={koKR.components.MuiDataGrid.defaultProps.localeText}
              sx={{ cursor: 'pointer', fontSize: '0.85em' }}
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                loadingOverlay: LinearProgress,
              }}
              columnHeaderHeight={38}
              rowHeight={34}
              loading={!loaded}
              rows={gprojectsWithPerformance}
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onRowDoubleClick={(params) => handleSelect({ type: 'performanceView', params })}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        fullScreen={true}
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
        scroll="body"
        disableEscapeKeyDown={true}
      >
        <DialogTitleClose
          id="draggable-dialog-title"
          onClose={handleDialogClose}
          fullScreen={true}
          color="white"
          style={{ backgroundColor: "#1976d2" }}
        >
          <div id="dialog-position">
            {"성능확인서"}
          </div>
        </DialogTitleClose>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <FormInputText
                  name={"site"}
                  control={control}
                  label={"현장명"}
                  value={selectedGProject?.site}
                  disabled={true}
                  sx={{ width: { sm: 300 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                />
                <FormInputText
                  name={"siteAddress"}
                  control={control}
                  label={"현장주소"}
                  value={selectedGProject?.siteAddress}
                  disabled={true}
                  sx={{ width: { sm: 500 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                />
                <FormInputText
                  name={"gclientName"}
                  control={control}
                  label={"업체명"}
                  value={selectedGProject?.gclientName}
                  disabled={true}
                  sx={{ width: { sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                />
                <FormInputText
                  name={"constructionCompanyName"}
                  control={control}
                  label={"건설사명(제출처)"}
                  value={selectedGProject?.constructionCompanyName}
                  disabled={true}
                  sx={{ width: { sm: 250 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <FormInputText
                  name={"startDate"}
                  control={control}
                  label={"시작"}
                  value={selectedGProject?.startDate && dateFormat(selectedGProject.startDate, 'yyyy-MM-dd')}
                  disabled={true}
                  sx={{ width: { /*xs: 330, */sm: 150 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                />
                <FormInputText
                  name={"endDate"}
                  control={control}
                  label={"마감"}
                  value={selectedGProject?.endDate && dateFormat(selectedGProject.endDate, 'yyyy-MM-dd')}
                  disabled={true}
                  sx={{ width: { sm: 150 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                />
                <FormInputText
                  name={"completionDate"}
                  control={control}
                  label={"준공일"}
                  value={selectedGProject?.completionDate && dateFormat(selectedGProject.completionDate, 'yyyy-MM-dd')}
                  disabled={true}
                  sx={{ width: { sm: 150 } }} // 화면 스케일에 따라 크기 조정이 필요한 경우
                />
              </Stack>
            </Grid>
            <Grid item sm={12}>
              {generatePerformanceTable()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>{"닫기"}</Button>
        </DialogActions>
      </Dialog>
      <DocumentViewerDrawer 
        open={documentViewerDrawerOpen}
        onClose={() => setDocumentViewerDrawerOpen(false)}
        url={currentUrl}
        anchor="left"
      />
    </>
  );
};

export default ProjectWithPerformance;