// layout.js
const SET_MENU_LAYOUT = 'layout/SET_MENU_LAYOUT';

// 액션 생성자 수정
export const setMenuLayout = (layout) => ({
  type: SET_MENU_LAYOUT,
  payload: layout  // layout -> payload로 변경
});

const initialState = {
  menuLayout: localStorage.getItem('menuLayout') || 'left'
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MENU_LAYOUT:
      return { 
        ...state, 
        menuLayout: action.payload  // action.layout -> action.payload로 변경
      };
    default:
      return state;
  }
}