import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Button,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ReactPlayer from 'react-player';

import * as constructionWorkTypeActions from './store/constructionWorkType';
import * as commonActions from './store/common';

const theme = createTheme();

const StyledButton = styled((props) => (
  <Button {...props}/>
))(
    ({ theme, ...props }) => {
      const { sx } = { ...props };
      return {
        '&:hover': {
          backgroundColor: sx.bgcolor,
          filter: 'brightness(120%)',
        },
    }}
);

const Intro = () => {  
  const constructionWorkTypes = useSelector(state => state.constructionWorkType.constructionWorkTypes);
  const manageStatus = useSelector(state => state.common.manageStatus);

  const dispatch = useDispatch();

  const selectConstructionTypes = () => dispatch(constructionWorkTypeActions.selectAll())
  const selectManageStatus = () => dispatch(commonActions.selectManageStatus())

  useEffect(
    async () => {
      await selectConstructionTypes();
      await selectManageStatus();
    }, []
  )

  const Types = ({ name, comments, color, url, col }) => {
    return (
      <Grid item xs={12} sm={col} display="flex" justifyContent={"center"} alignItems="center">
        <Box
          sx={{
            width: '100%', 
            height: '140px',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'scale(1.6)',
              zIndex: 1  // 다른 요소들 위에 표시되도록
            }
          }}
        >
          <StyledButton component={Link} to={url || ""} variant="contained" sx={{ width: '100%', height: '100%', fontSize: '1.06rem', fontWeight: 'bold', bgcolor: color || '#aaa', borderRadius: 0 }}>
            <Grid container spacing={0}>
              <Grid item xs={12} display="flex" justifyContent={"center"} alignItems="center">
                {name}
              </Grid>
              <Grid item xs={12} display="flex" justifyContent={"center"} sx={{ fontSize: '0.7em' }}>
                {comments || '준비중 입니다...'}
              </Grid>
              {name === '유리공사' && manageStatus && (
                <Grid item xs={12} sx={{ 
                  mt: 1, 
                  fontSize: '0.7em',
                  height: '20px',
                  overflow: 'hidden',
                  position: 'relative'
                }}>
                  <Box sx={{
                    // '@keyframes slideUp': {
                    //   '0%': { transform: 'translateY(0)' },
                    //   '23%': { transform: 'translateY(0)' },
                    //   '25%': { transform: 'translateY(-20px)' },
                    //   '48%': { transform: 'translateY(-20px)' },
                    //   '50%': { transform: 'translateY(-40px)' },
                    //   '73%': { transform: 'translateY(-40px)' },
                    //   '75%': { transform: 'translateY(-60px)' },
                    //   '98%': { transform: 'translateY(-60px)' },
                    //   '100%': { transform: 'translateY(-80px)' }
                    // },
                    '@keyframes slideUp': {
                      '0%': { transform: 'translateY(0)' },
                      '48%': { transform: 'translateY(0)' },
                      '50%': { transform: 'translateY(-20px)' },
                      '98%': { transform: 'translateY(-20px)' },
                      '100%': { transform: 'translateY(-40px)' }
                    },
                    animation: 'slideUp 12s infinite linear',
                    position: 'absolute',
                    width: '100%'
                  }}>
                    <Box sx={{ height: '20px', display: 'flex', justifyContent: 'center' }}>
                      <span>회원사</span>
                      <span style={{ marginLeft: '5px' }}>{manageStatus.gclientCnt.toLocaleString()} 곳</span>
                    </Box>
                    <Box sx={{ height: '20px', display: 'flex', justifyContent: 'center' }}>
                      <span>등록문서</span>
                      <span style={{ marginLeft: '5px' }}>{manageStatus.g04docuCnt.toLocaleString()} 건</span>
                    </Box>
                    {/* 현재는 발급수가 적어 노출하지 않음 */}
                    {/* <Box sx={{ height: '20px', display: 'flex', justifyContent: 'center' }}>
                      <span>자재승인서</span>
                      <span style={{ marginLeft: '5px' }}>{manageStatus.materialApprovalCnt.toLocaleString()} 건</span>
                    </Box>
                    <Box sx={{ height: '20px', display: 'flex', justifyContent: 'center' }}>
                      <span>납품확인서</span>
                      <span style={{ marginLeft: '5px' }}>{manageStatus.supplyConfirmCnt.toLocaleString()} 건</span>
                    </Box> */}
                    {/* 첫 번째 항목을 다시 한번 추가하여 끊김 없는 롤링 구현 */}
                    <Box sx={{ height: '20px', display: 'flex', justifyContent: 'center' }}>
                      <span>회원사</span>
                      <span style={{ marginLeft: '5px' }}>{manageStatus.gclientCnt.toLocaleString()} 곳</span>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </StyledButton>
        </Box>
      </Grid>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Container 
        component="main" 
        maxWidth="false" 
        sx={{ 
          // maxWidth: '1800px',  // 원하는 크기로 직접 지정
          mx: 'auto',  // 가운데 정렬을 위해
          bgcolor: '#000000',
          minHeight: 'calc(100vh - 275px)', // 전체 뷰포트 높이
          display: 'flex',
          alignItems: 'center', // 수직 중앙 정렬
        }}
      >
        <CssBaseline />
        <Grid container spacing={3} sx={{ mt: 5, mb: -3 }}>
          <Grid item xs={12} sm={6} display="flex" justifyContent={"center"} alignItems="center">
            <ReactPlayer
              width={'70%'}
              height='60%'
              url={`/intro.mp4`} // 캐싱으로 인해 이전 서비스 영상이 계속 보이는 문제 해결
              playing={true}
              muted={true}
              controls={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1.5} sx={{ p: 5, width: '80%' }}>
              {
                constructionWorkTypes &&
                constructionWorkTypes.length > 0 &&
                constructionWorkTypes
                  .map((type) => <Types key={type.id || type.index} name={type.name} comments={type.comments} color={type.color} url={type.url} col={3} />)
              }
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

export default Intro;