import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import session from './session';
import gunit from './gunit';
// import gmanufacturer from './gmanufacturer';
import gcomponent from './gcomponent';
import gcomponentItem from './gcomponentItem';
import gtype from './gtype';
import gtypeDetail from './gtypeDetail';
import gglass from './gglass';
import gsub from './gsub';
import gsubItem from './gsubItem';
import gstandardType from './gstandardType';
import gstandard from './gstandard';
import g04docu from './g04docu';
import g04docuGCertification from './g04docuGCertification';
import gclientG04docuGCertificationMap from "./gclientG04docuGCertificationMap";
import gclientG04docuGCertificationDetailMap from "./gclientG04docuGCertificationDetailMap";
import g04docuGTest from './g04docuGTest';
import gclientG04docuGTestMap from "./gclientG04docuGTestMap";
import g04docuGTestSubstitute from './g04docuGTestSubstitute';
import gclientG04docuGTestSubstituteMap from "./gclientG04docuGTestSubstituteMap";
import gclientType from './gclientType';
import gclientType04docu from './gclientType04docu';
import gclient from './gclient';
import user from './user';
import gprocess from './gprocess';
import gproject from './gproject';
import gprojectGlass from './gprojectGlass';
import gprojectGlassPrice from './gprojectGlassPrice';
import gorder from './gorder';
import gorderDetail from './gorderDetail';
import greception from './greception';
import greceptionDetail from './greceptionDetail';
import gworkOrder from './gworkOrder';
import gworkOrderProcess from './gworkOrderProcess';
import gworkOrderDetail from './gworkOrderDetail';
import gworkOrderDetailProcess from './gworkOrderDetailProcess';
import gplan from './gplan';
import gplanPlanning from './gplanPlanning';
import gline from './gline';
import glineCapacity from './glineCapacity';
import menu from './menu';
import gclientGComponentItemMap from './gclientGComponentItemMap';
import gclientG04docuMap from "./gclientG04docuMap";
import g04docuFile from "./g04docuFile";
import g04docuMaterialApproval from "./g04docuMaterialApproval";
import g04GeneralDocu from "./g04GeneralDocu";
import gclientG04GeneralDocuMap from "./gclientG04GeneralDocuMap";
import constructionWorkType from "./constructionWorkType";
import g04docuEtc from './g04docuEtc';
import g04docuSearch from './g04docuSearch';
import gclientG04docuEtcMap from "./gclientG04docuEtcMap";
import mail from './mail';
import gsendBasket from './gsendBasket';
import gsendG04docu from './gsendG04docu';
import security from './security';
import securityRequest from './securityRequest';
import error from './error';
import footer from './footer';
import notification from './notification';
import datagrid from './components/datagrid';
import dialog from './components/dialog';
import confirmDialog from './components/confirmDialog';
import alertDialog from './components/alertDialog';
import materialApprovalProgress from './materialApprovalProgress';
import gtestComparison from './gtestComparison';
import g04docuGTestComparison from './g04docuGTestComparison';
import gdomesticAuth from './gdomesticAuth';
import gprojectMap from './gprojectMap';
import gdomesticAuthMap from './gdomesticAuthMap';
import gglassPerformance from './gglassPerformance';
import gclientG04docuFile from './gclientG04docuFile';
import g04docuAlarmEmail from './g04docuAlarmEmail';
import consent from './consent';
import gperfData from './gperfData';
import gsupplyConfirm from './gsupplyConfirm';
import addressBook from './addressBook';
import gclientPayments from './gclientPayments';
import common from './common';
import gperformReport from './gperformReport';
import layout from './layout';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session', 'menu'], // 유지하고 싶은 리듀서 목록
};

const rootReducer = combineReducers({
  error,
  session,
  gunit,
  // gmanufacturer,
  gcomponent,
  gcomponentItem,
  gtype,
  gtypeDetail,
  gglass,
  gsub,
  gsubItem,
  gstandardType,
  gstandard,
  g04docu,
  g04docuGCertification,
  gclientG04docuGCertificationMap,
  gclientG04docuGCertificationDetailMap,
  g04docuGTest,
  gclientG04docuGTestMap,
  g04docuGTestSubstitute,
  gclientG04docuGTestSubstituteMap,
  gclientType,
  gclientType04docu,
  gclient,
  user,
  gprocess,
  gproject,
  gprojectGlass,
  gprojectGlassPrice,
  gorder,
  gorderDetail,
  greception,
  greceptionDetail,
  gworkOrder,
  gworkOrderProcess,
  gworkOrderDetail,
  gworkOrderDetailProcess,
  gplan,
  gplanPlanning,
  gline,
  glineCapacity,
  menu,
  gclientGComponentItemMap,
  gclientG04docuMap,
  g04docuFile,
  g04docuMaterialApproval,
  g04GeneralDocu,
  gclientG04GeneralDocuMap,
  constructionWorkType,
  g04docuEtc,
  g04docuSearch,
  gclientG04docuEtcMap,
  mail,
  gsendBasket,
  gsendG04docu,
  security,
  securityRequest,
  footer,
  notification,
  datagrid,
  dialog,
  confirmDialog,
  alertDialog,
  materialApprovalProgress,
  gtestComparison,
  g04docuGTestComparison,
  gdomesticAuth,
  gprojectMap,
  gdomesticAuthMap,
  gglassPerformance,
  gclientG04docuFile,
  g04docuAlarmEmail,
  consent,
  gperfData,
  gsupplyConfirm,
  addressBook,
  gclientPayments,
  common,
  gperformReport,
  layout,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let enhancer;

if (process.env.NODE_ENV === 'production') {
  enhancer = applyMiddleware(thunk);
} else {
  const logger = require('redux-logger').default;
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
  const store = createStore(persistedReducer, preloadedState, enhancer);
  const persistor = persistStore(store);
  return { store, persistor };
}

export default configureStore;
