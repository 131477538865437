import React, { useEffect, useState } from 'react';
import { Link as RouterLink, Navigate, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Collapse,
} from '@mui/material';
import {
  AccountCircle,
  AddShoppingCart,
  Inbox,
  Login,
  Logout,
  ManageAccounts,
  ReceiptLong,
  Settings,
  Menu as MenuIcon, // 추가
  ViewStream,
  ChevronRight as ChevronRightIcon,
  Article, // 문서 관련 아이콘 추가
  Description, // 문서 관련 추가 아이콘
  Assignment, // 과제/할일 관련 아이콘
  FolderOpen,
  OpenInBrowser, // 폴더 관련 아이콘
  Apps,
  BorderLeft,
  BorderTop,
} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // 추가
// import NavigationGuard from './guard/NavigationGuard';
import * as sessionActions from '../../store/session';
import * as securityActions from '../../store/security';
import * as menuActions from '../../store/menu';
import * as layoutActions from '../../store/layout';
import CascadingHoverMenus from './CascadingHoverMenus';
import SendBasketDialog from './SendBasketDialog';
import PaymentHistoryDialog from './PaymentHistoryDialog';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 5,
    top: 13,
    // border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: "white",
    // backgroundColor: "#9e9e9e",
    backgroundColor: "#2EA2DE", // 빨간색으로 변경
  },
}));

const useMenuLayout = () => {
  const menuLayout = useSelector(state => state.layout?.menuLayout);
  console.log("menuLayout", menuLayout);
  const dispatch = useDispatch();

  const setMenuLayout = (layout) => {
    if (layout) {
      dispatch(layoutActions.setMenuLayout(layout));
      localStorage.setItem('menuLayout', layout); // 로컬 스토리지에 저장
    }
  };

  return [menuLayout, setMenuLayout];
};

const Navigation = ({ isLoaded, menus }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const sessionUser = useSelector(state => state.session.sessionUser);
  const error = useSelector(state => state.error.error);
  const gsendBaskets = useSelector(state => state.gsendBasket.gsendBaskets);

  const [openBasket, setOpenBasket] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openPaymentHistory, setOpenPaymentHistory] = useState(false);  
  const [mobileOpen, setMobileOpen] = useState(false); // 추가
  // 펼쳐진 메뉴 상태 관리를 위한 state 추가
  const [expandedMenus, setExpandedMenus] = useState({});
  const [menuLayout, setMenuLayout] = useMenuLayout();

  // const selectSecurityOpenGClientList = (service, gclientId) => dispatch(securityActions.selectSecurityOpenGClientList(service, gclientId))
  // const selectSecurityCloseGClientList = (service, gclientId) => dispatch(securityActions.selectSecurityCloseGClientList(service, gclientId))
  // const selectOpenedAboutMeGClientList = (service, gclientId) => dispatch(securityActions.selectOpenedAboutMeGClientList(service, gclientId))

  const handleMenuLayoutChange = () => {
    const newLayout = menuLayout === 'top' ? 'left' : 'top';
    setMenuLayout(newLayout);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = (e) => {
    e.preventDefault();
    
    dispatch(sessionActions.logout(sessionUser));
    dispatch(menuActions.removeAllTabMenus());
  };
  
  const handleClickProfile = () => {
    const profileMenu = {
      menu: {
        href: "/profile",
        id: "profile",
        title: "내 정보",
      }
    }

    dispatch(menuActions.setSelectedMenu(profileMenu));
      
    dispatch(menuActions.addTabMenu(profileMenu.menu));

    navigate("/profile");
  }

  const handleClickInbox = () => {
    setOpenBasket(true);
  }

  // 전체 모듈에서 사용
  // useEffect(
  //   () => {
  //     const gclientId = sessionUser?.id;
  //     if (gclientId) {
  //       const service = '04docu';
  //       // selectSecurityOpenGClientList(service, gclientId);
  //       // selectSecurityCloseGClientList(service, gclientId);
  //       selectOpenedAboutMeGClientList(service, gclientId);
  //     }
  //   }, [sessionUser]
  // )

  const handleClickPaymentHistory = () => {
    setOpenPaymentHistory(true);
  }

  const handleAccordionChange = (menuId) => {
    setExpandedMenus(prev => ({
      ...prev,
      [menuId]: !prev[menuId]  // 토글 동작으로 변경
    }));
  };

  // 재귀적으로 메뉴를 렌더링하는 함수
  const renderMenuItems = (menuItems, level = 0) => {
    return menuItems?.map((menu, index) => {
      const isSelected = location.pathname === menu.href;
      const menuId = `${level}-${index}-${menu.title}`;
      const isFirstItem = level === 0 && index === 0;  // 최상위 레벨의 첫 번째 아이템 체크
      
      if (menu.submenus && menu.submenus.length > 0) {
        return (
          <Box
            key={menuId}
            // sx={{ ml: level * 2 }}
          >
            <ListItem 
              disablePadding 
              sx={{
                position: 'relative',
                borderLeft: '1px solid #dcdcdc',
                borderRight: '1px solid #dcdcdc',
                borderBottom: '1px solid #dcdcdc',
                borderTop: isFirstItem ? '1px solid #dcdcdc' : 'none',  // 첫 번째 아이템에만 상단 테두리 추가
              }}
            >
              <ListItemButton
                onClick={() => handleAccordionChange(menuId)}
                sx={{
                  pl: 2,
                  bgcolor: isSelected ? '#42a5f5' : 'transparent',
                  color: isSelected ? '#fff' : '',
                  '&:hover': {
                    bgcolor: '#42a5f5', // hover 시 배경색 추가
                    color: '#fff',
                  },
                }}
              >
                <ListItemText
                  primary={menu.title}
                  sx={{ pl: level * 2 }} // 텍스트만 들여쓰기
                />
                {expandedMenus[menuId] ? <ExpandMoreIcon fontSize="small"/> : <ChevronRightIcon fontSize="small" />}
              </ListItemButton>
            </ListItem>
            <Collapse in={expandedMenus[menuId]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(menu.submenus, level + 1)}
              </List>
            </Collapse>
          </Box>
        );
      } else {
        return (
          <ListItem 
            key={index} 
            disablePadding
            sx={{ 
              position: 'relative',
              borderLeft: '1px solid #dcdcdc',
              borderRight: '1px solid #dcdcdc',
              borderBottom: '1px solid #dcdcdc',
              borderTop: isFirstItem ? '1px solid #dcdcdc' : 'none',  // 첫 번째 아이템에만 상단 테두리 추가
            }}
          >
            <ListItemButton
              component={RouterLink}
              to={menu.href || '#'}
              onClick={handleDrawerToggle}
              sx={{
                pl: 2,
                bgcolor: isSelected ? '#42a5f5' : 'transparent',
                color: isSelected ? '#fff' : '',
                '&:hover': {
                  bgcolor: '#42a5f5', // hover 시 배경색 추가
                  color: '#fff',
                },
              }}
            >
              <ListItemText
                primary={menu.title}
                sx={{ pl: level * 2 }} // 텍스트만 들여쓰기
              />
              <Apps fontSize="small" />
            </ListItemButton>
          </ListItem>
        );
      }
    });
  };
  
  // 모바일 메뉴 렌더링 함수 추가
  const renderMobileMenu = (
    <Drawer
      variant="temporary"
      anchor="left"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 280 },
      }}
    >
      <Box sx={{ p: 2 }}>
        {sessionUser && renderMenuItems(menus)}
      </Box>
    </Drawer>
  );

  // 상단 메뉴 렌더링
  const renderTopMenu = (
    <Box sx={{ 
      display: { xs: 'none', md: 'flex' },
      width: '100%',
      justifyContent: menuLayout === 'top' ? 'flex-start' : 'flex-end',
      mr: 2  // 우측 아이콘들과의 간격
    }}>
      {sessionUser && menuLayout === 'top' && menus.map((menu, index) => (
        <CascadingHoverMenus key={index} menu={menu} />
      ))}
      {renderMobileMenu}
    </Box>
  );

  // 좌측 메뉴 렌더링
  const renderLeftMenu = (
    <Drawer
      variant="permanent"
      sx={{
        display: { 
          xs: 'none', 
          md: location.pathname === '/glass/signin' ? 'none' : 'block' // 로그인 화면에서는 숨김
        },
        '& .MuiDrawer-paper': { 
          boxSizing: 'border-box', 
          width: 320,
          // position: 'fixed',
          top: '68.5px', // AppBar 높이만큼 여백
          height: 'calc(100% - 68.5px)',
          bgcolor: '#F0F0F0', // 배경색 추가
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        {sessionUser && renderMenuItems(menus)}
      </Box>
    </Drawer>
  );

  return (
    <>
      <AppBar
        position="fixed" // static에서 fixed로 변경
        // position="static"
        // TODO : style 적용하는 방법(customize) 여러가지가 있으므로 확인할 것
        elevation={0}
        sx={{
          bgcolor: '#F0F0F0',
          color: '#000',
          boxShadow: 'none',
          borderBottom: '1px solid #dcdcdc',
          zIndex: (theme) => theme.zIndex.drawer + 1, // drawer보다 위에 표시
        }}
      >
        {/* <Container maxWidth="xl"> */}
        <Container maxWidth="false">
          <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '68.5px' }}>
            {/* 모바일 메뉴 토글 버튼 추가 */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            {/* 왼쪽 로고 영역 */}
            <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Grid container sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, mr: 5 }}>
                <Box sx={{ 
                  fontFamily: 'Pretendard', 
                  letterSpacing: '0pt',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  ml: 1,
                }}>
                  <Typography component="span" sx={{ 
                    fontWeight: 800,  // ExtraBold
                    fontSize: '2rem',
                  }}>
                    04
                  </Typography>
                  <Typography component="span" sx={{
                    fontWeight: 300,  // Light
                    fontSize: '2rem',
                  }}>
                    DOCU
                  </Typography>
                </Box>
              </Grid>
            </RouterLink>
            {/* 중앙 메뉴 영역 */}
            {renderTopMenu}
            {/* 우측 아이콘 영역 */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexShrink: 0 }}>
              {/* 메뉴 레이아웃 변경 버튼 추가 */}
              {sessionUser && (
                <Tooltip title={menuLayout === 'top' ? "좌측 메뉴로 변경" : "상단 메뉴로 변경"}>
                  <IconButton 
                    onClick={handleMenuLayoutChange}
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                  >
                    {menuLayout === 'left' ? <BorderLeft /> : <BorderTop />}
                  </IconButton>
                </Tooltip>
              )}
              {
                sessionUser && (<Box sx={{ flexGrow: 0 }}>
                  <Tooltip title={"전송바구니"}>
                    <StyledBadge badgeContent={gsendBaskets.length ? gsendBaskets.length : "0"/* 숫자형태의 0이면 나타나지 않음 */} onClick={handleClickInbox} sx={{ cursor: 'pointer' }}>
                      <IconButton onClick={handleClickInbox}>
                        {/* <Inbox color="action" /> */}
                        <AddShoppingCart color="action" />
                      </IconButton>
                    </StyledBadge>
                  </Tooltip>
                </Box>)
              }
              <Box sx={{ flexGrow: 0 }}>
              {
                sessionUser && (
                  <>
                    {/* <Tooltip title="Account settings"> */}
                      <IconButton
                        onClick={handleClick}
                        // size="large"
                        sx={{ ml: 1 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        // color="inherit"
                      >
                        <AccountCircle />
                      </IconButton>
                    {/* </Tooltip> */}
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      {/* <MenuItem component={Link} href={"/profile"}>
                        <Avatar /> {"내 정보"}
                      </MenuItem> */}
                      <MenuItem
                        component={Link}
                        onClick={handleClickProfile}>
                          <ListItemIcon>
                            <Avatar />
                          </ListItemIcon>
                          {"내 정보"}
                      </MenuItem>
                      <MenuItem
                        component={Link}
                        onClick={handleClickPaymentHistory}
                      >
                        <ListItemIcon>
                          <ReceiptLong />
                        </ListItemIcon>
                        {"납부이력"}
                      </MenuItem>
                      <Divider />
                      {/* TODO : 사용자 계정 임시 주석 : 추후 기능구현해야 함 */}
                      {/* {
                        sessionUser && sessionUser.bizRegNumber && (
                          <MenuItem component={Link} href={"/userAccount"}>
                            <ListItemIcon>
                              <ManageAccounts fontSize="small" />
                            </ListItemIcon>
                            {"사용자 계정"}
                          </MenuItem>
                        )
                      } */}
                      {/* <MenuItem>
                        <ListItemIcon>
                          <Settings fontSize="small" />
                        </ListItemIcon>
                        {"Settings"}
                      </MenuItem> */}
                      {/**
                       * 서버에서 session이 없어진 상태는 error가 설정된 상태이므로(그리고 ErrorPage가 로딩된 상태) 그럴 경우 아래처럼 "/" 이동하면 App에서의 dispatch useEffect에서 restoreUser or restoreSessionUser를 통해 다시 조회하여 signin로 이동
                       * 그렇지 않은 경우 dispatch(sessionActions.logout());를 하면 App의 Route를 통해 signin으로 이동*/}
                      {
                        error ? (
                        <MenuItem component={Link} href={"/"}>
                          <ListItemIcon>
                            <Logout />
                          </ListItemIcon>
                          {"로그아웃"}
                        </MenuItem>
                        ) : (
                          <MenuItem onClick={logout}>
                            <ListItemIcon>
                              <Logout />
                            </ListItemIcon>
                            {"로그아웃"}
                          </MenuItem>
                        )
                      }
                    </Menu>
                  </>
                )
              }
              {/* 로그인 화면에서는 AppBar의 로그인 버튼이 보이지 않도록 함 */}
              {
                // TODO : 추후 sessionUser에 따라 signin 위치가 다름
                // sessionUser === undefined && location.pathname !== '/signin' && (
                //   <Button color="inherit" href={"/signin"} startIcon={<Login />}>{"로그인"}</Button>
                // )

                // 회원사 관리자 또는 회원사 사용자 로그인 화면에서 서로간 이동할 수 있는 링크가 있으므로 아래 생략
                // sessionUser === undefined && location.pathname !== '/glass/signin' && (
                //   <Button color="inherit" href={"/glass/signin"} startIcon={<Login />}>
                //     <Typography varaint="body2" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                //       {"로그인 유형 선택"}
                //     </Typography>
                //   </Button>
                // )
              }
              </Box>
            </Box>
          </Toolbar>
          <SendBasketDialog
            open={openBasket}
            setOpen={setOpenBasket}
          />
          <PaymentHistoryDialog
            open={openPaymentHistory}
            setOpen={setOpenPaymentHistory}
          />
        </Container>
      </AppBar>

    {/* AppBar 높이만큼 여백 추가 */}
    <Box component="nav" sx={{
      mt: '68.5px',
      width: (
        location.pathname === '/glass' ||
        location.pathname === '/glass/signin' ||
        location.pathname === '/glass/signin/gclient' ||
        location.pathname === '/glass/signin/guser' ||
        location.pathname === '/glass/signup/gclient' ||
        location.pathname === '/glass/signup/guser'
      ) ? 0 : 'auto',
    }}>
      {/* 메뉴 레이아웃이 'left'일 때만 좌측 메뉴 표시 */}
      {
        menuLayout === 'left' &&
        location.pathname !== '/glass' &&
        location.pathname !== '/glass/signin' &&
        location.pathname !== '/glass/signin/gclient' &&
        location.pathname !== '/glass/signin/guser' &&
        location.pathname !== '/glass/signup/gclient' &&
        location.pathname !== '/glass/signup/guser' &&
        renderLeftMenu
      }
    </Box>
    </>
  )  
}

// TODO : 문제 없는지 모니터링할 것
// export {
//   Navigation,
//   NavigationGuard,
// };
export { Navigation, useMenuLayout };
export default Navigation;